import React from "react";

const RatingStars = ({ rating, totalStars = 5 }) => {
  return (
    <div className="d-flex me-1">
      {Array.from(Array(totalStars).keys()).map((i) => (
        <svg
          key={i}
          width="18"
          height="18"
          viewBox="0 0 19 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="d-flex align-items-center"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.7072 1.80759L12.3731 5.13565C12.5364 5.46237 12.8515 5.68893 13.217 5.74135L16.9436 6.27777C17.8644 6.41071 18.2308 7.52661 17.5644 8.16601L14.8696 10.7554C14.6047 11.0101 14.4842 11.3761 14.5468 11.7356L15.1828 15.3913C15.3394 16.2956 14.3769 16.9856 13.5539 16.5578L10.2231 14.8306C9.89659 14.6611 9.50551 14.6611 9.17803 14.8306L5.84721 16.5578C5.02423 16.9856 4.06172 16.2956 4.21929 15.3913L4.85432 11.7356C4.91697 11.3761 4.79642 11.0101 4.53159 10.7554L1.83674 8.16601C1.17039 7.52661 1.53679 6.41071 2.45754 6.27777L6.18418 5.74135C6.54963 5.68893 6.86573 5.46237 7.02899 5.13565L8.69393 1.80759C9.10589 0.984708 10.2953 0.984708 10.7072 1.80759Z"
            stroke="#5956ff"
            strokeWidth="1.36741"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill={i + 1 <= rating ? "#5956ff" : "white"}
          />
        </svg>
      ))}
    </div>
  );
};

export default RatingStars;
