import { useStoreState } from "easy-peasy";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import LoadingAnimationSpoon from "../Helpers/LoadingAnimationSpoon";
import { useOutletContext } from "react-router-dom";
import axios from "axios";

const PlatformFeeInfo = () => {
  const [loader, setLoader] = useState(true);
  const [platformFee, setPlatformFee] = useState("x");
  const [editPlatformFee, setEditPlatformFee] = useState(false);
  const [platformFeeAddress, setPlatformFeeAddress] = useState("fee address");
  const [editPlatformFeeAddress, setEditPlatformFeeAddress] = useState(false);
  const [owner, setOwner] = useState("");
  const [disableEditing, setDisableEditing] = useState(true);

  const contractInstance = useStoreState((state) => state.contractInstance);
  const account = useStoreState((state) => state.account);
  const { setError } = useOutletContext();

  const platformFeeRef = useRef();
  const platformAddressRef = useRef();

  useEffect(() => {
    setDisableEditing(account !== owner);
  }, [account, owner]);

  useEffect(() => {
    if (contractInstance) _getPlatformFeesData();
  }, [contractInstance]);

  async function _getPlatformFeesData() {
    console.log(account);
    setLoader(true);
    try {
      const platformFeesAddress = await contractInstance.platformFeesAddress();
      const platformFees = await contractInstance.platformMintFeePercentage();

      setPlatformFee(parseInt(platformFees._hex, 16) / 100);
      setPlatformFeeAddress(platformFeesAddress);

      const owner = await contractInstance.owner();
      setOwner(owner);
      setLoader(false);
    } catch (error) {
      console.log("ERROR::_getPlatformFeesData", error);
      setError();
    }
  }

  const saveEditedPlatformFeeAddress = async () => {
    try {
      setLoader(true);
      if (typeof window.ethereum !== "undefined") {
        const transaction = await contractInstance.setPlatformFeesAddress(
          platformAddressRef.current.value,
          {
            gasLimit: 100000,
          }
        );
        await transaction.wait();

        _getPlatformFeesData();
        setLoader(false);
        setEditPlatformFeeAddress(false);
      }
    } catch (error) {
      console.log("ERROR::saveEditedPlatformFeeAddress", error);
      setLoader(false);
      setError();
    }
  };

  const saveEditedPlatformFeeMintPercent = async () => {
    try {
      setLoader(true);
      if (typeof window.ethereum !== "undefined") {
        const transaction = await contractInstance.setPlatformMintFeePercentage(
          platformFeeRef.current.value * 100,
          {
            gasLimit: 100000,
          }
        );

        await transaction.wait();
        _getPlatformFeesData();
        setLoader(false);
        setEditPlatformFee(false);
      }
    } catch (error) {
      console.log("ERROR::saveEditedPlatformFeeMintPercent", error);
      setLoader(false);
      setError();
    }
  };

  return (
    <div className="platform-fee-info-container">
      <h3 className="text-center">Platform Fee Info</h3>
      <hr />
      <div className="owner-info-container">
        <div className="detail">Contract Owner: </div>
        <div className="value">{owner}</div>
      </div>
      <div className="platform-fee-data-container  my-3">
        <div className="platform-fee-container d-flex flex-grow-0 align-items-center">
          <p className="detail">Platform Fee : </p>
          <p className="value">
            {!editPlatformFee ? (
              platformFee
            ) : (
              <input
                defaultValue={platformFee}
                type="number"
                className="fee-input"
                ref={platformFeeRef}
              />
            )}{" "}
            %
          </p>

          {!editPlatformFee && (
            <i
              className="edit-icon ms-2"
              onClick={() => {
                if (!disableEditing) {
                  setEditPlatformFee(true);
                  setEditPlatformFeeAddress(false);
                }
              }}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.9287 1.1377H6.72237C3.26299 1.1377 1.09399 3.58682 1.09399 7.05407V16.4073C1.09399 19.8746 3.25287 22.3237 6.72237 22.3237H16.6494C20.12 22.3237 22.2789 19.8746 22.2789 16.4073V11.8758"
                  stroke="#8971FF"
                  strokeWidth="1.9"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.93096 10.286L16.3381 1.87893C17.3855 0.832676 19.0831 0.832676 20.1305 1.87893L21.4996 3.24805C22.547 4.29543 22.547 5.99418 21.4996 7.04043L13.052 15.488C12.5941 15.9459 11.9731 16.2035 11.3251 16.2035H7.11084L7.21659 11.951C7.23234 11.3256 7.48771 10.7293 7.93096 10.286Z"
                  stroke="#8971FF"
                  strokeWidth="1.9"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M15.0605 3.17773L20.1973 8.31448"
                  stroke="#8971FF"
                  strokeWidth="1.9"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </i>
          )}
          {editPlatformFee && (
            <>
              <Button
                size="sm"
                onClick={() => {
                  saveEditedPlatformFeeMintPercent();
                }}
                className="mx-2"
              >
                Save
              </Button>{" "}
              <Button
                variant="danger"
                size="sm"
                onClick={() => {
                  setEditPlatformFee(false);
                }}
              >
                Cancel
              </Button>
            </>
          )}
        </div>

        <div className="address-container d-flex align-items-center flex-wrap">
          <p className="detail">Platform Fee Address : </p>
          <p className="value">
            {!editPlatformFeeAddress ? (
              platformFeeAddress
            ) : (
              <input
                defaultValue={platformFeeAddress}
                type="text"
                ref={platformAddressRef}
                className="address-input"
              />
            )}
          </p>
          {!editPlatformFeeAddress && (
            <i
              className="edit-icon ms-2"
              onClick={() => {
                if (!disableEditing) {
                  setEditPlatformFee(false);
                  setEditPlatformFeeAddress(true);
                }
              }}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.9287 1.1377H6.72237C3.26299 1.1377 1.09399 3.58682 1.09399 7.05407V16.4073C1.09399 19.8746 3.25287 22.3237 6.72237 22.3237H16.6494C20.12 22.3237 22.2789 19.8746 22.2789 16.4073V11.8758"
                  stroke="#8971FF"
                  strokeWidth="1.9"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.93096 10.286L16.3381 1.87893C17.3855 0.832676 19.0831 0.832676 20.1305 1.87893L21.4996 3.24805C22.547 4.29543 22.547 5.99418 21.4996 7.04043L13.052 15.488C12.5941 15.9459 11.9731 16.2035 11.3251 16.2035H7.11084L7.21659 11.951C7.23234 11.3256 7.48771 10.7293 7.93096 10.286Z"
                  stroke="#8971FF"
                  strokeWidth="1.9"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M15.0605 3.17773L20.1973 8.31448"
                  stroke="#8971FF"
                  strokeWidth="1.9"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </i>
          )}
          {editPlatformFeeAddress && (
            <>
              <Button
                size="sm"
                onClick={saveEditedPlatformFeeAddress}
                className="mx-2"
              >
                Save
              </Button>{" "}
              <Button
                size="sm"
                variant="danger"
                onClick={() => {
                  setEditPlatformFeeAddress(false);
                }}
              >
                Cancel
              </Button>
            </>
          )}
        </div>
      </div>

      {loader && (
        <div className="loader-container">
          <LoadingAnimationSpoon className="loader" width={150} />
        </div>
      )}
    </div>
  );
};

export default PlatformFeeInfo;
