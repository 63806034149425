import React from "react";

const ClockIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.741 8.12684C13.741 11.2783 11.1866 13.8327 8.03516 13.8327C4.8837 13.8327 2.32935 11.2783 2.32935 8.12684C2.32935 4.97538 4.8837 2.42102 8.03516 2.42102C11.1866 2.42102 13.741 4.97538 13.741 8.12684Z"
        stroke="white"
        strokeWidth="0.925267"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.1519 9.94195L7.82642 8.55467V5.56482"
        stroke="white"
        strokeWidth="0.925267"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ClockIcon;
