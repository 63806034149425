import axios from "axios";

const BACKEND_URL = process.env.REACT_APP_BLOCKCHAIN_BE_SERVER_URL;

class UserService {
  getUsers = (params) => {
    return axios.get(BACKEND_URL + "/users", { params: params });
  };

  loginUser = async (typeOfLogin) => {
    const accessToken = localStorage.getItem("idToken");
    const appPubKey = localStorage.getItem("appPubKey");
    if (!accessToken || !appPubKey)
      throw new Error(
        "appPubKey or idToken not set properly, API login failed"
      );
    const response = await axios.post(`${BACKEND_URL}/login`, {
      typeOfLogin,
    });
    return await response.data;
  };

  getUser = async () => {
    const response = await axios.get(BACKEND_URL + "/user");
    return response.data;
  };

  getUserLite = async () => {
    const res = await axios.get(BACKEND_URL + "/login-lite/user");
    return res.data;
  };

  updateUser = async ({ name, email, mobile }) => {
    const response = await axios.post(BACKEND_URL + "/update-user", {
      name,
      email,
      mobile,
    });

    return response.data;
  };

  checkWhitelistEligibility = (restaurantId, walletAddress) => {
    return axios.get(
      `${BACKEND_URL}/whitelist/check-eligibility/${restaurantId}/${walletAddress}`
    );
  };

  sendOtpForMobileUpdation = (mobile) => {
    return axios.post(BACKEND_URL + "/update-mobile/send-otp", { mobile });
  };

  verifyOtpForMobileUpdation = (mobile, otp) => {
    return axios.post(BACKEND_URL + "/update-mobile/verify-otp", {
      mobile,
      otp,
    });
  };

  sendOtpForEmailUpdation = (email) => {
    return axios.post(BACKEND_URL + "/update-email/send-otp", { email });
  };

  verifyOtpForEmailUpdation = (email, otp) => {
    return axios.post(BACKEND_URL + "/update-email/verify-otp", { email, otp });
  };

  sendOtpForLoginLite = (params) => {
    // params is either {mobile} or {email}
    return axios.post(BACKEND_URL + "/login-lite/send-otp", params);
  };

  verifyOtpForLoginLite = (params) => {
    // params is either {mobile, otp} or {email, otp}
    return axios.post(BACKEND_URL + "/login-lite/verify-otp", params);
  };

  validateLoginType = (value, typeOfLogin) => {
    return axios.post(BACKEND_URL + "/validate-login", { value, typeOfLogin });
  };
}

export default new UserService();
