import React, { useState } from "react";
import { Button, Container, FloatingLabel, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import UserService from "../Services/UserService";

const RegisterUser = ({ mobileNumber, checkUserRegistered }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [age, setAge] = useState("");
  const [lowAgeWarning, setLowAgeWarning] = useState(false);
  // const [gender]

  const handleAgeInput = (e) => {
    let ageEntered = e.target.value;
    let age = ageEntered > 99 ? Math.floor(ageEntered / 10) : ageEntered;
    setAge(age);
    setLowAgeWarning(age < 13);
    console.log("lowagewarning, ", lowAgeWarning);
  };

  let navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (firstName !== undefined && lastName !== undefined && age >= 13) {
      const user = { mobileNumber, firstName, lastName, age };
      try {
        // When user is registered successfully, then update state in login component
        UserService.registerUser(user).then((response) => {
          console.log(response.data.message);
          checkUserRegistered();
        });
      } catch (e) {
        console.log("ERROR :", e);
      }

      // if (redirectToHome) navigate("/");
      // else {
      //   proceedBooking(true); // signing up while making a booking
      // }
    }
    console.log("clicked");
  };

  return (
    <Container>
      <h1 className="text-center">Tell us about yourself</h1>
      <br />
      <Form>
        <FloatingLabel label="First Name">
          <Form.Control
            type="text"
            placeholder="your name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </FloatingLabel>
        <br />
        <FloatingLabel label="Last Name">
          <Form.Control
            required
            type="text"
            placeholder="your name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </FloatingLabel>
        <br />
        <FloatingLabel label="Age">
          <Form.Control
            type="number"
            placeholder="your name"
            value={age}
            onChange={handleAgeInput}
          />
        </FloatingLabel>
        {lowAgeWarning && (
          <small style={{ color: "red" }}>
            You need to be atleast 13 years old
          </small>
        )}
        <br />
        <Button type="submit" variant="danger" onClick={handleSubmit}>
          Sign Up
        </Button>
      </Form>
    </Container>
  );
};

export default RegisterUser;
