import { AnimatePresence } from "framer-motion";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import ModalBox from "../Modal/ModalBox";
import MobileNumberInput from "../MobileNumberInput";
import LoginLiteModal from "./LoginLiteModal";
import { action, useStoreActions, useStoreState } from "easy-peasy";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const LoginLite = ({
  litelogoutButtonRef,
  collapseNavbar = () => {},
  handleWeb3AuthLogin = () => {},
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const loginLite = useStoreState((state) => state.loginLite);
  const setLoginLite = useStoreActions((actions) => actions.setLoginLite);
  const setAccountLite = useStoreActions((actions) => actions.setAccountLite);
  const setAccount = useStoreActions((actions) => actions.setAccount);
  const accountLite = useStoreState((state) => state.accountLite);

  const navigate = useNavigate();

  const closeModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    console.log("Login Lite effect");
    console.log("logging in? : ", sessionStorage.getItem("loggingIn"));
    // if (sessionStorage.getItem("loggingIn") !== "true") {
    // console.log("No actual login detected");
    if (loginLite) {
      handleLoginLite();
    }
    // }
  }, []);

  const handleLoginLite = (userData, token) => {
    console.log("Lite login");

    setLoginLite(true);

    let idToken = token;

    const cachedIdTokenLite = localStorage.getItem("idTokenLite");
    const cachedTimestamp = localStorage.getItem("idTokenLiteTimeStamp");
    const currentTime = new Date().getTime();

    if (
      cachedIdTokenLite &&
      cachedTimestamp &&
      currentTime - cachedTimestamp < 3600000 * 24
    ) {
      console.log("using cached idtokenlite");
      idToken = localStorage.getItem("idTokenLite");
    } else {
      if (!token) {
        handleLogoutLite();
      } else {
        console.log("setting new idtokenlite", token);
        localStorage.setItem("idTokenLite", token);
        localStorage.setItem("idTokenLiteTimeStamp", new Date().getTime());
      }
    }

    console.log("setting header lite id tokeN:", idToken);
    axios.defaults.headers.common["Authorization"] = `Bearer ${idToken}`;

    if (accountLite) {
      console.log("setting account from lite:, ", accountLite);
      setAccount(accountLite);
    } else {
      console.log("setting account from lite first time:, ", userData);
      setAccountLite(userData?.walletAddress);
      setAccount(userData?.walletAddress);
    }

    // navigate("/account/reservations-and-notify");

    return true;
  };

  const handleLogoutLite = () => {
    localStorage.removeItem("idTokenLite");
    localStorage.removeItem("idTokenLiteTimeStamp");
    setAccountLite(null);
    setAccount(null);
    delete axios.defaults.headers.common["Authorization"];
    setLoginLite(false);
    navigate("/");
    console.log("logout completed");
  };

  return (
    <div>
      {!loginLite ? (
        <Button
          onClick={() => setModalOpen(true)}
          className="paidrez-btn navbar-button login-lite-login-button bg-transparent"
        >
          Sign In <small>(Lite)</small>
        </Button>
      ) : (
        <Button
          ref={litelogoutButtonRef}
          className="d-none navbar-button login-lite-logout-button paidrez-btn w-100 mt-2"
          onClick={handleLogoutLite}
        >
          Sign Out
        </Button>
      )}

      <AnimatePresence initial={false} exit={true} onExitComplete={() => null}>
        {modalOpen && (
          <ModalBox
            modalOpen={modalOpen}
            handleClose={closeModal}
            children={
              <LoginLiteModal
                closeModal={closeModal}
                verified={handleLoginLite}
                collapseNavbar={collapseNavbar}
                handleWeb3AuthLogin={handleWeb3AuthLogin}
              />
            }
            style_class={"login-lite-modal"}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export default LoginLite;
