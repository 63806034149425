import { MoonPayBuyWidget } from "@moonpay/moonpay-react";
import { useStoreState } from "easy-peasy";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const BuyTokens = ({ loginButtonRef }) => {
  const account = useStoreState((state) => state.account);
  const loginLite = useStoreState((state) => state.loginLite);

  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     // Disable the default warning
  //     event.returnValue = undefined;
  //     console.log("beforeunload event listener removed");
  //   };

  //   window.addEventListener("beforeunload", handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, []);

  return (
    <div className="page-container d-flex justify-content-center">
      {!account || loginLite ? (
        <div className="mt-4">
          <h5 className="text-center">
            {loginLite
              ? "You must be fully signed in to buy POL"
              : "You are not signed in."}
          </h5>
          <Button
            className="paidrez-btn mx-auto d-block"
            onClick={() => {
              loginButtonRef.current?.click();
            }}
          >
            Sign In
          </Button>
        </div>
      ) : (
        <MoonPayBuyWidget
          variant="embedded"
          baseCurrencyCode="usd"
          baseCurrencyAmount="100"
          // quoteCurrencyAmount="100"
          defaultCurrencyCode="matic"
          showOnlyCurrencies="matic"
          visible
          walletAddress={account}
          colorCode="#5956ff"
        />
      )}
    </div>
  );
};

export default BuyTokens;
