import React from "react";
import { Image } from "react-bootstrap";
import CloseIcon from "./Icons/CloseIcon";

const ErrorPopup = ({ closeModal, msg }) => {
  return (
    <div>
      <CloseIcon closeModal={closeModal} />

      <Image
        src="/broken-chain-icon.png"
        width={48}
        className="mx-auto d-block mb-2"
        alt="broken chain"
      />
      <h5 className="text-black fw-bold text-center mb-2">
        {msg || "Something Went Wrong."}
      </h5>

      <p className="fw-bold text-center text-black">Please try again later.</p>

      <p className="fw-bold text-center text-muted mb-0">
        If you continue to face trouble,{" "}
        <a
          href="mailto:support@developingnow.com?subject=PAIDREZ%20Support"
          className="paidrez-color text-decoration-none"
        >
          Contact Us
        </a>
      </p>
    </div>
  );
};

export default ErrorPopup;
