import React from "react";

const RestaurantAdminDashboard = () => {
  return (
    <div>
      <h2 className="text-center"> Restaurant Name - Admin page</h2>
    </div>
  );
};

export default RestaurantAdminDashboard;
