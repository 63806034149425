import React from "react";
import loading from "../../dots-loading-animation.gif";

const LoadingAnimationDots = ({ style, width }) => {
  return (
    <div className={`text-center`} style={style}>
      <img src={loading} alt="loading" width={width} />
    </div>
  );
};

export default LoadingAnimationDots;
