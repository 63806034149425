import React from "react";
import { Container } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useNavigate } from "react-router";

const BlogPostGrid = ({ isMobile, blogs, topBlog }) => {
  const navigate = useNavigate();

  // if (isMobile && blogs.length && topBlog)
  //   return (
  //     <div className="BlogPostGrid">
  //       <Row className="flex-nowrap mobile">
  //         {blogs.map((blog, idx) => {
  //           return (
  //             blog._id !== topBlog._id && (
  //               <Col
  //                 xs={8}
  //                 sm={5}
  //                 md={4}
  //                 lg={3}
  //                 key={idx}
  //                 onClick={() => navigate("/blog/" + blog._id)}
  //               >
  //                 <Card>
  //                   <Card.Img
  //                     variant="top"
  //                     src={blog.imageUrl}
  //                     className="card-image"
  //                   />
  //                   <Card.Body>
  //                     <Card.Title>{blog.title}</Card.Title>
  //                     <Card.Text>
  //                       {blog.content?.substring(0, 50)}
  //                       {blog.content?.length > 50 && "..."}
  //                     </Card.Text>
  //                   </Card.Body>
  //                 </Card>
  //               </Col>
  //             )
  //           );
  //         })}
  //       </Row>
  //     </div>
  //   );

  // if (!isMobile && blogs.length && topBlog)
  //   return (
  //     <div className="BlogPostGrid">
  //       <h5 className="more-stories">More Stories</h5>
  //       <hr />
  //       {blogs.map((blog, idx) => {
  //         return (
  //           blog._id !== topBlog._id && (
  //             <div
  //               className="web"
  //               onClick={() => navigate("/blog/" + blog._id)}
  //               key={idx}
  //             >
  //               <Row>
  //                 <Card>
  //                   <Card.Img
  //                     variant="top"
  //                     src={blog.imageUrl}
  //                     className="card-image"
  //                   />
  //                   <Card.Body>
  //                     <Card.Title>{blog.title}</Card.Title>
  //                     <Card.Text>
  //                       {blog.content.substring(0, 50)}
  //                       {blog.content?.length > 50 && "..."}
  //                     </Card.Text>
  //                   </Card.Body>
  //                 </Card>
  //               </Row>
  //               <hr />
  //             </div>
  //           )
  //         );
  //       })}
  //     </div>
  //   );

  if (isMobile)
    return (
      <>
        <h4 className="more-stories ">Restaurant Owner? Learn more below</h4>
        <hr />
        <div className="BlogPostGrid">
          <Row className="flex-nowrap mobile">
            <Col
              xs={8}
              sm={5}
              md={4}
              lg={3}
              onClick={() => navigate("/value-proposition-for-restaurants")}
            >
              <Card>
                <Card.Img
                  variant="top"
                  src="/blog-images/blog-value-props.webp"
                  className="card-image"
                />

                {/* <div className="learn-more-container">
                  <p>VALUE PROPOSITION FOR RESTAURANT OWNERS</p>
                </div> */}
              </Card>
            </Col>
            <Col
              xs={8}
              sm={5}
              md={4}
              lg={3}
              onClick={() => navigate("/how-it-works")}
            >
              <Card>
                <Card.Img
                  variant="top"
                  src="/blog-images/blog-process.webp"
                  className="card-image"
                />

                {/* <div className="learn-more-container">
                  <p>SEE HOW IT ALL WORKS</p>
                </div> */}
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );

  if (!isMobile)
    return (
      <div className="BlogPostGrid">
        <h5 className="more-stories ">Restaurant Owner? Learn More Below</h5>
        <hr />

        <div
          className="web"
          onClick={() => navigate("/value-proposition-for-restaurants")}
        >
          <Row>
            <Card>
              <Card.Img
                variant="top"
                src="/blog-images/blog-value-props.webp"
                className="card-image"
              />

              {/* <div className="learn-more-container">
                <p>VALUE PROPOSITION FOR RESTAURANT OWNERS</p>
              </div> */}
            </Card>
          </Row>
          <hr />
        </div>
        <div className="web" onClick={() => navigate("/how-it-works")}>
          <Row>
            <Card>
              <Card.Img
                variant="top"
                src="/blog-images/blog-process.webp"
                className="card-image"
              />

              {/* <div className="learn-more-container">
                <p>SEE HOW IT ALL WORKS</p>
              </div> */}
            </Card>
          </Row>
          <hr />
        </div>
      </div>
    );
};

export default BlogPostGrid;
