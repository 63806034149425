import React, { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { Navigate, useNavigate } from "react-router-dom";
import UserService from "../Services/UserService";
import MobileOtp from "./MobileOtp";
import RegisterUser from "./RegisterUser";

const Login = ({ closeModal, loginComplete, onlyLogIn = false }) => {
  const [otpVerified, setOtpVerified] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [welcome, setWelcome] = useState(false);
  const [firstName, setFirtName] = useState("");
  const [errorFromAPI, setErrorFromAPI] = useState(false);

  const navigate = useNavigate();

  const checkUserRegistered = async () => {
    await UserService.checkUserRegistered(mobileNumber)
      .then((response) => {
        const registered = response.data.registered;
        console.log("registered?: ", registered);
        setIsRegistered(registered);
        if (registered) {
          setFirtName(response.data.user.firstName);
          welcomeScreen();
        }
        setOtpVerified(true);
        sessionStorage.setItem("isLoggedIn", true);
        sessionStorage.setItem("userId", response.data.user.id);
        sessionStorage.removeItem("otp");
        // closeModal();
      })
      .catch((e) => {
        console.log(e);
        setErrorFromAPI(true);
      });
  };

  useEffect(() => {
    if (mobileNumber !== "") {
      checkUserRegistered();
    }
  }, [mobileNumber]);

  const getMobileNumber = async (mobileNumber) => {
    setMobileNumber(mobileNumber);
    console.log("mobile number set! ", mobileNumber);
  };

  const welcomeScreen = () => {
    setWelcome(true);
    setTimeout(() => loginComplete(), 3000);
  };

  return (
    <Container className="login-container">
      {!otpVerified ? (
        <>
          <MobileOtp getMobileNumber={getMobileNumber} onlyLogIn={onlyLogIn} />
          {errorFromAPI && (
            <div className="login-error-container">
              <div>
                <h1>Sorry we can't log you in. Something went wrong.</h1>
                <a
                  className="need-help-button"
                  href="mailto:contact@paidrez.com"
                  type="button"
                >
                  Contact us
                </a>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          {isRegistered ? (
            <>
              {welcome && (
                <>
                  <h1 className="text-center">
                    Hi! {firstName}, Welcome back!
                  </h1>
                </>
              )}
            </>
          ) : (
            <RegisterUser
              checkUserRegistered={checkUserRegistered} // After registering, <RegisterUser/> will check if user is registered by calling checkUserRegistered, to update 'isRegistered'
              mobileNumber={mobileNumber}
            />
          )}
        </>
      )}
    </Container>
  );
};

export default Login;
