import { ethers } from "ethers";
import React from "react";
import { Accordion, Col, Container, Image, Row } from "react-bootstrap";
import Moment from "react-moment";

const NFTDetails = ({ nft, closeModal }) => {
  return (
    <Container className="nft-details">
      {/* NFTDetails  */}
      <div className="close-button-container" onClick={() => closeModal()}>
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.896 0.664673H34.126C42.036 0.664673 47.3327 6.21801 47.3327 14.478V33.5437C47.3327 41.7803 42.036 47.3313 34.126 47.3313H13.896C5.98602 47.3313 0.666016 41.7803 0.666016 33.5437V14.478C0.666016 6.21801 5.98602 0.664673 13.896 0.664673ZM31.0228 30.998C31.8162 30.207 31.8162 28.9237 31.0228 28.1304L26.8695 23.977L31.0228 19.8214C31.8162 19.0304 31.8162 17.7237 31.0228 16.9304C30.2295 16.1347 28.9462 16.1347 28.1295 16.9304L23.9995 21.0814L19.8462 16.9304C19.0295 16.1347 17.7462 16.1347 16.9528 16.9304C16.1595 17.7237 16.1595 19.0304 16.9528 19.8214L21.1062 23.977L16.9528 28.107C16.1595 28.9237 16.1595 30.207 16.9528 30.998C17.3495 31.3947 17.8862 31.607 18.3995 31.607C18.9362 31.607 19.4495 31.3947 19.8462 30.998L23.9995 26.8704L28.1528 30.998C28.5495 31.4204 29.0628 31.607 29.5762 31.607C30.1128 31.607 30.6262 31.3947 31.0228 30.998Z"
            fill="#D9D9D9"
          />
        </svg>
      </div>

      <Row>
        <Col sm={6}>
          <Image src={nft.s3ImageURI} alt="nft-image" className="nft-image" />
        </Col>
        <Col sm={6} className="py-3 details-container">
          <div className="name-time-container">
            <h3 className="name">
              {nft.tokenName ||
                `PAIDREZ ${nft.restaurantName} #${nft.tokenId
                  .toString()
                  .padStart(5, "0")}`}
            </h3>
            <div className="time-container">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="icon"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M13.741 8.12684C13.741 11.2783 11.1866 13.8327 8.03516 13.8327C4.8837 13.8327 2.32935 11.2783 2.32935 8.12684C2.32935 4.97538 4.8837 2.42102 8.03516 2.42102C11.1866 2.42102 13.741 4.97538 13.741 8.12684Z"
                  stroke="white"
                  strokeWidth="0.925267"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.1519 9.94195L7.82642 8.55467V5.56482"
                  stroke="white"
                  strokeWidth="0.925267"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <i className="divider"></i>
              <span className="time">
                {<Moment format="LT">{new Date(nft.slotTime * 1000)}</Moment>}
              </span>
            </div>
          </div>

          <Row className="seat-date-container mb-3">
            <Col xs={6} className="nft-info-container seats">
              <div className="nft-info ">
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon"
                >
                  <path
                    d="M14.9856 11.5371C16.8427 11.9182 17.5219 13.1535 17.5219 14.1851"
                    stroke="#9E9E9E"
                    strokeWidth="1.14435"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M14.5728 5.2312C15.4182 5.50406 16.028 6.29839 16.0246 7.23476C16.021 8.13127 15.4554 8.89532 14.6619 9.19239"
                    stroke="#9E9E9E"
                    strokeWidth="1.14435"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M5.00391 15.7532C5.00391 14.1447 6.27378 12.142 9.93094 12.142C13.5881 12.142 14.858 14.1299 14.858 15.7394"
                    stroke="#9E9E9E"
                    strokeWidth="1.14435"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.0792 6.6944C13.0792 8.43289 11.6699 9.84136 9.93224 9.84136C8.19376 9.84136 6.78442 8.43289 6.78442 6.6944C6.78442 4.95589 8.19376 3.54742 9.93224 3.54742C11.6699 3.54742 13.0792 4.95589 13.0792 6.6944Z"
                    stroke="#9E9E9E"
                    strokeWidth="1.14435"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M4.87711 11.5371C3.01993 11.9182 2.34082 13.1535 2.34082 14.1851"
                    stroke="#9E9E9E"
                    strokeWidth="1.14435"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M5.28993 5.2312C4.4445 5.50406 3.83469 6.29839 3.83815 7.23476C3.84161 8.13127 4.40726 8.89532 5.20071 9.19239"
                    stroke="#9E9E9E"
                    strokeWidth="1.14435"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <i className="divider"></i>
                <span>{nft.seatCapacity} Seats</span>
              </div>
            </Col>{" "}
            <Col xs={6} className="nft-info-container date">
              <div className="nft-info ">
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon"
                >
                  <path
                    d="M12.0186 2.78455V4.95788"
                    stroke="#9E9E9E"
                    strokeWidth="1.14435"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M6.68335 2.78455V4.95788"
                    stroke="#9E9E9E"
                    strokeWidth="1.14435"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M15.2903 8.79628V6.89384C15.2963 4.90756 14.0908 3.82758 12.1461 3.82758H6.55468C4.61516 3.82758 3.40454 4.90756 3.40454 6.89384V12.869C3.40454 14.8864 4.61516 15.9917 6.55468 15.9917H7.96496"
                    stroke="#9E9E9E"
                    strokeWidth="1.14435"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16.0491 13.5382C16.0491 15.1831 14.7153 16.5169 13.0705 16.5169C11.4257 16.5169 10.0918 15.1831 10.0918 13.5382C10.0918 11.8934 11.4257 10.5596 13.0705 10.5596C14.7153 10.5596 16.0491 11.8934 16.0491 13.5382Z"
                    stroke="#9E9E9E"
                    strokeWidth="1.14435"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M13.9959 14.3116L13.054 13.749V12.5384"
                    stroke="#9E9E9E"
                    strokeWidth="1.14435"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12.4647 7.84631H6.2312"
                    stroke="#9E9E9E"
                    strokeWidth="1.14435"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <i className="divider"></i>
                <Moment format="MMM Do YYYY">
                  {new Date(nft.slotTime * 1000)}
                </Moment>
              </div>
            </Col>
          </Row>

          <Row className="mint-fee-container">
            <p>
              Mint Fee{" "}
              <span className="value">
                {nft.priceInMatic}
                {"  "}
                POL
              </span>
            </p>
          </Row>

          <hr />

          <Row className="accordion-container">
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0" className="mt-0">
                <Accordion.Header>
                  <svg
                    width="16"
                    height="14"
                    viewBox="0 0 18 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="me-2"
                  >
                    <path
                      d="M12.4064 13.2846H5.78809"
                      stroke="#7A7A7A"
                      strokeWidth="1.7"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12.4064 9.44698H5.78809"
                      stroke="#7A7A7A"
                      strokeWidth="1.7"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M8.3135 5.61825H5.78809"
                      stroke="#7A7A7A"
                      strokeWidth="1.7"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.5828 0.933838C12.5828 0.933838 5.54557 0.937505 5.53457 0.937505C3.00457 0.953088 1.43799 2.61775 1.43799 5.15692V13.5866C1.43799 16.1386 3.01649 17.8097 5.56849 17.8097C5.56849 17.8097 12.6048 17.8069 12.6167 17.8069C15.1467 17.7913 16.7142 16.1258 16.7142 13.5866V5.15692C16.7142 2.60492 15.1348 0.933838 12.5828 0.933838Z"
                      stroke="#7A7A7A"
                      strokeWidth="1.7"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  Description
                </Accordion.Header>
                <Accordion.Body></Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  <svg
                    width="16"
                    height="18"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="me-2"
                  >
                    <path
                      d="M3 12C3 16.9699 7.02908 21 12 21C16.9709 21 21 16.9699 21 12C21 7.02908 16.9709 3 12 3C7.02908 3 3 7.02908 3 12Z"
                      stroke="#7A7A7A"
                      strokeWidth="1.7"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12.0057 15.6932V11.3936M12 8.35426V8.29102"
                      stroke="#7A7A7A"
                      strokeWidth="1.7"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  Details
                </Accordion.Header>
                <Accordion.Body></Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  <svg
                    width="16"
                    height="18"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="me-2"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M4.41494 14.8496C4.20994 14.3176 4.06694 13.7856 3.98894 13.2676C3.90694 12.7206 3.39094 12.3526 2.85194 12.4266C2.30594 12.5096 1.92894 13.0186 2.01194 13.5646C2.11094 14.2256 2.29094 14.8996 2.54794 15.5666C2.70094 15.9636 3.07994 16.2076 3.48094 16.2076C3.60094 16.2076 3.72194 16.1866 3.83994 16.1416C4.35494 15.9436 4.61294 15.3656 4.41494 14.8496Z"
                      fill="#7A7A7A"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M4.99783 7.22852C5.28683 7.22852 5.57283 7.10452 5.76983 6.86452C6.06683 6.50252 6.42183 6.15252 6.82383 5.82252C7.24983 5.47052 7.31083 4.84052 6.95983 4.41452C6.60883 3.98752 5.97883 3.92652 5.55183 4.27752C5.05083 4.69052 4.60483 5.13252 4.22683 5.59252C3.87483 6.01952 3.93583 6.64952 4.36283 7.00052C4.54883 7.15452 4.77383 7.22852 4.99783 7.22852Z"
                      fill="#7A7A7A"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M2.86167 11.5293C2.91367 11.5373 2.96567 11.5413 3.01767 11.5413C3.50167 11.5413 3.92667 11.1883 4.00467 10.6953C4.09767 10.0953 4.25667 9.52231 4.47767 8.99131C4.68967 8.48131 4.44767 7.89531 3.93767 7.68331C3.42867 7.47331 2.84267 7.71431 2.63067 8.22331C2.34867 8.90131 2.14567 9.63031 2.02767 10.3863C1.94267 10.9323 2.31667 11.4443 2.86167 11.5293Z"
                      fill="#7A7A7A"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M8.63916 4.63485C8.76316 4.63485 8.88916 4.61185 9.01116 4.56285C9.49716 4.36685 10.0262 4.22585 10.5812 4.14085C11.1272 4.05785 11.5032 3.54785 11.4202 3.00185C11.3362 2.45585 10.8242 2.08085 10.2812 2.16385C9.57316 2.27185 8.89516 2.45385 8.26616 2.70685C7.75316 2.91285 7.50516 3.49485 7.71116 4.00785C7.86816 4.39785 8.24316 4.63485 8.63916 4.63485Z"
                      fill="#7A7A7A"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M21.872 4.93849C21.601 4.45549 20.994 4.28549 20.511 4.55449C20.056 4.80949 19.628 5.08249 19.207 5.36049C18.142 4.15449 16.769 3.18949 15.15 2.60449C14.59 2.40749 14.007 2.25949 13.418 2.16749C12.867 2.07849 12.361 2.45349 12.274 2.99849C12.188 3.54349 12.56 4.05549 13.106 4.14149C13.575 4.21549 14.039 4.33449 14.477 4.48849C15.7 4.92949 16.743 5.64749 17.572 6.54049C14.884 8.69249 13.006 11.2025 11.814 13.1375C10.967 11.9795 9.97501 10.9185 8.85401 9.97249C8.43301 9.61549 7.801 9.66949 7.445 10.0905C7.088 10.5125 7.14201 11.1445 7.56401 11.5005C8.95001 12.6705 10.111 14.0335 11.017 15.5515C11.197 15.8555 11.524 16.0405 11.875 16.0405H11.9C12.261 16.0315 12.589 15.8285 12.758 15.5095C13.543 14.0365 15.496 10.8205 18.742 8.17849C19.774 10.0935 20.027 12.4185 19.232 14.6205C18.514 16.6085 17.064 18.1985 15.151 19.0965C13.238 19.9935 11.088 20.0935 9.099 19.3755C8.186 19.0455 7.35 18.5565 6.601 17.9135C6.265 17.6305 5.95901 17.3185 5.69301 16.9855C5.34801 16.5515 4.71801 16.4825 4.28801 16.8265C3.85601 17.1705 3.785 17.7995 4.129 18.2315C4.479 18.6695 4.879 19.0795 5.307 19.4375C6.23001 20.2325 7.278 20.8435 8.42001 21.2555C9.52 21.6535 10.66 21.8505 11.795 21.8505C13.231 21.8505 14.662 21.5355 16 20.9075C18.397 19.7825 20.213 17.7915 21.113 15.2995C22.143 12.4475 21.775 9.42849 20.369 6.98549C20.731 6.74749 21.101 6.51549 21.488 6.29849C21.97 6.02949 22.142 5.41949 21.872 4.93849Z"
                      fill="#7A7A7A"
                    />
                  </svg>
                  Attributes
                </Accordion.Header>
                <Accordion.Body></Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default NFTDetails;
