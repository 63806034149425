import axios from "axios";

const BACKEND_URL = process.env.REACT_APP_BLOCKCHAIN_BE_SERVER_URL;

class BlogsService {
  getBlogs = () => {
    return axios.get(BACKEND_URL + "/all-blog-posts");
  };

  getBlogById = (id) => {
    return axios.get(BACKEND_URL + "/blog-posts/" + id);
  };

  addBlog = (blog) => {
    const data = new FormData();
    data.append("title", blog.title);
    data.append("content", blog.content);
    data.append("author", blog.author);
    data.append("imageFile", blog.image);
    if (blog.isTopPost) data.append("isTopPost", blog.isTopPost);

    return axios.post(BACKEND_URL + "/create-blogpost", data);
  };

  updateBlog = (blog) => {
    const data = new FormData();
    data.append("title", blog.title);
    data.append("content", blog.content);
    data.append("imageFile", blog.image);
    data.append("author", blog.author);
    console.log(blog.image);
    if (blog.isTopPost) data.append("isTopPost", blog.isTopPost);

    return axios.post(BACKEND_URL + "/update-blogpost/" + blog.id, data);
  };

  makeTopPost = (id) => {
    return axios.post(BACKEND_URL + "/update-blogpost/" + id, {
      isTopPost: true,
    });
  };

  deleteBlog = (id) => {
    return axios.delete(BACKEND_URL + "/delete-blogpost/" + id);
  };
}

export default new BlogsService();
