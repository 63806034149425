import React, { useState } from "react";
import { Alert, Button, Form, FormControl, FormText } from "react-bootstrap";
import emailjs from "@emailjs/browser";
import { Link, useNavigate } from "react-router-dom";

const HelpAndFeedback = () => {
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [success, setSuccess] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    // setSuccess(true);
    // return;
    try {
      emailjs
        .send(
          process.env.REACT_APP_EMAILJS_SERVICE_ID,
          process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
          {
            // to: "hooman@developingnow.com",
            to_name: "Hooman",
            from: email,
            message: message,
            reply_to: email,
          },
          process.env.REACT_APP_EMAILJS_PUBLIC_KEY
        )
        .then(
          (result) => {
            console.log(result.text);
            setSuccess(true);
            setSubmitted(false);
          },
          (error) => {
            console.log(error.text);
            setShowAlert(true);
            setTimeout(() => setShowAlert(false), 2000);
            setSubmitted(false);
          }
        );
    } catch (e) {
      console.error(e);
      setShowAlert(true);
      setTimeout(() => setShowAlert(false), 2000);
      setSubmitted(false);
    }
    // console.log("submitted");
  };

  return (
    <div className="help-and-feedback mt-4">
      {!success ? (
        <Form className="help-form" onSubmit={handleSubmit}>
          <h3 className="mb-3">Contact Us</h3>
          <FormText>Message</FormText>
          <FormControl
            as="textarea"
            // placeholder="Type your query here"
            rows="4"
            className="mb-2"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          />

          <FormText>How should we contact you?</FormText>
          <FormControl
            type="email"
            className="email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Your Email"
          />
          <Button
            type="submit"
            className="submit-button mt-3 "
            disabled={submitted}
          >
            Submit
          </Button>
          {showAlert && (
            <Alert
              dismissible
              className="alert"
              variant="danger"
              onClose={() => setShowAlert(false)}
            >
              Something went wrong
            </Alert>
          )}
        </Form>
      ) : (
        <div className="success">
          <h4 className="mb-5">
            Thankyou for your query. We'll get back to you!
          </h4>
          <div className="buttons-container text-center ">
            <Button className="paidrez-btn me-2" onClick={() => navigate("/")}>
              Go to Home
            </Button>
            <Button
              className="paidrez-btn-secondary"
              onClick={() => {
                setSubmitted(false);
                setSuccess(false);
                setMessage("");
              }}
            >
              Submit another query
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default HelpAndFeedback;
