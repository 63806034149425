import React from "react";
import ReactPlayer from "react-player";

const VideoContainer = ({ closeModal }) => {
  return (
    <div className="overflow-hidden d-relative">
      <button className="close-btn" onClick={() => closeModal()}>
        <svg height="20" viewBox="0 0 20 20" width="20">
          <path
            d="m3.94621165 2.89705176.08411844.07261815 5.96966991 5.96933009 5.9696699-5.96933009c.2928932-.29289321.767767-.29289321 1.0606602 0 .2662665.26626657.2904726.68293025.0726181.97654174l-.0726181.08411844-5.9693301 5.96966991 5.9693301 5.9696699c.2928932.2928932.2928932.767767 0 1.0606602-.2662666.2662665-.6829303.2904726-.9765418.0726181l-.0841184-.0726181-5.9696699-5.9693301-5.96966991 5.9693301c-.29289322.2928932-.76776696.2928932-1.06066018 0-.26626656-.2662666-.29047261-.6829303-.07261815-.9765418l.07261815-.0841184 5.96933009-5.9696699-5.96933009-5.96966991c-.29289321-.29289322-.29289321-.76776696 0-1.06066018.26626657-.26626656.68293025-.29047261.97654174-.07261815z"
            fillRule="evenodd"
          ></path>
        </svg>
      </button>
      <ReactPlayer
        // url="https://paidrez.s3.us-east-2.amazonaws.com/Paidrez+Final+Video2-01-07-2024.mp4"
        url="https://paidrez.s3.us-east-2.amazonaws.com/Paidrez%2BFinal%2BVideo2-01-07-2024.mp4"
        width="min(95vw, 800px)"
        height="auto"
        className="video-player"
        controls={true}
        playsinline={true}
        // muted={true}
        playing={true}
      />
    </div>
  );
};

export default VideoContainer;
