import axios from "axios";

const BACKEND_URL = process.env.REACT_APP_BLOCKCHAIN_BE_SERVER_URL;

class Marketplace {
  getListings(params) {
    /*
    PARAMS

    const restoId = req.query.restoId;
		const restoName = req.query.restoName;
		const minPrice = req.query.minPrice || 0;
		const maxPrice = req.query.maxPrice || 9999999999;
		const startTime = req.query.startTime || 0;
		const seatCapacity = req.query.seatCapacity;
		const endTime = req.query.endTime || 9999999999;
		const sold = req.query.sold || false;
		const pageNo = req.query.pageNo || 1;
		const pageSize = req.query.pageSize || 10;
    */

    return axios.get(BACKEND_URL + "/listings", { params });
  }

  getListingById(id) {
    return axios.get(BACKEND_URL + "/listing/" + id);
  }

  getListingByTokenId(tokenId) {
    return axios.get(BACKEND_URL + "/listing/token/" + tokenId);
  }

  createListing(restoId, tokenId, price) {
    return axios.post(BACKEND_URL + "/create-listing", {
      restoId,
      tokenId,
      price,
    });
  }

  updateListing(id) {
    return axios.post(BACKEND_URL + "/update-listing/" + id);
  }

  cancelListing(id) {
    return axios.post(BACKEND_URL + "/cancel-listing/" + id);
  }

  buyListing(id) {
    return axios.post(BACKEND_URL + "/mark-listing-sold/" + id);
  }
}

export default new Marketplace();
