import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import OtpInput from "react-otp-input";
import PhoneInputWithCountrySelect, {
  formatPhoneNumberIntl,
  isPossiblePhoneNumber,
} from "react-phone-number-input";
import PhoneInput from "react-phone-number-input/input";
import "react-phone-number-input/style.css";
import LoadingAnimationEclipse from "./Helpers/LoadingAnimationEclipse";
import UserService from "../Services/UserService";

const MobileNumberInput = ({ closeModal, fromInvite, verified }) => {
  const [mobile, setMobile] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState("");
  const [sending, setSending] = useState(false);
  const [incorrect, setIncorrect] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (otpSent) {
      setSending(false);
    }
  }, [otpSent]);

  useEffect(() => {
    if (otp.length === 6) {
      handleOtpSubmit();
    } else {
      setIncorrect(false);
    }
  }, [otp]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log(isPossiblePhoneNumber(mobile));
    if (!isPossiblePhoneNumber(mobile)) {
      // console.log("invalid");
      setError("Invalid Mobile Number");
      return;
    }

    let formattedNumber = formatPhoneNumberIntl(mobile)
      .replace(" ", "-")
      .replaceAll(" ", "");
    // formattedNumber.replace(" ", "-");
    console.log(formattedNumber);
    // return;

    try {
      //otp api call
      setSending(true);
      setError(false);
      await UserService.sendOtpForMobileUpdation(formattedNumber);
      setOtpSent(true);
    } catch (e) {
      console.log("ERROR::", e);
      setSending(false);
      if (e.response?.data?.message?.includes("phone already exists ")) {
        setError(
          "This mobile number is associated with another account. Please use a different mobile number, or sign in with the original account"
        );
      }
    }
  };

  const handleOtpSubmit = async () => {
    console.log("otp");
    setSubmitting(true);

    let formattedNumber = formatPhoneNumberIntl(mobile)
      .replace(" ", "-")
      .replaceAll(" ", "");

    try {
      const res = await UserService.verifyOtpForMobileUpdation(
        formattedNumber,
        Number(otp)
      );
      console.log(res);
      if (res.data?.status === "failed") {
        setIncorrect(true);
        setSubmitting(false);
      } else {
        setSubmitted(true);
        setSubmitting(false);
        setTimeout(() => {
          verified();
        }, 3000);
      }
    } catch (e) {
      setSubmitting(false);
      console.log("ERROR::", e);
    }
  };

  return (
    <div className="mobile-number-input">
      <div className="close-button-container" onClick={() => closeModal()}>
        <svg
          width="32"
          height="32"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.896 0.664673H34.126C42.036 0.664673 47.3327 6.21801 47.3327 14.478V33.5437C47.3327 41.7803 42.036 47.3313 34.126 47.3313H13.896C5.98602 47.3313 0.666016 41.7803 0.666016 33.5437V14.478C0.666016 6.21801 5.98602 0.664673 13.896 0.664673ZM31.0228 30.998C31.8162 30.207 31.8162 28.9237 31.0228 28.1304L26.8695 23.977L31.0228 19.8214C31.8162 19.0304 31.8162 17.7237 31.0228 16.9304C30.2295 16.1347 28.9462 16.1347 28.1295 16.9304L23.9995 21.0814L19.8462 16.9304C19.0295 16.1347 17.7462 16.1347 16.9528 16.9304C16.1595 17.7237 16.1595 19.0304 16.9528 19.8214L21.1062 23.977L16.9528 28.107C16.1595 28.9237 16.1595 30.207 16.9528 30.998C17.3495 31.3947 17.8862 31.607 18.3995 31.607C18.9362 31.607 19.4495 31.3947 19.8462 30.998L23.9995 26.8704L28.1528 30.998C28.5495 31.4204 29.0628 31.607 29.5762 31.607C30.1128 31.607 30.6262 31.3947 31.0228 30.998Z"
            fill="#D9D9D9"
          />
        </svg>
      </div>
      {!otpSent ? (
        <>
          {fromInvite ? (
            <p>Please verify your Mobile Number to accept the Invite.</p>
          ) : (
            <p>Please Enter Your Mobile Number</p>
          )}
          <Form onSubmit={handleSubmit}>
            <PhoneInputWithCountrySelect
              // placeholder="Enter phone number"
              defaultCountry="US"
              international
              withCountryCallingCode
              value={mobile}
              onChange={setMobile}
              className="mobile-input"
            />
            <Button
              type="submit"
              className="paidrez-btn w-100 mt-4"
              disabled={sending}
            >
              Submit
              {sending && (
                <LoadingAnimationEclipse width={20} className="d-inline ms-1" />
              )}
            </Button>

            {error && (
              <div className="mt-2">
                <small>
                  <b className="text-danger">{error}</b>
                </small>
              </div>
            )}
          </Form>
        </>
      ) : (
        <div className="otp-screen">
          <p className="mb-1 text-center">
            We have sent a 6-Digit Confirmation Code to
          </p>
          <p className="text-center mobile-number">
            {formatPhoneNumberIntl(mobile)}{" "}
            <small
              className="edit"
              onClick={() => {
                if (!submitting && !submitted) setOtpSent(false);
              }}
            >
              Edit
            </small>
          </p>
          <p>Enter it below to verify Mobile Number.</p>
          <OtpInput
            value={otp}
            onChange={(otp) => setOtp(otp)}
            numInputs={6}
            // separator={<span>-</span>}
            className={
              incorrect
                ? "otp-input incorrect"
                : submitted
                ? "otp-input correct"
                : "otp-input"
            }
            shouldAutoFocus={true}
            isInputNum={true}
            hasErrored={true}
            isDisabled={submitting || submitted}
          />

          <div className="loader">
            {submitting && <LoadingAnimationEclipse width={20} />}
            {incorrect && (
              <p className="text-center text-danger">
                Please Enter Correct OTP
              </p>
            )}
            {submitted && <p className="text-center">Verified ✅</p>}
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileNumberInput;
