import React from "react";
import { Button, Image } from "react-bootstrap";

const SessionExpiredPopup = ({ loginButtonRef, handleClose }) => {
  return (
    <div className="session-expired">
      <div
        className="close-button-container"
        onClick={() => {
          handleClose();
          if (window.location.pathname !== "/") window.location.pathname = "/";
        }}
      >
        <svg
          width="32"
          height="32"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.896 0.664673H34.126C42.036 0.664673 47.3327 6.21801 47.3327 14.478V33.5437C47.3327 41.7803 42.036 47.3313 34.126 47.3313H13.896C5.98602 47.3313 0.666016 41.7803 0.666016 33.5437V14.478C0.666016 6.21801 5.98602 0.664673 13.896 0.664673ZM31.0228 30.998C31.8162 30.207 31.8162 28.9237 31.0228 28.1304L26.8695 23.977L31.0228 19.8214C31.8162 19.0304 31.8162 17.7237 31.0228 16.9304C30.2295 16.1347 28.9462 16.1347 28.1295 16.9304L23.9995 21.0814L19.8462 16.9304C19.0295 16.1347 17.7462 16.1347 16.9528 16.9304C16.1595 17.7237 16.1595 19.0304 16.9528 19.8214L21.1062 23.977L16.9528 28.107C16.1595 28.9237 16.1595 30.207 16.9528 30.998C17.3495 31.3947 17.8862 31.607 18.3995 31.607C18.9362 31.607 19.4495 31.3947 19.8462 30.998L23.9995 26.8704L28.1528 30.998C28.5495 31.4204 29.0628 31.607 29.5762 31.607C30.1128 31.607 30.6262 31.3947 31.0228 30.998Z"
            fill="#D9D9D9"
          />
        </svg>
      </div>
      <Image src="/error.webp" width={200} className="mx-auto d-block" />
      <h3 className="text-muted text-center">Session Expired.</h3>

      <p className="text-muted text-center">Please sign in again.</p>

      <Button
        onClick={() => {
          handleClose();
          loginButtonRef.current?.click();
        }}
        className="paidrez-btn d-block m-auto"
      >
        Sign In
      </Button>
    </div>
  );
};

export default SessionExpiredPopup;
