import React from "react";
import { useEffect } from "react";
import { QRCode } from "react-qrcode-logo";

const QrCodeContainer = ({ qrCodeData }) => {
  useEffect(() => {
    console.log(qrCodeData);
  });
  return (
    <div className="py-3 qrcode-container">
      <h2>Rez Confirmation</h2>
      <div className="text-center">
        <QRCode
          value={qrCodeData}
          logoImage="/favicon/32_32.png"
          removeQrCodeBehindLogo={true}
          logoPadding={5}
          className="d-block m-auto"
        />
      </div>
    </div>
  );
};

export default QrCodeContainer;
