import { ethers } from "ethers";
import PaidrezABI from "./../abi/paidrezABI.json";
import MarketplaceABI from "../abi/paidrezMarketplaceABI.json";

class ContractService {
  contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
  mpContractAddress = process.env.REACT_APP_MARKETPLACE_CONTRACT_ADDRESS;
  provider = null;

  async _intializeContract(web3AuthProvider) {
    console.log("contractAddress", this.contractAddress);
    this.provider = new ethers.providers.Web3Provider(web3AuthProvider);
    console.log(
      "wallet address",
      await this.provider.listAccounts(),
      this.provider.getSigner()
    );
    const signer = this.provider.getSigner();
    const contract = new ethers.Contract(
      this.contractAddress,
      PaidrezABI,
      signer
    );

    const res = await contract.getAllRestoIds();
    console.log("contract resto ids", res);
    return contract;
  }

  async initilizeMPContract() {
    const signer = this.provider.getSigner();
    const contract = new ethers.Contract(
      this.mpContractAddress,
      MarketplaceABI,
      signer
    );
    return contract;
  }
}

export default new ContractService();
