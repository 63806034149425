import React, { useEffect } from "react";
import { Container, Nav } from "react-bootstrap";
import { Link, Outlet, useOutletContext, useParams } from "react-router-dom";
import "./Account.scss";
import { useStoreState } from "easy-peasy";

const AccountNavbar = ({ selectedAccountPage, setSelectedAccountPage }) => {
  const { page } = useParams();
  const { loginButtonRef } = useOutletContext();
  const loginLite = useStoreState((state) => state.loginLite);

  useEffect(() => {
    console.log(page);
  }, []);

  return (
    <div className="account-grey-bg">
      <Container className="account-navbar-container">
        <div className="account-navbar">
          <Nav
            variant="pills"
            // defaultActiveKey="reservations-and-notify"
            defaultActiveKey={selectedAccountPage}
          >
            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/account/reservations-and-notify"
                eventKey={0}
                active={selectedAccountPage === 0}
                onClick={() => setSelectedAccountPage(0)}
              >
                My Reservations
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/account/profile"
                eventKey={1}
                active={selectedAccountPage === 1}
                onClick={() => setSelectedAccountPage(1)}
              >
                Profile
              </Nav.Link>
            </Nav.Item>
            {/* <Nav.Item>
              <Nav.Link
                as={Link}
                to="/account/my-hit-list"
                eventKey={2}
                active={selectedAccountPage === 2}
                onClick={() => setSelectedAccountPage(2)}
              >
                My Hit List
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/account/payment-methods"
                eventKey={3}
                active={selectedAccountPage === 3}
                onClick={() => setSelectedAccountPage(3)}
              >
                Payment Methods
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/account/communication"
                eventKey={4}
                active={selectedAccountPage === 4}
                onClick={() => setSelectedAccountPage(4)}
              >
                Communication
              </Nav.Link>
            </Nav.Item> */}
            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/account/help-and-feedback"
                eventKey={5}
                active={selectedAccountPage === 5}
                onClick={() => setSelectedAccountPage(5)}
              >
                Help & Feedback
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
        <div className="outlet-container">
          <Outlet context={{ loginButtonRef }} />
        </div>
      </Container>
      {/* <div className="grey-bg-fixer"></div> */}
    </div>
  );
};

export default AccountNavbar;
