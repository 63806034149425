import { useStoreState } from "easy-peasy";
import React, { useEffect, useState } from "react";
import { Button, Image, Table } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import LoadingAnimationSpoon from "../Helpers/LoadingAnimationSpoon";
import { ethers } from "ethers";
import { getCurrentMaticUSDPrice } from "../../Services/MaticPriceService";
import MarketplaceService from "../../Services/MarketplaceService";
import CloseIcon from "../Icons/CloseIcon";

const ConfirmUpdatePopup = ({
  nft,
  restaurantName,
  closeModal,
  setModalValue,
  newPrice,
  handleModalError,
  fetchListing,
  updateBalance,
}) => {
  const [updating, setUpdating] = useState(false);

  const [gasFee, setGasFee] = useState(0);
  const [maticUsdPrice, setMaticUsdPrice] = useState(0);
  const [success, setSuccess] = useState(false);

  const mpContractInstance = useStoreState(
    (states) => states.mpContractInstance
  );

  useEffect(() => {
    estimateGasFees();
    getMaticPrice();
  }, []);

  const getMaticPrice = async () => {
    try {
      const usd = await getCurrentMaticUSDPrice(1);
      setMaticUsdPrice(usd);
    } catch (e) {
      console.log("ERROR::GETTING_PRICE_IN_USD::", e);
    }
  };

  const fetchGasPrice = async () => {
    const gas_fee_url = process.env.REACT_APP_GAS_FEES_URL;

    let gasPriceInfo;
    for (let i = 0; i < 3; i++) {
      gasPriceInfo = await fetch(gas_fee_url)
        .then((response) => response.json())
        .catch((e) => {
          console.log("fetching gas price failed, Attempt", i + 1, e);
        });

      if (gasPriceInfo) {
        break;
      }
    }

    console.log("gas price info:", gasPriceInfo);
    return gasPriceInfo;
  };

  const estimateGasFees = async () => {
    try {
      let gasPriceInfo = await fetchGasPrice();

      if (gasPriceInfo?.fast?.maxFee) {
        gasPriceInfo.fast.maxFee = Math.min(200, 3 * gasPriceInfo.fast.maxFee);
        console.log("maxFee exists, doubled:", gasPriceInfo?.fast?.maxFee);
      } else {
        console.log("couldn't fetch gas price. Using default values");
        gasPriceInfo = { fast: { maxFee: 200 } };
      }

      const options = {
        gasPrice: ethers.utils.parseUnits(
          gasPriceInfo.fast.maxFee.toFixed(6),
          "gwei"
        ), // Set your desired gas price
        // value: listingPrice,
      };

      const amountInWei = ethers.utils.parseEther(newPrice);

      // Estimate the gas required for the transaction
      const gasEstimate = await mpContractInstance.estimateGas.updateListing(
        process.env.REACT_APP_CONTRACT_ADDRESS,
        nft.tokenId,
        amountInWei,
        { gasLimit: 300000 }
      );

      console.log(gasEstimate);

      let gasFee = ethers.utils
        .formatUnits(gasEstimate.mul(options.gasPrice), "ether")
        .substring(0, 10);

      gasFee = Number(gasFee).toFixed(4);

      setGasFee(gasFee);

      console.log("Gas estimate:", gasEstimate.toString());
      console.log("Estimated gas fees:", gasFee);
    } catch (e) {
      console.log("ERROR::", e);
      handleModalError("Couldn't Estimate Gas Required for the Transaction.");
    }
  };

  const updateListing = async () => {
    try {
      setUpdating(true);

      const amountInWei = ethers.utils.parseEther(newPrice);

      const receipt = await mpContractInstance.updateListing(
        process.env.REACT_APP_CONTRACT_ADDRESS,
        nft.tokenId,
        amountInWei,
        { gasLimit: 300000 }
      );

      await receipt.wait();

      console.log("Updated ");

      updateBalance();

      // API call when transaction completes
      const res = await MarketplaceService.updateListing(nft._id);
      console.log(res);
      setUpdating(false);
      setSuccess(true);
      fetchListing();
    } catch (e) {
      console.log("ERROR::", e);
      setUpdating(false);
      handleModalError("Something went wrong while updating the price.");
    }
  };

  return (
    <div>
      <div className="sale-confirmation w-100">
        {!success && (
          <div
            className="back-button-container"
            onClick={() => setModalValue(2)}
          >
            <img src="/back-button.svg" alt="back" />
          </div>
        )}

        <CloseIcon closeModal={closeModal} />

        {!success ? (
          <>
            <h5 className="header">Update</h5>

            <hr className="mt-0" />

            <div className="selling-details-container">
              <Table borderless className="mb-0">
                <tbody>
                  <tr>
                    <td>
                      <h3>
                        {nft.tokenName ||
                          `PAIDREZ ${nft?.restoName} #${nft?.tokenId
                            ?.toString()
                            .padStart(5, "0")}`}
                      </h3>
                    </td>
                    <td>
                      <h3 className="price green">{newPrice} POL</h3>{" "}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {" "}
                      <p>
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 24 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="align-text-bottom"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M3.48633 11.3716C3.48633 6.52804 7.4128 2.60156 12.2563 2.60156C17.0999 2.60156 21.0264 6.52804 21.0264 11.3716C21.0264 14.3207 19.2628 16.8205 17.2308 18.5815L13.8773 21.4879C12.947 22.2942 11.5657 22.2942 10.6354 21.4879L7.28185 18.5815C5.24985 16.8205 3.48633 14.3207 3.48633 11.3716ZM12.2563 13.3205C13.3327 13.3205 14.2052 12.448 14.2052 11.3716C14.2052 10.2952 13.3327 9.42269 12.2563 9.42269C11.18 9.42269 10.3075 10.2952 10.3075 11.3716C10.3075 12.448 11.18 13.3205 12.2563 13.3205Z"
                            fill="#7F8596"
                          />
                        </svg>
                        {nft.restoData?.name || restaurantName}
                      </p>
                    </td>
                    <td>
                      <p className="price">
                        ~ $ {(newPrice * maticUsdPrice).toFixed(2)} USD
                      </p>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>

            <div className="gas-fee-container">
              <h4>Estimated Gas fee</h4>
              <div className="d-flex justify-content-between text-muted mb-5">
                {gasFee && maticUsdPrice ? (
                  <>
                    <b>{gasFee} POL</b>
                    <b> ~ $ {(gasFee * maticUsdPrice).toFixed(4)} USD</b>
                  </>
                ) : (
                  <Skeleton containerClassName="w-100" className="h-100" />
                )}
              </div>
            </div>

            <Button
              className="paidrez-btn w-100 mb-2"
              onClick={updateListing}
              disabled={!gasFee}
            >
              Continue{" "}
              <svg
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="align-text-bottom"
              >
                <path
                  d="M11.6935 5.57568L17.0867 10.8106M17.0867 10.8106L11.6935 16.0455M17.0867 10.8106L3.2666 10.8106"
                  stroke="#FCFCF9"
                  stroke-width="2.0102"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Button>
          </>
        ) : (
          <div className="success-container">
            <Image src={nft.s3ImageURI} width={150} className="nft-image" />
            <h2 className="text-center">Congratulations!</h2>
            <p className="text-center">Awesome, your item was Updated!</p>
          </div>
        )}

        {updating && (
          <div className="loader-container">
            <LoadingAnimationSpoon width={150} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ConfirmUpdatePopup;
