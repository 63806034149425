import { createStore, action, persist } from "easy-peasy";

const MainStorage = createStore(
  persist(
    {
      contractInstance: null,
      setContractInstance: action((state, payload) => {
        state.contractInstance = payload;
      }),

      mpContractInstance: null,
      setMPContractInstance: action((state, payload) => {
        state.mpContractInstance = payload;
      }),

      provider: null,
      setProvider: action((state, payload) => {
        state.provider = payload;
      }),

      balance: null,
      setBalance: action((state, payload) => {
        state.balance = payload;
      }),

      refreshBalance: 0,
      setRefreshBalance: action((state, payload) => {
        state.refreshBalance = payload;
      }),

      account: null,
      setAccount: action((state, payload) => {
        state.account = payload;
      }),

      isLoggedIn: false,
      setLoggedIn: action((state, payload) => {
        state.isLoggedIn = payload;
      }),

      isAdminLoggedIn: false,
      setAdminLoggedIn: action((state, payload) => {
        state.isAdminLoggedIn = payload;
      }),

      role: null,
      setRole: action((state, payload) => {
        state.role = payload;
      }),

      email: null,
      setEmail: action((state, payload) => {
        state.email = payload;
      }),

      logout: false,
      setLogout: action((state, payload) => {
        state.logout = payload;
      }),

      logoutLite: false,
      setLogoutLite: action((state, payload) => {
        state.logoutLite = payload;
      }),

      cityId: 1,
      setCityId: action((state, payload) => {
        state.cityId = payload;
      }),

      name: null,
      setName: action((state, payload) => {
        state.name = payload;
      }),

      web3AuthReady: false,
      setWeb3AuthReady: action((state, payload) => {
        state.web3AuthReady = payload;
      }),

      loginLite: false,
      setLoginLite: action((state, payload) => {
        state.loginLite = payload;
      }),

      accountLite: null,
      setAccountLite: action((state, payload) => {
        state.accountLite = payload;
      }),
    },

    {
      allow: [
        "isLoggedIn",
        "isAdminLoggedIn",
        "email",
        "role",
        "logout",
        "cityId",
        "loginLite",
        "accountLite",
      ],
      storage: "localStorage",
    }
  )
);

export default MainStorage;
