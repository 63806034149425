import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "../scss/allRestaurants.scss";
import RestaurantsService from "../Services/RestaurantsService";
import LoadingAnimationSpoon from "./Helpers/LoadingAnimationSpoon";

const AllRestaurants = ({ cityId }) => {
  const [cities, setCities] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchCities();
  }, []);

  useEffect(() => {
    if (cities.length > 0) fetchRestaurants();
  }, [cities]);

  const fetchCities = async () => {
    setLoading(true);
    try {
      const res = await RestaurantsService.getCities();
      console.log(res.data);
      setCities(res.data);
    } catch (e) {
      console.log("ERROR:", e);
    }
  };

  const fetchRestaurants = async () => {
    try {
      setLoading(true);
      const res = await Promise.all(
        cities.map(async (city, index) => {
          const restaurants = await RestaurantsService.getRestaurantsByCityId(
            city.cityId
          );

          return {
            cityId: city.cityId,
            city: city.city,
            restaurants: restaurants.data?.restaurants,
          };
        })
      );
      console.log("Citywise restaurants", res);
      setData(res);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Container className="all-restaurants page-container">
      {loading ? (
        <LoadingAnimationSpoon className="loader" width={150} />
      ) : (
        <>
          <h1 className="paidrez-color">All Restaurants</h1>

          <hr />
          {data.map(
            (city, i) =>
              city.restaurants?.length > 0 && (
                <div key={i}>
                  <Row>
                    <h4>{city.city}</h4>
                  </Row>

                  <Row className="g-4 restaurants-row">
                    {city.restaurants.map((restaurant, i) => (
                      <Col xs={8} sm={5} md={5} lg={3} key={i}>
                        <Link
                          to={`/restaurant/${restaurant._id}`}
                          className="card-link-container"
                        >
                          <Card>
                            <Card.Img
                              variant="top"
                              src={
                                restaurant.displayImageS3Url ||
                                "/placeholder-restaurant.png"
                              }
                              alt={"./paidrez-logo.png"}
                            />
                            <Card.Body>
                              <Card.Title className="mb-0">
                                {restaurant.name}
                              </Card.Title>
                              <p>{restaurant.location}</p>
                              {/* <div className="paidrez-color fw-bold rating">
                                  <div className="star">
                                    <svg
                                      height="19"
                                      viewBox="0 0 20 19"
                                      width="20"
                                    >
                                      <path d="m19.3266897 6.81209154-6.0333852-.87390272-2.7043055-5.44738083c-.1520997-.32725431-.3486048-.49080799-.5889252-.49080799-.24032046 0-.43667804.16355368-.58892523.49080799l-2.70445309 5.44738083-6.03368026.87390272c-.44862764.07185782-.67301522.25539649-.67301522.55076297 0 .16766824.10017039.35928908.30051118.57471558l4.37504149 4.23814161-1.03356962 5.9860941c-.01608037.1118278-.0240468.1917678-.0240468.239526 0 .1676683.04204501.3091797.12613503.425122.08409003.1157954.21022506.1735462.37855262.1735462.14428078 0 .30449439-.0476113.48078838-.1435687l5.39651396-2.8256727 5.39695656 2.8256727c.1684751.0958104.3286887.1435687.4806408.1435687.1606562 0 .2826605-.0576038.3667505-.1735462.0839425-.1157954.1259875-.2574537.1259875-.425122 0-.1037456-.0039832-.1835387-.0120971-.239526l-1.0337172-5.9860941 4.3629444-4.23814161c.2086022-.20749128.3126083-.39911212.3126083-.57471558 0-.29536648-.2242401-.47890515-.6733103-.55076297z"></path>
                                    </svg>
                                  </div>
                                  <p>
                                    {(
                                      Math.round(restaurant.rating * 10) / 10
                                    ).toFixed(1)}
                                  </p>
                                </div> */}
                              <p> {restaurant.cuisineType}</p>
                            </Card.Body>
                          </Card>
                        </Link>
                      </Col>
                    ))}
                  </Row>

                  <hr />
                </div>
              )
          )}
        </>
      )}
    </Container>
  );
};

export default AllRestaurants;
