import emailjs from "@emailjs/browser";

class EmailService {
  mintConfirmationEmail({
    to,
    to_name = "Guest",
    restaurant,
    date,
    time,
    guests,
    nft_image_url,
    address,
    map,
    nft_name,
    token_id,
    restaurant_email,
    restaurant_phone,
  }) {
    return emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        "template_2lr51gg",
        {
          // to: "hooman@developingnow.com",
          // to_name: "Hooman",
          // restaurant: "PaidRez",
          // date: "20th September",
          // time: "10 PM",
          // guests: "4",
          // nft_image_url: "https://paidrez.s3.us-east-2.amazonaws.com/188",
          // address: "49, Paidrez Street, NYC, 12345",
          // map: "https://goo.gl/maps/Noa4QAZcLb89zA3U6",
          // nft_name: "Paidrez PaidRez #00058",
          // token_id: "100000070",
          // restaurant_email: "paidrezrestaurant@gmail.com",
          // restaurant_phone: "+1 999-999-9999",
          // reply_to: ""

          to,
          to_name,
          restaurant,
          date,
          time,
          guests,
          nft_image_url,
          address,
          map,
          nft_name,
          token_id,
          restaurant_email,
          restaurant_phone,
          reply_to: "paidrezrestaurant@gmail.com",
        },
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          throw new Error("Email not sent :: " + error.text);
        }
      );
  }
}

export default new EmailService();
