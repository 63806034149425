import { useStoreState } from "easy-peasy";
import React, { useEffect, useState } from "react";
import { Button, Col, Image, Row } from "react-bootstrap";
import { getCurrentMaticUSDPrice } from "../../Services/MaticPriceService";
import { ethers } from "ethers";
import Skeleton from "react-loading-skeleton";
import MarketplaceService from "../../Services/MarketplaceService";
import LoadingAnimationSpoon from "../Helpers/LoadingAnimationSpoon";
import CloseIcon from "../Icons/CloseIcon";

const CancelListing = ({
  nft,
  closeModal,
  handleModalError,
  setMoveToMyReservation,
  updateBalance,
}) => {
  const [cancelling, setCancelling] = useState(false);

  const [gasFee, setGasFee] = useState(0);
  const [maticUsdPrice, setMaticUsdPrice] = useState(0);
  const [cancelled, setCancelled] = useState(false);

  const mpContractInstance = useStoreState(
    (states) => states.mpContractInstance
  );

  useEffect(() => {
    estimateGasFees();
    getMaticPrice();
  }, []);

  const getMaticPrice = async () => {
    try {
      const usd = await getCurrentMaticUSDPrice(1);
      setMaticUsdPrice(usd);
    } catch (e) {
      console.log("ERROR::GETTING_PRICE_IN_USD::", e);
    }
  };

  const fetchGasPrice = async () => {
    const gas_fee_url = process.env.REACT_APP_GAS_FEES_URL;

    let gasPriceInfo;
    for (let i = 0; i < 3; i++) {
      gasPriceInfo = await fetch(gas_fee_url)
        .then((response) => response.json())
        .catch((e) => {
          console.log("fetching gas price failed, Attempt", i + 1, e);
        });

      if (gasPriceInfo) {
        break;
      }
    }

    console.log("gas price info:", gasPriceInfo);
    return gasPriceInfo;
  };

  const estimateGasFees = async () => {
    try {
      let gasPriceInfo = await fetchGasPrice();

      if (gasPriceInfo?.fast?.maxFee) {
        gasPriceInfo.fast.maxFee = Math.min(200, 3 * gasPriceInfo.fast.maxFee);
        console.log("maxFee exists, doubled:", gasPriceInfo?.fast?.maxFee);
      } else {
        console.log("couldn't fetch gas price. Using default values");
        gasPriceInfo = { fast: { maxFee: 200 } };
      }

      const options = {
        gasPrice: ethers.utils.parseUnits(
          gasPriceInfo.fast.maxFee.toFixed(6),
          "gwei"
        ), // Set your desired gas price
        // value: listingPrice,
      };

      // Estimate the gas required for the transaction
      const gasEstimate = await mpContractInstance.estimateGas.cancelListing(
        process.env.REACT_APP_CONTRACT_ADDRESS,
        nft.tokenId,
        { gasLimit: 300000 }
      );

      console.log(gasEstimate);

      let gasFee = ethers.utils
        .formatUnits(gasEstimate.mul(options.gasPrice), "ether")
        .substring(0, 10);

      gasFee = Number(gasFee).toFixed(4);

      setGasFee(gasFee);

      console.log("Gas estimate:", gasEstimate.toString());
      console.log("Estimated gas fees:", gasFee);
    } catch (e) {
      console.log("ERROR::", e);
      handleModalError("Couldn't Estimate Gas Required for the Transaction.");
    }
  };

  const cancelListing = async () => {
    try {
      setCancelling(true);

      const receipt = await mpContractInstance.cancelListing(
        process.env.REACT_APP_CONTRACT_ADDRESS,
        nft.tokenId,
        { gasLimit: 300000 }
      );

      await receipt.wait();

      console.log("Cancelled");

      updateBalance();

      // API call when transaction completes
      const res = await MarketplaceService.cancelListing(nft._id);
      console.log(res);
      setCancelling(false);
      setCancelled(true);
      setMoveToMyReservation(true);
      // fetchListing();
    } catch (e) {
      console.log("ERROR::", e);
      setCancelling(false);
      handleModalError("Couldn't Cancel Listing at the Moment.");
    }
  };

  return (
    <div>
      <CloseIcon closeModal={closeModal} />

      <Image
        src={nft.s3ImageURI}
        width={150}
        height={150}
        roundedCircle
        className="d-block mx-auto mb-4 object-fit-cover"
      />

      <p className="text-center">
        {nft.tokenName ||
          `PAIDREZ ${nft?.restoName} #${nft?.tokenId
            ?.toString()
            .padStart(5, "0")}`}
      </p>

      {!cancelled ? (
        <>
          <h5 className="text-center mb-4">
            Are you sure to Delete this Rez from Marketplace?
          </h5>

          <hr />

          <div className="gas-fee-container">
            <p>Estimated Gas fee</p>
            <div className="d-flex justify-content-between text-muted mb-5">
              {gasFee && maticUsdPrice ? (
                <>
                  <b>{gasFee} POL</b>
                  <b> ~ $ {(gasFee * maticUsdPrice).toFixed(4)} USD</b>
                </>
              ) : (
                <Skeleton containerClassName="w-100" className="h-100" />
              )}
            </div>
          </div>

          <Row>
            <Col className="pe-1" onClick={closeModal}>
              <Button className="paidrez-btn-secondary w-100">Cancel</Button>
            </Col>
            <Col className="ps-1">
              <Button
                variant="danger w-100"
                disabled={!gasFee}
                onClick={cancelListing}
              >
                Delete
              </Button>
            </Col>
          </Row>
        </>
      ) : (
        <h4 className="text-center text-danger">Cancelled</h4>
      )}

      {cancelling && (
        <div className="loader-container">
          <LoadingAnimationSpoon width={150} />
        </div>
      )}
    </div>
  );
};

export default CancelListing;
