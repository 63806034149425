import React, { useEffect, useState } from "react";
import { Button, Image } from "react-bootstrap";
import "../scss/invitation.scss";
import { useStoreState } from "easy-peasy";
import LoadingAnimationReservation from "./Helpers/LoadingAnimationReservation";
import LoadingAnimationNFT from "./Helpers/LoadingAnimationNFT";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import LoadingAnimationEclipse from "./Helpers/LoadingAnimationEclipse";
import {
  acceptInvitation,
  getInvitation,
} from "../Services/BlockchainBackendService";
import { AnimatePresence } from "framer-motion";
import ModalBox from "./Modal/ModalBox";
import MobileNumberInput from "./MobileNumberInput";
import UserService from "../Services/UserService";

const res = {
  restaurant: "PaidRez",
  guests: 4,
  slotTime: 1700628300,
  invitee: "0xe0005eC8266263f0649E6dd1E7f650a23E3C7B012",
  inviterName: "Todd Smith",
  inviteeName: "Naman Mehta",
  s3ImageURI:
    "https://paidrez.s3.us-east-2.amazonaws.com/PaidRez-0xc76814f67e3e8e66722f4dcb142fb26dfdc9160a4be0c4d6149236d38f458eab",
  location: "New York",
};

const Invitation = ({ loginButtonRef }) => {
  const [loading, setLoading] = useState(true);
  const [invitation, setInvitation] = useState({});
  const [notFound, setNotFound] = useState(false);
  const [accepting, setAccepting] = useState(false);
  const [accepted, setAccepted] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalNumber, setModalNumber] = useState(0);
  const isLoggedIn = useStoreState((states) => states.isLoggedIn);
  const account = useStoreState((state) => state.account);
  const web3AuthReady = useStoreState((state) => state.web3AuthReady);

  const { inviteId } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    fetchInvitation();
  }, []);

  const closeModal = () => {
    setModalNumber(0);
  };

  useEffect(() => {
    if (modalNumber === 0) {
      setModal(false);
    } else {
      setModal(true);
    }
  }, [modalNumber]);

  const fetchInvitation = async () => {
    setLoading(true);
    setNotFound(false);
    try {
      const res1 = await getInvitation(inviteId);
      if (!res1.data) {
        throw new Error("Invite Not found");
      }
      console.log("invite:", res1);
      if (res1.data?.inviteData?.status === "ACCEPTED") {
        if (res1.data?.ownerAddress?.toLowerCase() === account?.toLowerCase()) {
          setAccepted(true);
        } else {
          setNotFound(true);
        }
      }
      setInvitation(res1.data);
      setLoading(false);
    } catch (e) {
      setNotFound(true);
      setLoading(false);
      console.log("ERROR::", e);
    }
  };

  const handleAccept = async () => {
    if (!isLoggedIn) {
      loginButtonRef.current?.click();
      return;
    }

    try {
      setAccepting(true);
      const user = await UserService.getUser();
      if (!user.mobile || user.mobile === " ") {
        setModalNumber(1);
        setAccepting(false);
        return;
      }

      if (user.mobile?.replace("-", "") !== invitation?.inviteData?.mobile) {
        setModalNumber(2);
        setAccepting(false);
        return;
      }

      // setModalNumber(3);
      const res = await acceptInvitation(inviteId);
      console.log(res);

      setAccepted(true);
      setAccepting(false);
    } catch (e) {
      console.log("ERROR::", e);
      setAccepting(false);
    }
  };

  if (loading) {
    return (
      <div className="page-container invitation">
        <LoadingAnimationNFT />
      </div>
    );
  } else {
    return (
      <div className="page-container invitation">
        {!notFound && (
          <div className="invitation-container">
            <div className="image-container mb-3">
              <Image src="/mail-dynamic-gradient.png" alt="" width={80} />
            </div>
            <h2>Hello {invitation?.inviteData?.inviteeName}</h2>
            <b className="content">
              You've received an exclusive invitation at{" "}
              {invitation?.restoData?.name}, {invitation?.restoData?.location}
            </b>

            <hr />

            <div className="reservation">
              <div className="reservation-image-container">
                <Image
                  className="invite-image"
                  src={invitation?.reservation?.s3ImageURI}
                  width={130}
                  rounded
                />
              </div>

              <div className="details-container">
                <h3 className="restaurant-name">{invitation.restaurant}</h3>
                <p className="text-muted">Date & Time</p>
                <p className="mb-1">
                  {moment(invitation?.reservation?.slotTime * 1000).format(
                    "MMM Do YYYY"
                  )}{" "}
                  at{" "}
                  {moment(invitation?.reservation?.slotTime * 1000).format(
                    "LT"
                  )}
                </p>
                <p className="text-muted">Guests</p>
                <p>{invitation?.reservation?.seatCapacity} Guests</p>
              </div>
            </div>

            {accepted && (
              <div className="mt-4">
                {" "}
                <b>Accepted ✅ </b> <br />{" "}
              </div>
            )}

            <Button
              className="w-100 paidrez-btn mt-4"
              onClick={() => {
                if (accepted) {
                  navigate("/account/reservations-and-notify");
                } else {
                  handleAccept();
                }
              }}
              disabled={accepting}
            >
              {accepted ? "Go to Your Reservations" : "Accept Now"}
              {accepting && (
                <LoadingAnimationEclipse width={20} className="d-inline ms-1" />
              )}
            </Button>
          </div>
        )}

        {notFound && (
          <div className="mt-5">
            <Image src="/error.webp" width={200} className="mx-auto d-block" />
            <p className="text-center text-muted">
              <b>The page you are looking for doesn't exist.</b>
            </p>
          </div>
        )}

        <AnimatePresence initial={false} exit={true}>
          {modal && (
            <ModalBox
              modalOpen={modal}
              handleClose={closeModal}
              children={
                {
                  1: (
                    <MobileNumberInput
                      closeModal={closeModal}
                      fromInvite={true}
                      verified={closeModal}
                    />
                  ),
                  2: (
                    <div className="invite-different-account">
                      <p p>
                        <b>The Invite is not for this account.</b>
                      </p>
                      <p>
                        Make sure you are using the same mobile number in this
                        account, on which the invite has been receved.
                      </p>
                      <p
                        className="check-mobile"
                        onClick={() => navigate("/account/profile")}
                      >
                        Check Mobile
                      </p>
                    </div>
                  ),
                  3: <>Accepting...</>,
                }[modalNumber]
              }
              style_class="invite-modal"
            />
          )}
        </AnimatePresence>
      </div>
    );
  }
};

export default Invitation;
