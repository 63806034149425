// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.invitation .invitation-container {
  background-color: #2a3240;
  width: 500px;
  max-width: 90%;
  margin: auto;
  text-align: center;
  box-shadow: 0 0 6px 0px #5956ff;
  background-color: #0f0f0f;
  padding: 1rem;
  border-radius: 1rem;
  margin-top: 3rem;
}
.invitation .invitation-container .image-container {
  width: max-content;
  margin: auto;
  border-radius: 100rem;
  padding: 0.5rem;
  background-color: rgba(89, 86, 255, 0.2901960784);
}
.invitation .invitation-container .content {
  color: #c8c8c8 !important;
}
.invitation .invitation-container .reservation {
  display: flex;
  justify-content: space-around;
  gap: 1rem;
  margin-top: 2rem;
}
.invitation .invitation-container .reservation .reservation-image-container {
  flex-grow: 1;
}
.invitation .invitation-container .reservation .details-container {
  flex-grow: 2;
  text-align: start;
}
.invitation .invitation-container .reservation .details-container p {
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 0;
}`, "",{"version":3,"sources":["webpack://./src/scss/invitation.scss"],"names":[],"mappings":"AAME;EACE,yBAAA;EACA,YAAA;EACA,cAAA;EACA,YAAA;EACA,kBAAA;EACA,+BAAA;EACA,yBAXS;EAYT,aAAA;EACA,mBAAA;EACA,gBAAA;AALJ;AAMI;EACE,kBAAA;EACA,YAAA;EACA,qBAAA;EACA,eAAA;EACA,iDAAA;AAJN;AAOI;EACE,yBAAA;AALN;AAQI;EACE,aAAA;EACA,6BAAA;EACA,SAAA;EACA,gBAAA;AANN;AAOM;EACE,YAAA;AALR;AAOM;EACE,YAAA;EAEA,iBAAA;AANR;AAOQ;EACE,mBAAA;EACA,gBAAA;EACA,gBAAA;AALV","sourcesContent":["$paidrez-color: #5956ff;\n$paidrez-color-light: #7876ff;\n$dark-color: #0f0f0f;\n$dark-color-secondary: #080d27;\n\n.invitation {\n  .invitation-container {\n    background-color: #2a3240;\n    width: 500px;\n    max-width: 90%;\n    margin: auto;\n    text-align: center;\n    box-shadow: 0 0 6px 0px $paidrez-color;\n    background-color: $dark-color;\n    padding: 1rem;\n    border-radius: 1rem;\n    margin-top: 3rem;\n    .image-container {\n      width: max-content;\n      margin: auto;\n      border-radius: 100rem;\n      padding: 0.5rem;\n      background-color: #5956ff4a;\n    }\n\n    .content {\n      color: #c8c8c8 !important;\n    }\n\n    .reservation {\n      display: flex;\n      justify-content: space-around;\n      gap: 1rem;\n      margin-top: 2rem;\n      .reservation-image-container {\n        flex-grow: 1;\n      }\n      .details-container {\n        flex-grow: 2;\n\n        text-align: start;\n        p {\n          font-size: 0.875rem;\n          font-weight: 500;\n          margin-bottom: 0;\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
