import { useStoreState } from "easy-peasy";
import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import paidrezLogo from "../assets/paidrez-logo.png";

const Footer = () => {
  const role = useStoreState((states) => states.role);
  return (
    <footer className="footer fixed text-center">
      <div>
        <Image src={paidrezLogo} alt="paidrez-logo" height={40} />
        <p className="my-1">
          <small>v1.8</small>
        </p>
      </div>
      <Row className="copyright-terms-container">
        <Col md={6} className="left-col">
          <small>© 2023 DevelopingNow, Inc.</small>
        </Col>
        <Col className="right-col">
          <small>
            <b>
              <a> Terms of Service</a>
            </b>
          </small>
          <br />
          {role === "ADMIN" && <Link to="/admin/all-restaurants">Admin</Link>}
        </Col>
      </Row>
    </footer>
  );
};

export default Footer;
