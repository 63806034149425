import React from "react";
import loading from "../../eclipse-loading-animation.gif";

const LoadingAnimationEclipse = ({ className, width }) => {
  return (
    <div className={className}>
      <img src={loading} alt="loading" width={width} />
    </div>
  );
};

export default LoadingAnimationEclipse;
