import React from "react";

const CalendarTimeIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon"
    >
      <path
        d="M12.0186 2.78455V4.95788"
        stroke="#9E9E9E"
        strokeWidth="1.14435"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.68335 2.78455V4.95788"
        stroke="#9E9E9E"
        strokeWidth="1.14435"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.2903 8.79628V6.89384C15.2963 4.90756 14.0908 3.82758 12.1461 3.82758H6.55468C4.61516 3.82758 3.40454 4.90756 3.40454 6.89384V12.869C3.40454 14.8864 4.61516 15.9917 6.55468 15.9917H7.96496"
        stroke="#9E9E9E"
        strokeWidth="1.14435"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0491 13.5382C16.0491 15.1831 14.7153 16.5169 13.0705 16.5169C11.4257 16.5169 10.0918 15.1831 10.0918 13.5382C10.0918 11.8934 11.4257 10.5596 13.0705 10.5596C14.7153 10.5596 16.0491 11.8934 16.0491 13.5382Z"
        stroke="#9E9E9E"
        strokeWidth="1.14435"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.9959 14.3116L13.054 13.749V12.5384"
        stroke="#9E9E9E"
        strokeWidth="1.14435"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.4647 7.84631H6.2312"
        stroke="#9E9E9E"
        strokeWidth="1.14435"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CalendarTimeIcon;
