import { useEffect, useState } from "react";
import { Alert, Button, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import AuthenticateAdminService from "../../Services/AuthenticateAdminService";
import RestaurantAdminDashboard from "./RestaurantAdminDashboard";

const RestaurantAdminLogin = () => {
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [showAlert, setShowAlert] = useState(false);
  const [dashboard, setDashboard] = useState(true);

  let navigate = useNavigate();

  // useEffect(() => {
  //   if (sessionStorage.getItem("admin") === "true") {
  //     navigate("../adminDashboard");
  //   }
  // }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    AuthenticateAdminService.authenticateAdmin(username, password)
      .then((response) => {
        console.log("Logged in");
        console.log(response);
        sessionStorage.setItem("admin", true);
        // navigate("../adminDashboard");
        setDashboard(true);
      })
      .catch((err) => {
        console.log("Error loggin in : ", err.response.data.message);
        sessionStorage.setItem("admin", false);
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
      });

    // navigate("../adminDashboard");
  };

  if (!dashboard) {
    return (
      <>
        <div
          className="resy-logo text-center mt-5"
          onClick={() => navigate("../")}
        >
          <Link to="/">
            <img src="/paidrez-logo.png" alt="paidrez-logo" height={40} />
          </Link>
        </div>
        <h3 className="text-center">Restaurant Name</h3>
        <Form className="admin-login container">
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Admin Username</Form.Label>
            <Form.Control
              type="text"
              required
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Admin Password</Form.Label>
            <Form.Control
              type="password"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>

          <Button variant="primary" type="submit" onClick={handleSubmit}>
            Login
          </Button>
          <Alert className="admin-alert" variant="danger" show={showAlert}>
            <svg
              class="bi flex-shrink-0 me-2"
              width="24"
              height="24"
              role="img"
              aria-label="Danger:"
            >
              <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
            </svg>
            Access Denied
          </Alert>
        </Form>
      </>
    );
  } else {
    return <RestaurantAdminDashboard />;
  }
};

export default RestaurantAdminLogin;
