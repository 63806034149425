import React from "react";
import {
  Button,
  Container,
  Form,
  FormCheck,
  FormSelect,
  InputGroup,
  Pagination,
  Table,
} from "react-bootstrap";
import LoadingAnimationSpoon from "../Helpers/LoadingAnimationSpoon";
import { useState } from "react";
import RestaurantsService from "../../Services/RestaurantsService";
import { useEffect } from "react";
import { useOutletContext } from "react-router";
import { AnimatePresence } from "framer-motion";
import ModalBox from "../Modal/ModalBox";
import AddNewForm from "./AddNewForm";
import EditRestaurant from "./EditRestaurant";

const AllRestaurants = () => {
  const { contractInstance, setWeb3Loading, setError } = useOutletContext();
  const [modalOpen, setModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [restaurantsPageNo, setRestaurantsPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [switchChecked, setSwitchChecked] = useState(true);
  const [selectedRestaurant, setSelectedRestaurant] = useState(null);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (restaurantsPageNo === 1) {
        fetchData();
      } else {
        setRestaurantsPageNo(1);
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  useEffect(() => {
    fetchData();
  }, [restaurantsPageNo, pageSize]);

  useEffect(() => {
    if (selectedRestaurant) {
      setModalOpen(true);
    }
  }, [selectedRestaurant]);

  async function fetchData() {
    console.log("inside fetchData()");
    setLoading(true);
    await RestaurantsService.getRestaurants({
      pageNo: restaurantsPageNo,
      pageSize,
      searchParam: searchTerm,
      // isActive: false,
    })
      .then((res) => {
        console.log("Result", res.data);
        setSearchResults(res.data.restaurants);
        setTotalPages(res.data.totalPages);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }

  const registerRestaurantOnchain = async (restoData) => {
    setWeb3Loading(true);
    console.log("data", restoData);
    try {
      if (typeof window.ethereum !== "undefined") {
        const _name = restoData.name;
        const _location = restoData.location;
        const _creator = restoData.creatorAddress;
        const _royaltyPercentBPS = restoData.royaltyPercent;
        const _restoAddress = restoData.walletAddress;

        if (!contractInstance) {
          throw new Error("Contract instance not set");
        }

        const transaction = await contractInstance.registerRestaurant(
          _name,
          _location,
          _creator,
          _royaltyPercentBPS,
          _restoAddress,
          {
            gasLimit: 300000,
          }
        );

        transaction
          .wait()
          .then(async (res) => {
            console.log(res);
            RestaurantsService.registerRestaurant(restoData._id).then(() => {
              console.log("registered");
              setWeb3Loading(false);
              fetchData();
            });
          })
          .catch((e) => {
            setWeb3Loading(false);
            console.error(e);
            setError();
          });
      }
    } catch (error) {
      setWeb3Loading(false);
      console.log("ERROR::registerRestaurantOnchain", error);
      setError();
    }
  };

  const handleRestaurantAddedSuccessfully = () => {
    console.log("handleRestaurant added successfully function");
    setModalOpen(false);
    fetchData();
    // setChildReload(!childReload); // For setting restaurant list to page 1 after restaurant added.
  };

  const handleEdit = (id) => {
    console.log("edit button clicked for id:", id);
  };

  const handleDelete = (id, name) => {
    if (
      window.confirm(
        "Are you sure you want to delete restaurant " + id + " : " + name + "?"
      )
    ) {
      RestaurantsService.deleteRestaurant(id)
        .then(() => {
          console.log("restaurant with id=", id, " deleted successfully");
          fetchData();
        })
        .catch(() => console.log("can't delete"));
    }
  };

  const handleDisable = async (id, name, status) => {
    console.log(status);
    const action = status ? "disable" : "enable";
    if (window.confirm("Are you sure to " + action + " " + name + "?")) {
      try {
        setLoading(true);
        const res = await RestaurantsService.toggleRestaurant(id);
        console.log(res);
        fetchData();
      } catch (e) {
        console.log("ERROR::", e);
        setLoading(false);
        setError();
      }
    }
  };

  return (
    <Container className="restaurants-list">
      <div className="header">
        <Button className="paidrez-btn" onClick={() => setModalOpen(true)}>
          Add New
        </Button>
        <div className="search-box-container">
          {/* <Form.Label className="restaurants-details-label">
            Restaurant Details
          </Form.Label> */}
          <div className="search-box">
            <svg
              width="14"
              height="14"
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="search-icon"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16 9C16 12.866 12.866 16 9 16C5.13401 16 2 12.866 2 9C2 5.13401 5.13401 2 9 2C12.866 2 16 5.13401 16 9ZM16.0319 14.6177C17.2635 13.078 18 11.125 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18C11.125 18 13.078 17.2635 14.6177 16.0319L17.2929 18.7071C17.6834 19.0976 18.3166 19.0976 18.7071 18.7071C19.0976 18.3166 19.0976 17.6834 18.7071 17.2929L16.0319 14.6177Z"
                fill="#6F767E"
              />
            </svg>
            <Form.Control
              type="search"
              placeholder="Search restaurants"
              value={searchTerm}
              onChange={(e) => {
                (e.target?.value?.replace(/\s/g, "").length ||
                  !e.target?.value?.length) &&
                  setSearchTerm(e.target.value);
              }}
              className="search-box"
            />
          </div>
        </div>
        {/* <div className="filters-container">
          <DropdownButton
            title="City"
            id="dropdown-button-example"
            className="me-1"
          >
            <Dropdown.Header>Dropdown Header</Dropdown.Header>
            <Dropdown.Item eventKey="1">Action</Dropdown.Item>
            <Dropdown.Item eventKey="2">Another action</Dropdown.Item>
            <Dropdown.Item eventKey="3">Something else here</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item eventKey="4">Separated link</Dropdown.Item>
            <Dropdown.ItemText>Non-interactive text</Dropdown.ItemText>
          </DropdownButton>

          <DropdownButton title="Country" id="dropdown-button-example">
            <Dropdown.Header>Dropdown Header</Dropdown.Header>
            <Dropdown.Item eventKey="1">Action</Dropdown.Item>
            <Dropdown.Item eventKey="2">Another action</Dropdown.Item>
            <Dropdown.Item eventKey="3">Something else here</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item eventKey="4">Separated link</Dropdown.Item>
            <Dropdown.ItemText>Non-interactive text</Dropdown.ItemText>
          </DropdownButton>
        </div> */}
      </div>

      {loading ? (
        // <h1>Loading...</h1>
        <div className="restaurants-loading-animation-container">
          <LoadingAnimationSpoon className={"restaurants-loading-animation"} />
        </div>
      ) : (
        <>
          <div className="table-container">
            <Table striped bordered hover>
              <thead className="text-bg-secondary">
                <tr>
                  <th rowSpan={2}>Status</th>
                  <th rowSpan={2} style={{ minWidth: "250px" }}>
                    Name
                  </th>
                  <th rowSpan={2}>Location</th>
                  <th rowSpan={2}>Creator</th>
                  <th rowSpan={2} className="nowrap">
                    Royalty %
                  </th>
                  <th rowSpan={2}>Wallet Address</th>
                  <th rowSpan={2}>Mobile</th>
                  <th rowSpan={2}>Status</th>
                  <th rowSpan={2}></th>
                </tr>
              </thead>

              <tbody>
                {searchResults.map((res, index) => (
                  <tr key={index}>
                    <td className="text-center">
                      <FormCheck
                        type="switch"
                        checked={res.isActive}
                        onChange={() => {
                          handleDisable(res._id, res.name, res.isActive);
                        }}
                      />
                    </td>
                    <td className="nowrap">{searchResults[index].name}</td>
                    <td className="nowrap">
                      {searchResults[index].location +
                        " " +
                        searchResults[index].city}
                    </td>
                    <td>{searchResults[index].creatorAddress}</td>
                    <td>{searchResults[index].royaltyPercent}</td>
                    <td>{searchResults[index].walletAddress}</td>
                    {/* <td>
                      {searchResults[index].mintFee2 &&
                        ethers.utils.formatEther(searchResults[index].mintFee2)}
                    </td>
                    <td>
                      {searchResults[index].mintFee4 &&
                        ethers.utils.formatEther(searchResults[index].mintFee4)}
                    </td>
                    <td>
                      {searchResults[index].mintFee6 &&
                        ethers.utils.formatEther(searchResults[index].mintFee6)}
                    </td> */}
                    <td>{searchResults[index].mobile}</td>
                    <td className="text-center">
                      <Button
                        size="sm"
                        disabled={searchResults[index].isRegisteredOnChain}
                        onClick={() => {
                          registerRestaurantOnchain(searchResults[index]);
                        }}
                        className="paidrez-btn"
                      >
                        {res.isRegisteredOnChain ? "Registered" : "Register"}
                      </Button>
                    </td>
                    <td>
                      <InputGroup className="quick-options">
                        <i className="edit-icon">
                          <svg
                            onClick={() => {
                              setSelectedRestaurant(res);
                            }}
                            height="16"
                            width="1em"
                            viewBox="0 0 16 16"
                          >
                            <g
                              xmlns="http://www.w3.org/2000/svg"
                              transform="scale(0.1)"
                            >
                              <path
                                className="st0"
                                d="M94.62,2c-1.46-1.36-3.14-2.09-5.02-1.99c-1.88,0-3.56,0.73-4.92,2.2L73.59,13.72l31.07,30.03l11.19-11.72 c1.36-1.36,1.88-3.14,1.88-5.02s-0.73-3.66-2.09-4.92L94.62,2L94.62,2L94.62,2z M41.44,109.58c-4.08,1.36-8.26,2.62-12.35,3.98 c-4.08,1.36-8.16,2.72-12.35,4.08c-9.73,3.14-15.07,4.92-16.22,5.23c-1.15,0.31-0.42-4.18,1.99-13.6l7.74-29.61l0.64-0.66 l30.56,30.56L41.44,109.58L41.44,109.58L41.44,109.58z M22.2,67.25l42.99-44.82l31.07,29.92L52.75,97.8L22.2,67.25L22.2,67.25z"
                              />
                            </g>
                          </svg>
                        </i>

                        {!res.isRegisteredOnChain && (
                          <i className="delete-icon">
                            <svg
                              onClick={() =>
                                handleDelete(
                                  searchResults[index]._id,
                                  searchResults[index].name
                                )
                              }
                              height="16"
                              width="1em"
                              viewBox="0 0 16 16"
                            >
                              <g
                                xmlns="http://www.w3.org/2000/svg"
                                transform="scale(0.1)"
                              >
                                <path
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="cls-1"
                                  d="M11.17,37.16H94.65a8.4,8.4,0,0,1,2,.16,5.93,5.93,0,0,1,2.88,1.56,5.43,5.43,0,0,1,1.64,3.34,7.65,7.65,0,0,1-.06,1.44L94,117.31v0l0,.13,0,.28v0a7.06,7.06,0,0,1-.2.9v0l0,.06v0a5.89,5.89,0,0,1-5.47,4.07H17.32a6.17,6.17,0,0,1-1.25-.19,6.17,6.17,0,0,1-1.16-.48h0a6.18,6.18,0,0,1-3.08-4.88l-7-73.49a7.69,7.69,0,0,1-.06-1.66,5.37,5.37,0,0,1,1.63-3.29,6,6,0,0,1,3-1.58,8.94,8.94,0,0,1,1.79-.13ZM5.65,8.8H37.12V6h0a2.44,2.44,0,0,1,0-.27,6,6,0,0,1,1.76-4h0A6,6,0,0,1,43.09,0H62.46l.3,0a6,6,0,0,1,5.7,6V6h0V8.8h32l.39,0a4.7,4.7,0,0,1,4.31,4.43c0,.18,0,.32,0,.5v9.86a2.59,2.59,0,0,1-2.59,2.59H2.59A2.59,2.59,0,0,1,0,23.62V13.53H0a1.56,1.56,0,0,1,0-.31v0A4.72,4.72,0,0,1,3.88,8.88,10.4,10.4,0,0,1,5.65,8.8Zm42.1,52.7a4.77,4.77,0,0,1,9.49,0v37a4.77,4.77,0,0,1-9.49,0v-37Zm23.73-.2a4.58,4.58,0,0,1,5-4.06,4.47,4.47,0,0,1,4.51,4.46l-2,37a4.57,4.57,0,0,1-5,4.06,4.47,4.47,0,0,1-4.51-4.46l2-37ZM25,61.7a4.46,4.46,0,0,1,4.5-4.46,4.58,4.58,0,0,1,5,4.06l2,37a4.47,4.47,0,0,1-4.51,4.46,4.57,4.57,0,0,1-5-4.06l-2-37Z"
                                />
                              </g>
                            </svg>
                          </i>
                        )}
                        {/* <Form.Check className="checkbox" /> */}
                      </InputGroup>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>

          {totalPages !== 0 && (
            <div className="d-flex justify-content-between flex-wrap mt-1 mx-1">
              <div className="pagination-container ">
                <Pagination className="justify-content-center mb-0" size="sm">
                  <Pagination.Prev
                    disabled={restaurantsPageNo === 1}
                    onClick={() =>
                      setRestaurantsPageNo((restaurantsPageNo) =>
                        restaurantsPageNo === 1
                          ? restaurantsPageNo
                          : restaurantsPageNo - 1
                      )
                    }
                  />
                  {Array.from(Array(totalPages).keys()).map((_, i) => (
                    <Pagination.Item
                      active={restaurantsPageNo === i + 1}
                      onClick={() => setRestaurantsPageNo(i + 1)}
                      key={i}
                    >
                      {i + 1}
                    </Pagination.Item>
                  ))}

                  <Pagination.Next
                    disabled={restaurantsPageNo === totalPages}
                    onClick={() =>
                      setRestaurantsPageNo(
                        (restaurantsPageNo) => restaurantsPageNo + 1
                      )
                    }
                  />
                </Pagination>
              </div>
              <div className="page-size-selector">
                <div className="d-flex">
                  <small className="mb-0 me-1 align-self-center">
                    Results per page
                  </small>
                  <FormSelect
                    onChange={(e) => {
                      setPageSize(e.target.value);
                    }}
                    defaultValue={pageSize}
                    size="sm"
                    className="w-max"
                  >
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={30}>30</option>
                  </FormSelect>
                </div>
              </div>
            </div>
          )}
        </>
      )}

      <AnimatePresence initial={false} exit={true}>
        {modalOpen && (
          <ModalBox
            modalOpen={modalOpen}
            handleClose={() => {
              setModalOpen(false);
              setSelectedRestaurant(null);
            }}
            children={
              !selectedRestaurant ? (
                <AddNewForm
                  handleRestaurantAddedSuccessfully={
                    handleRestaurantAddedSuccessfully
                  }
                  closeModal={() => {
                    setModalOpen(false);
                    setSelectedRestaurant(null);
                    // handleRestaurantAddedSuccessfully();
                  }}
                />
              ) : (
                <EditRestaurant
                  restaurant={selectedRestaurant}
                  closeModal={() => {
                    setModalOpen(false);
                    setSelectedRestaurant(null);
                  }}
                />
              )
            }
            style_class="admin-modal"
          />
        )}
      </AnimatePresence>
    </Container>
  );
};

export default AllRestaurants;
