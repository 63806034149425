import React from "react";
import { Button, CloseButton } from "react-bootstrap";
import QrCodeContainer from "./QrCodeContainer";
import moment from "moment";
import CloseIcon from "../Icons/CloseIcon";

const ConfirmNFTUse = ({
  nft,
  handleNFTUse,
  closeModal,
  showQrCode,
  qrCodeData,
}) => {
  return (
    <div className="confirm-use-now-container">
      <CloseIcon closeModal={closeModal} />

      {!showQrCode && (
        <>
          <h4 className="mb-2 text-center">
            Are you sure to use this Reservation?
          </h4>

          <small className="d-block text-center fw-bold text-muted">
            (You won't be able to undo this)
          </small>

          <div className="buttons-container mt-4">
            <Button onClick={() => handleNFTUse(nft)} className="paidrez-btn">
              Confirm
            </Button>
            <Button className="float-end cancel" onClick={() => closeModal()}>
              Cancel
            </Button>
          </div>
        </>
      )}

      {showQrCode && <QrCodeContainer qrCodeData={qrCodeData} />}

      {showQrCode && (
        <div className="timestamp text-center">
          <small>
            Used{" "}
            {nft.utilizedAt
              ? "at " +
                moment(nft.utilizedAt).format("LT") +
                " on " +
                moment(nft.utilizedAt).format("MMM Do YYYY")
              : "now"}{" "}
          </small>
        </div>
      )}
    </div>
  );
};

export default ConfirmNFTUse;
