import React, { forwardRef, useEffect, useRef, useState } from "react";
import {
  Accordion,
  Button,
  Col,
  Collapse,
  Container,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  FormSelect,
  Image,
  Row,
} from "react-bootstrap";
import "../../scss/marketplace.scss";
import NFT from "../NFT";
import RangeSlider from "react-bootstrap-range-slider";
import moment from "moment";
import MarketplaceService from "../../Services/MarketplaceService";
import Moment from "react-moment";
import ReactDatePicker from "react-datepicker";
import { subDays } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import Skeleton from "react-loading-skeleton";
import { getCurrentMaticUSDPrice } from "../../Services/MaticPriceService";
import { AnimatePresence } from "framer-motion";
import ModalBox from "../Modal/ModalBox";
import ConfirmBuyPopup from "./ConfirmBuyPopup";
import BuySuccessPopup from "./BuySuccessPopup";
import LoadingAnimationReservation from "../Helpers/LoadingAnimationReservation";
import { useOutletContext } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import ConfirmMintPopup from "../ConfirmMintPopup";

const MarketPlace = ({ startDate, setStartDate }) => {
  const [isMobile, setMobile] = useState(window.innerWidth < "577");
  const [loading, setLoading] = useState(true);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [slots, setSlots] = useState([]);
  const [priceSelector, setPriceSelector] = useState(0);
  const [search, setSearch] = useState("");
  const [guests, setGuests] = useState(0);
  const [timeCategory, setTimeCategory] = useState(0);
  // const [date, setDate] = useState(new Date());
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [maticPriceInUSD, setMaticPriceInUSD] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalValue, setModalValue] = useState(0);
  const [selectedNFT, setSelectedNFT] = useState(null);
  const [buying, setBuying] = useState(false);
  // const [noDate, setNoDate] = useState(true);
  const [reset, setReset] = useState(false);
  const [sortBy, setSortBy] = useState(0);
  const [firstRender, setFirstRender] = useState(true);
  // const [mpFilters, setMpFilters] = useState(false);

  const { loginButtonRef, mpFilters, setMpFilters, noDate, setNoDate } =
    useOutletContext();
  const filtersRef = useRef(null);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getListings();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  useEffect(() => {
    console.log(startDate);
    if (isMobile && !noDate) getListings();
  }, [startDate]);

  useEffect(() => {
    getListings();
  }, [pageNo, pageSize, sortBy, reset]);

  useEffect(() => {
    if (modalValue !== 0) {
      setModalOpen(true);
    }
  }, [modalValue]);

  useEffect(() => {
    window.addEventListener("resize", updateScreenSize);
    return () => window.removeEventListener("resize", updateScreenSize);
  });

  const updateScreenSize = () => {
    setMobile(window.outerWidth < "577");
  };

  const getMaticPriceInUSD = async () => {
    const maticPrice = await getCurrentMaticUSDPrice(1);
    setMaticPriceInUSD(maticPrice);
  };

  const getListings = async () => {
    setLoading(true);

    getMaticPriceInUSD();

    let hour = timeCategory !== 0 ? (timeCategory + 1) * 4 : 0;
    let startTime = moment(startDate);
    let endTime;

    // console.log("current hour:", moment(new Date()).hour());
    // console.log("hour + 4", hour + 4);

    // if (moment(new Date()).hour() >= hour + 4) {
    //   hour = moment(new Date()).hour();
    // }

    const diff = moment(startDate).startOf("day").diff(moment().startOf("day"));
    console.log("diff", diff);

    if (noDate) {
      startTime = moment();
    } else {
      if (
        diff === 0 &&
        (timeCategory === 0 || moment(new Date()).hour() >= hour)
      ) {
        console.log("TODAY");
        console.log("current start time");
        startTime = moment();
        if (moment(new Date()).hour() >= hour + 4) {
          endTime = moment(startDate).endOf("day");
        } else {
          endTime = moment(startDate)
            .startOf("day")
            .add(hour + 4, "hours");
        }
      } else {
        startTime = startTime.startOf("day").add(hour, "hours");
        if (timeCategory === 0) {
          endTime = moment(startDate).endOf("day");
        } else {
          endTime = moment(startDate)
            .startOf("day")
            .add(hour + 4, "hours");
        }
        console.log("selected start time:", startTime);
      }
    }

    // startTime = startTime.startOf("day").add(hour, "hours");
    console.log(startTime.toDate());
    console.log(endTime?.toDate());
    startTime = startTime.unix();
    endTime = endTime?.unix();

    // if (!noDate) {
    //   if (diff === 0)
    //     endTime =
    //       timeCategory === 0 || moment(new Date()).hour() >= hour + 4
    //         ? moment(startDate).endOf("day").unix()
    //         : moment(startDate)
    //             .startOf("day")
    //             .add(hour + 4, "hours");
    //   console.log("endtime:", endTime, new Date(endTime * 1000));
    // }

    try {
      const res = await MarketplaceService.getListings({
        maxPrice: priceSelector > 0 ? priceSelector : null,
        seatCapacity: guests === 0 ? null : guests,
        startTime,
        endTime,
        search,
        sortBy: { 0: "slottime", 1: "price" }[sortBy],
        sortOrder: "ASC",
      });

      setSlots(res.data.data);
      console.log(res.data.data);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const DateButton = forwardRef(({ value, onClick }, ref) => (
    <Button
      className="datepicker-button w-100"
      onClick={() => {
        onClick();
        setIsCalendarOpen(!isCalendarOpen);
      }}
      ref={ref}
    >
      {noDate ? (
        "ANY"
      ) : new Date(value).toDateString() === new Date().toDateString() ? (
        "Today"
      ) : (
        <Moment format={"ddd, MMM Do"}>{value}</Moment>
      )}
    </Button>
  ));

  const handleCalendarOpen = () => {
    document.addEventListener(
      "touchstart",
      (event) => {
        event.stopPropagation();
      },
      true
    );
  };

  const handleFiltersApply = (e) => {
    e.preventDefault();
    getListings();
    setMpFilters(false);
  };

  const handleReset = () => {
    setGuests(0);
    setNoDate(true);
    // setStartDate(new Date());
    setTimeCategory(0);
    setSearch("");
    setPriceSelector(0);
    setReset(!reset);
  };

  const closeModal = () => {
    setModalValue(0);
    setModalOpen(false);
    setSelectedNFT(null);
  };

  const handleBuyClick = (nft) => {
    setSelectedNFT(nft);
    setModalValue(1);
  };

  const handleBuySuccess = (nft) => {
    setSelectedNFT(nft);
    setModalValue(2);
    getListings();
  };

  const handleModalError = () => {
    setModalValue(10);
    // setTimeout(() => closeModal(), 3000);
  };

  return (
    <div className="page-container marketplace ">
      <Helmet>
        <title>Marketplace - Trade Your Reservations | PaidRez</title>
        <meta
          name="description"
          content="Buy or Sell Reservations to High Demand Restaurants and Events on PaidRez."
        />
      </Helmet>
      <Container>
        <Row className="marketplace-header">
          <Col xs={12} md={6} lg={4}>
            <h4 className="title">Reservation Marketplace</h4>
          </Col>
          <Col className="search-col">
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                getListings();
              }}
            >
              <FormGroup>
                <FormControl
                  type="search"
                  placeholder="Search by Restaurant"
                  className="listing-search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </FormGroup>
            </Form>
          </Col>
          <Col className="sortby-col">
            <FormLabel>Sort By</FormLabel>
            <FormSelect
              value={sortBy}
              onChange={(e) => {
                setSortBy(Number(e.target?.value));
              }}
            >
              <option value={0}>Time</option>
              <option value={1}>Price</option>
            </FormSelect>
          </Col>
        </Row>
        <hr />
        <Row>
          {!isMobile && (
            <Col sm={4} md={3} xl={2}>
              <Form onSubmit={handleFiltersApply} className="filters">
                <h4>Filters</h4>
                <hr className="paidrez-hr" />

                <FormGroup className="mb-2">
                  <FormLabel>Available Date</FormLabel>

                  <div className="date-container">
                    <ReactDatePicker
                      selected={startDate}
                      onChange={(date) => {
                        setNoDate(false);
                        console.log("date selected: ", date.toISOString());
                        setStartDate(date);
                        setIsCalendarOpen(false);
                      }}
                      open={isCalendarOpen}
                      onClickOutside={() => setIsCalendarOpen(false)}
                      onCalendarOpen={handleCalendarOpen}
                      customInput={<DateButton />}
                      minDate={subDays(new Date(), 0)}
                    />
                    {!noDate && (
                      // <Image
                      //   src="/remove.png"
                      //   width={16}
                      //   className="cursor-pointer"
                      //   onClick={() => {
                      //     setStartDate(new Date());
                      //     setNoDate(true);
                      //   }}
                      // />

                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        width="20"
                        height="20"
                        viewBox="0 0 50 50"
                        className="cross-button"
                        onClick={() => {
                          setStartDate(new Date());
                          setNoDate(true);
                        }}
                      >
                        <path d="M 25 2 C 12.309534 2 2 12.309534 2 25 C 2 37.690466 12.309534 48 25 48 C 37.690466 48 48 37.690466 48 25 C 48 12.309534 37.690466 2 25 2 z M 25 4 C 36.609534 4 46 13.390466 46 25 C 46 36.609534 36.609534 46 25 46 C 13.390466 46 4 36.609534 4 25 C 4 13.390466 13.390466 4 25 4 z M 32.990234 15.986328 A 1.0001 1.0001 0 0 0 32.292969 16.292969 L 25 23.585938 L 17.707031 16.292969 A 1.0001 1.0001 0 0 0 16.990234 15.990234 A 1.0001 1.0001 0 0 0 16.292969 17.707031 L 23.585938 25 L 16.292969 32.292969 A 1.0001 1.0001 0 1 0 17.707031 33.707031 L 25 26.414062 L 32.292969 33.707031 A 1.0001 1.0001 0 1 0 33.707031 32.292969 L 26.414062 25 L 33.707031 17.707031 A 1.0001 1.0001 0 0 0 32.990234 15.986328 z"></path>
                      </svg>
                    )}
                  </div>
                </FormGroup>

                <FormGroup className="mb-2">
                  <FormLabel>Time</FormLabel>
                  <FormSelect
                    value={timeCategory}
                    onChange={(e) => {
                      setTimeCategory(Number(e.target?.value));
                      setNoDate(false);
                    }}
                  >
                    <option value={0}>
                      <small className="text-muted">Any</small>
                    </option>
                    <option value={1}>Morning (8-12)</option>
                    <option value={2}>Afternoon (12-4)</option>
                    <option value={3}>Evening (4-8)</option>
                    <option value={4}>Night (8 onwards)</option>
                  </FormSelect>
                </FormGroup>

                <FormGroup className="mb-2">
                  <FormLabel>Guests</FormLabel>
                  <FormSelect
                    value={guests}
                    onChange={(e) => setGuests(Number(e.target.value))}
                  >
                    <option value={0}>Any</option>
                    <option value={2}>2</option>
                    <option value={4}>4</option>
                    <option value={6}>6</option>
                  </FormSelect>
                </FormGroup>

                <FormGroup>
                  <FormLabel>Price (in POL)</FormLabel>
                  <RangeSlider
                    min={5}
                    max={100}
                    step={5}
                    value={priceSelector}
                    onChange={(e) => setPriceSelector(e.target.value)}
                  />

                  <FormControl
                    value={priceSelector !== 0 ? priceSelector : "ANY"}
                    onChange={(e) => setPriceSelector(e.target.value)}
                  />
                </FormGroup>
                <hr />

                <Row>
                  <Col className="pe-1">
                    <Button className="paidrez-btn w-100" type="submit">
                      Apply
                    </Button>
                  </Col>

                  <Col className="ps-1">
                    <Button
                      className="paidrez-btn-secondary w-100"
                      onClick={handleReset}
                    >
                      Reset
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          )}
          <Col xs={12} sm={8} md={9} xl={10} className="market-nfts-container">
            <Container>
              <Row className="market-nfts-row">
                {loading &&
                  [0, 1, 2, 3, 4, 5, 6, 7].map((_, i) => (
                    <Col
                      key={i}
                      className={
                        isMobile
                          ? "market-nft-container mb-2"
                          : "market-nft-container mb-2 mx-2"
                      }
                      xs={6}
                      sm={4}
                      lg={3}
                    >
                      <Skeleton containerClassName="skeleton-nft-image" />

                      {isMobile ? (
                        <>
                          <Skeleton height={30} className="mb-2" />
                          <Skeleton count={4} height={20} />
                        </>
                      ) : (
                        <>
                          <Skeleton count={4} />
                        </>
                      )}
                    </Col>
                  ))}
                {!loading &&
                  slots.length > 0 &&
                  slots.map((slot, i) => (
                    <Col
                      key={i}
                      className="market-nft-container"
                      xs={6}
                      sm={4}
                      lg={3}
                    >
                      <NFT
                        nft={slot}
                        marketPlace={true}
                        alert={alert}
                        maticPriceInUSD={maticPriceInUSD}
                        handleBuyClick={handleBuyClick}
                        loginButtonRef={loginButtonRef}
                        showRestaurantName={true}
                      />
                    </Col>
                  ))}
                {!loading && slots.length === 0 && (
                  <div>
                    <h5>
                      No Reservations Found
                      {!noDate && (
                        <>
                          {" "}
                          for {new Date(startDate).toDateString()}
                          <Button
                            onClick={handleReset}
                            className="quick-reset-button paidrez-btn-secondary ms-2"
                            size="sm"
                          >
                            Reset filters
                          </Button>
                        </>
                      )}
                    </h5>
                  </div>
                )}
              </Row>
            </Container>
          </Col>
        </Row>

        {isMobile && (
          <Accordion
            className="mobile-filters-container"
            defaultActiveKey={0}
            activeKey={mpFilters ? "1" : "0"}
            onSelect={(e) => {
              // console.log(e);
              setMpFilters((mpFilters) => !mpFilters);
              // console.log("mpFilterts set to", mpFilters);
              // setMpFilters(!mpFilters);
            }}
          >
            <Accordion.Item eventKey="1" ref={filtersRef}>
              <Accordion.Header className="filters-button">
                Filters
              </Accordion.Header>
              <Accordion.Body className="position-relative">
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    getListings();
                    setMpFilters(false);
                  }}
                  className="mt-4 filters"
                >
                  <div
                    className="close-button-container"
                    onClick={() => setMpFilters(false)}
                  >
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 48 48"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M13.896 0.664673H34.126C42.036 0.664673 47.3327 6.21801 47.3327 14.478V33.5437C47.3327 41.7803 42.036 47.3313 34.126 47.3313H13.896C5.98602 47.3313 0.666016 41.7803 0.666016 33.5437V14.478C0.666016 6.21801 5.98602 0.664673 13.896 0.664673ZM31.0228 30.998C31.8162 30.207 31.8162 28.9237 31.0228 28.1304L26.8695 23.977L31.0228 19.8214C31.8162 19.0304 31.8162 17.7237 31.0228 16.9304C30.2295 16.1347 28.9462 16.1347 28.1295 16.9304L23.9995 21.0814L19.8462 16.9304C19.0295 16.1347 17.7462 16.1347 16.9528 16.9304C16.1595 17.7237 16.1595 19.0304 16.9528 19.8214L21.1062 23.977L16.9528 28.107C16.1595 28.9237 16.1595 30.207 16.9528 30.998C17.3495 31.3947 17.8862 31.607 18.3995 31.607C18.9362 31.607 19.4495 31.3947 19.8462 30.998L23.9995 26.8704L28.1528 30.998C28.5495 31.4204 29.0628 31.607 29.5762 31.607C30.1128 31.607 30.6262 31.3947 31.0228 30.998Z"
                        fill="#D9D9D9"
                      />
                    </svg>
                  </div>

                  <h4>Filters</h4>
                  <hr className="paidrez-hr" />

                  <FormGroup className="mb-2">
                    <FormLabel>Available Date</FormLabel>
                    <div className="date-container">
                      <ReactDatePicker
                        selected={startDate}
                        onChange={(date) => {
                          setNoDate(false);
                          console.log("date selected: ", date.toISOString());
                          setStartDate(date);
                          setIsCalendarOpen(false);
                        }}
                        open={isCalendarOpen}
                        onClickOutside={() => setIsCalendarOpen(false)}
                        onCalendarOpen={handleCalendarOpen}
                        customInput={<DateButton />}
                        minDate={subDays(new Date(), 0)}
                      />
                      {!noDate && (
                        // <Image
                        //   src="/remove.png"
                        //   width={16}
                        //   className="cursor-pointer"
                        //   onClick={() => {
                        //     setStartDate(new Date());
                        //     setNoDate(true);
                        //   }}
                        // />

                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          width="16"
                          height="16"
                          viewBox="0 0 50 50"
                          className="cross-button"
                          onClick={() => {
                            setStartDate(new Date());
                            setNoDate(true);
                          }}
                        >
                          <path d="M 25 2 C 12.309534 2 2 12.309534 2 25 C 2 37.690466 12.309534 48 25 48 C 37.690466 48 48 37.690466 48 25 C 48 12.309534 37.690466 2 25 2 z M 25 4 C 36.609534 4 46 13.390466 46 25 C 46 36.609534 36.609534 46 25 46 C 13.390466 46 4 36.609534 4 25 C 4 13.390466 13.390466 4 25 4 z M 32.990234 15.986328 A 1.0001 1.0001 0 0 0 32.292969 16.292969 L 25 23.585938 L 17.707031 16.292969 A 1.0001 1.0001 0 0 0 16.990234 15.990234 A 1.0001 1.0001 0 0 0 16.292969 17.707031 L 23.585938 25 L 16.292969 32.292969 A 1.0001 1.0001 0 1 0 17.707031 33.707031 L 25 26.414062 L 32.292969 33.707031 A 1.0001 1.0001 0 1 0 33.707031 32.292969 L 26.414062 25 L 33.707031 17.707031 A 1.0001 1.0001 0 0 0 32.990234 15.986328 z"></path>
                        </svg>
                      )}
                    </div>
                  </FormGroup>

                  <FormGroup className="mb-2">
                    <FormLabel>Time</FormLabel>
                    <FormSelect
                      value={timeCategory}
                      onChange={(e) => {
                        setTimeCategory(Number(e.target?.value));
                        setNoDate(false);
                      }}
                    >
                      <option value={0}>
                        <small className="text-muted">Any</small>
                      </option>
                      <option value={1}>Morning (8-12)</option>
                      <option value={2}>Afternoon (12-4)</option>
                      <option value={3}>Evening (4-8)</option>
                      <option value={4}>Night (8 onwards)</option>
                    </FormSelect>
                  </FormGroup>

                  <FormGroup className="mb-2">
                    <FormLabel>Guests</FormLabel>
                    <FormSelect
                      value={guests}
                      onChange={(e) => setGuests(Number(e.target.value))}
                    >
                      <option value={0}>Any</option>
                      <option value={2}>2</option>
                      <option value={4}>4</option>
                      <option value={6}>6</option>
                    </FormSelect>
                  </FormGroup>

                  <FormGroup>
                    <FormLabel>Price (in POL)</FormLabel>
                    <RangeSlider
                      min={5}
                      max={100}
                      step={5}
                      value={priceSelector}
                      onChange={(e) => setPriceSelector(e.target.value)}
                    />

                    <FormControl
                      value={priceSelector !== 0 ? priceSelector : "ANY"}
                      onChange={(e) => setPriceSelector(e.target.value)}
                    />
                  </FormGroup>
                  <hr />

                  <Row>
                    <Col className="pe-1">
                      <Button className="paidrez-btn w-100" type="submit">
                        Apply
                      </Button>
                    </Col>

                    <Col className="ps-1">
                      <Button
                        className="paidrez-btn-secondary w-100"
                        onClick={handleReset}
                      >
                        Reset
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        )}
      </Container>

      <AnimatePresence initial={false} exit={true} onExitComplete={() => null}>
        {modalOpen && (
          <ModalBox
            modalOpen={modalOpen}
            handleClose={closeModal}
            // children={<NFTDetails />}
            children={
              {
                1: (
                  <ConfirmMintPopup
                    nft={selectedNFT}
                    closeModal={closeModal}
                    handleModalError={handleModalError}
                    handleBuySuccess={handleBuySuccess}
                    setBuying={setBuying}
                    marketPlace={true}
                  />
                ),
                2: (
                  <BuySuccessPopup nft={selectedNFT} closeModal={closeModal} />
                ),
                10: (
                  <div className="py-3 text-black text-center">
                    <div
                      className="close-button-container"
                      onClick={() => closeModal()}
                    >
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 48 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M13.896 0.664673H34.126C42.036 0.664673 47.3327 6.21801 47.3327 14.478V33.5437C47.3327 41.7803 42.036 47.3313 34.126 47.3313H13.896C5.98602 47.3313 0.666016 41.7803 0.666016 33.5437V14.478C0.666016 6.21801 5.98602 0.664673 13.896 0.664673ZM31.0228 30.998C31.8162 30.207 31.8162 28.9237 31.0228 28.1304L26.8695 23.977L31.0228 19.8214C31.8162 19.0304 31.8162 17.7237 31.0228 16.9304C30.2295 16.1347 28.9462 16.1347 28.1295 16.9304L23.9995 21.0814L19.8462 16.9304C19.0295 16.1347 17.7462 16.1347 16.9528 16.9304C16.1595 17.7237 16.1595 19.0304 16.9528 19.8214L21.1062 23.977L16.9528 28.107C16.1595 28.9237 16.1595 30.207 16.9528 30.998C17.3495 31.3947 17.8862 31.607 18.3995 31.607C18.9362 31.607 19.4495 31.3947 19.8462 30.998L23.9995 26.8704L28.1528 30.998C28.5495 31.4204 29.0628 31.607 29.5762 31.607C30.1128 31.607 30.6262 31.3947 31.0228 30.998Z"
                          fill="#D9D9D9"
                        />
                      </svg>
                    </div>
                    <h4 className="text-center my-2 text-black">
                      Something Went Wrong.
                    </h4>
                    <p className="mb-1 fw-bold">Please Try Again.</p>
                    <p>
                      <a
                        href="mailto:support@developingnow.com?subject=PAIDREZ%20Support%20-%20Marketplace%20Error"
                        className="paidrez-color fw-bold"
                      >
                        Contact Us
                      </a>{" "}
                      if you continue to face trouble.
                    </p>
                  </div>
                ),
              }[modalValue]
            }
            style_class={
              {
                1: "buy-confirmation-modal",
                2: "reservation-successfull-popup",
              }[modalValue]
            }
          />
        )}
      </AnimatePresence>

      {buying && (
        <div className="nft-loader-container">
          <LoadingAnimationReservation />
        </div>
      )}
    </div>
  );
};

export default MarketPlace;
