import { AnimatePresence } from "framer-motion";
import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import Moment from "react-moment";
import "../scss/nfts.scss";
import ModalBox from "./Modal/ModalBox";
import NFTDetails from "./NFTDetails";
import { ethers } from "ethers";
import { useNavigate } from "react-router";
import ConfirmMintPopup from "./ConfirmMintPopup";
import { getCurrentMaticUSDPrice } from "../Services/MaticPriceService";
import { useOutletContext } from "react-router-dom";
import moment from "moment";
import { useStoreState } from "easy-peasy";
import PeopleIcon from "./Icons/PeopleIcon";
import CalendarTimeIcon from "./Icons/CalendarTimeIcon";
import WhitelistIcon from "./Icons/WhitelistIcon";
import ClockIcon from "./Icons/ClockIcon";

const NFT = ({
  nft,
  mintReservationNFT,
  contractInstance,
  showRestaurantName = false,
  fromHomePage,
  alert,
  marketPlace,
  loginButtonRef,
  maticPriceInUSD,
  handleBuyClick,
  notEligible,
  getReservationSlotNFTs,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const { setStartDate } = useOutletContext();
  const isLoggedIn = useStoreState((state) => state.isLoggedIn);
  const account = useStoreState((state) => state.account);

  const closeModal = () => {
    setConfirmationModal(false);
    setModalOpen(false);
  };

  const navigate = useNavigate();

  return (
    <>
      <div className="nft">
        <div className="nft-container h-100">
          <Card
            className="nft-container1"
            onClick={() => {
              console.log("card clicked");
              if (fromHomePage) {
                setStartDate(new Date(moment(nft.slotTime * 1000)));
                navigate(`/restaurant/${nft.restaurantId}`);
              } else if (marketPlace) {
                // navigate(`/marketplace/nft/${nft}`)
                navigate(`/marketplace/nft/${nft.tokenId}`);
              } else {
                // setModalOpen(true);
              }
            }}
          >
            <Card.Img
              variant="top"
              src={nft.s3ImageURI}
              alt="nft-image"
              className="nft-image"
              onClick={() => {
                if (!fromHomePage && !marketPlace) {
                  if (nft.isMinted) {
                    if (account === nft.walletAddress)
                      navigate("/account/reservations-and-notify");
                  } else {
                    if (!isLoggedIn) {
                      loginButtonRef.current?.click();
                      return;
                    }
                    if (!notEligible) {
                      setConfirmationModal(true);
                      setModalOpen(true);
                    }
                  }
                }
              }}
            />
            {nft.isWhitelistEnabled && (
              <div className="whitelist-only-indicator" title="Invite Only">
                <WhitelistIcon />
              </div>
            )}
            <div className="time-container">
              <ClockIcon />

              <i className="divider"></i>
              <span className="time">
                {<Moment format="LT">{new Date(nft.slotTime * 1000)}</Moment>}
              </span>
            </div>
            <Card.Body className="p-0">
              <Card.Title>
                <div className="nft-name">
                  {nft.tokenName ||
                    `PAIDREZ ${
                      marketPlace ? nft.restoName : nft.restaurantName
                    } #${nft.tokenId?.toString().padStart(5, "0")}`}
                </div>
                {showRestaurantName && (
                  <div className="restaurant-name">
                    at{" "}
                    <span>
                      {marketPlace ? nft.restoName : nft.restaurantName}
                    </span>
                  </div>
                )}
                <div className="token-id">Token ID: {nft.tokenId}</div>
              </Card.Title>
              <Card.Text as="div">
                <Row className="flex-nowrap seat-date-container">
                  <Col xs={6} className="nft-info-container seats">
                    <div className="nft-info ">
                      <PeopleIcon />
                      <i className="divider"></i>
                      <div className="d-inline">{nft.seatCapacity} Seats</div>
                    </div>
                  </Col>{" "}
                  <Col xs={6} className="nft-info-container date">
                    <div className="nft-info ">
                      <CalendarTimeIcon />

                      <i className="divider"></i>
                      <Moment format="MMM Do YYYY">
                        {new Date(nft.slotTime * 1000)}
                      </Moment>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} className="nft-info-container mint-fee">
                    <div className="nft-info ">
                      <span className="description mint-fee-text">
                        {" "}
                        Mint fee:{" "}
                      </span>
                      <div className="price-container">
                        <span className="value">{nft.priceInMatic} POL</span>
                        <span className="description">
                          {" "}
                          ≈ ${(nft.priceInMatic * maticPriceInUSD).toFixed(2)}
                        </span>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Card.Text>
            </Card.Body>
          </Card>

          {!fromHomePage && !marketPlace && (
            <Row className=" mint-button-container">
              {nft.isMinted ? (
                <Button
                  className="paidrez-btn mint-button"
                  disabled={
                    account?.toLowerCase() !== nft.walletAddress?.toLowerCase()
                  }
                  onClick={() => navigate("/account/reservations-and-notify")}
                >
                  {account?.toLowerCase() === nft.walletAddress?.toLowerCase()
                    ? "Owned"
                    : "Unavailable"}
                </Button>
              ) : (
                <Button
                  className="paidrez-btn mint-button"
                  onClick={(e) => {
                    if (!isLoggedIn) {
                      loginButtonRef.current?.click();
                      return;
                    }
                    if (marketPlace) {
                      handleBuyClick(nft);
                    } else {
                      setConfirmationModal(true);
                      setModalOpen(true);
                    }
                  }}
                  disabled={notEligible && account}
                >
                  {notEligible ? "Invite Only" : "Buy Rez"}
                </Button>
              )}
            </Row>
          )}

          {marketPlace && (
            <Row className="mint-button-container">
              {nft.seller?.toLowerCase() === account?.toLowerCase() ? (
                <Button
                  className="paidrez-btn mint-button"
                  // disabled={account !== nft.seller}
                  onClick={() => navigate(`/marketplace/nft/${nft.tokenId}`)}
                >
                  Owned
                </Button>
              ) : (
                <Button
                  className="paidrez-btn mint-button"
                  onClick={(e) => {
                    if (!isLoggedIn) {
                      loginButtonRef.current?.click();
                      return;
                    }
                    handleBuyClick(nft);
                  }}
                  disabled={nft.isUtilized}
                >
                  Buy Rez
                </Button>
              )}
            </Row>
          )}
        </div>
      </div>
      <AnimatePresence initial={false} exit={true} onExitComplete={() => null}>
        {modalOpen && (
          <ModalBox
            modalOpen={modalOpen}
            handleClose={closeModal}
            children={
              confirmationModal ? (
                <ConfirmMintPopup
                  contractInstance={contractInstance}
                  nft={nft}
                  mintReservationNFT={mintReservationNFT}
                  closeModal={closeModal}
                  alert={alert}
                  marketPlace={marketPlace}
                  getReservationSlotNFTs={getReservationSlotNFTs}
                />
              ) : (
                <NFTDetails nft={nft} closeModal={closeModal} />
              )
            }
            style_class={
              confirmationModal ? "buy-confirmation-modal" : "nft-details-modal"
            }
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default NFT;
