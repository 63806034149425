import axios from "axios";

// const BASE_URL = "http://localhost:3001";
// const BASE_URL = "https://api.paidrez.com";
const BASE_URL = process.env.REACT_APP_API_BASE_URL;

class AuthenticateAdminService {
  authenticateAdmin = (username, password) => {
    return axios.get(BASE_URL + "/authenticateAdmin", {
      auth: { username, password },
    });
  };
}

export default new AuthenticateAdminService();
