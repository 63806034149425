import React, { useEffect } from "react";
import { useState } from "react";
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  InputGroup,
  Row,
  Table,
} from "react-bootstrap";
import TopRestaurantsService from "../../Services/TopRestaurantsService";
import { AnimatePresence } from "framer-motion";
import ModalBox from "../Modal/ModalBox";
import RestaurantsService from "../../Services/RestaurantsService";

const AddNewRestaurantToList = ({
  newRestaurantToAdd,
  addRestaurantToList,
  setModalOpen,
}) => {
  const [restaurants, listNumber, listTitle] = [
    newRestaurantToAdd.restaurantsAvailable,
    newRestaurantToAdd.listNumber,
    newRestaurantToAdd.listTitle,
  ];
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    // if (!loading) {
    const results = restaurants.filter((restaurant) =>
      restaurant.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    setSearchResults(results);
    console.log("results:", results);
    // }
  }, [searchTerm]);

  return (
    <div className="add-restaurant-to-list-container">
      <h4 className="text-center">
        Add restaurant to list #{listNumber} : {listTitle}
      </h4>
      <Form.Control
        placeholder="search for restaurants"
        value={searchTerm}
        onChange={(e) => {
          console.log("search term: ", e.target.value);
          setSearchTerm(e.target.value);
        }}
      />{" "}
      <br />
      <div className="table-container">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Add to list</th>
            </tr>
          </thead>
          <tbody className="search-results-container">
            {searchResults.map((_, index) => (
              <tr key={index} className="search-results-row">
                <td>{searchResults[index].restaurant_id}</td>
                <td>{searchResults[index].name}</td>
                <td>
                  <Button
                    variant="outline-primary"
                    size="sm"
                    onClick={() => {
                      addRestaurantToList(
                        listNumber,
                        searchResults[index].restaurant_id,
                        searchResults[index].name
                      );
                      setModalOpen(false);
                    }}
                  >
                    Add
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

const TopRestaurants = () => {
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [restaurants, setRestaurants] = useState([]);
  const [topRestaurants, setTopRestaurants] = useState([]);
  const [listTitles, setListTitles] = useState([]);
  const [newRestaurantToAdd, setNewRestaurantToAdd] = useState({});
  const [alert, setAlert] = useState(false);
  const [cityId, setCityId] = useState(0);

  const errorHandler = () => {
    setAlert(true);
    setTimeout(() => setAlert(false), 2000);
  };

  const segregateTopRestaurants = (topRestaurants) => {
    let restaurantList_1 = [];
    let restaurantList_2 = [];
    let restaurantList_3 = [];

    topRestaurants.forEach((res) => {
      if (res.list === 1) {
        restaurantList_1.push(res);
      } else if (res.list === 2) {
        restaurantList_2.push(res);
      } else {
        restaurantList_3.push(res);
      }
    });

    let list = [restaurantList_1, restaurantList_2, restaurantList_3];

    setTopRestaurants([...list]);

    setLoading(false);
  };

  async function fetchTopRestaurants() {
    await TopRestaurantsService.getTopRestaurantsByCityId(cityId)
      .then((response) => {
        console.log(response.data);
        setListTitles([
          response.data.list_titles[0].name,
          response.data.list_titles[1].name,
          response.data.list_titles[2].name,
        ]);
        // setTopRestaurants([
        //   response.data.top_restaurants_0,
        //   response.data.top_restaurants_1,
        //   response.data.top_restaurants_2,
        // ]);

        // setLoading(false);

        segregateTopRestaurants(response.data.top_restaurants);
      })
      .catch((err) => {
        console.log(err);
        errorHandler();
      });
  }

  async function fetchRestaurants() {
    await RestaurantsService.getRestaurantsByCityId(cityId)
      .then((res) => {
        console.log(res);
        setRestaurants(res.data.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  useEffect(() => {
    if (cityId == 0) {
      console.log("city id 0");
      return;
    }

    fetchTopRestaurants();
    fetchRestaurants();
  }, [cityId]);

  const handleListTitleChange = (e, index) => {
    let tempArray = [...listTitles];
    tempArray[index] = e.target.value;
    setListTitles([...tempArray]);
  };

  const saveTitle = (index) => {
    const titleDetails = { list_id: index, list_title: listTitles[index] };
    TopRestaurantsService.updateListTitles(titleDetails)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err.message);
        errorHandler();
      });
  };

  const handleDelete = (index, i, restaurant) => {
    console.log(index, i);
    if (
      window.confirm(
        "Are you sure you want to delete restaurant " +
          restaurant.restaurant_id +
          " : " +
          restaurant.name +
          " from list #" +
          (index + 1) +
          "?"
      )
    ) {
      TopRestaurantsService.deleteTopRestaurant(
        restaurant.restaurant_id,
        index + 1
      )
        .then((res) => {
          console.log(res.data.message);
          let tempArray = topRestaurants;
          tempArray[index].splice(i, 1);
          setTopRestaurants([...tempArray]);
        })
        .catch((err) => {
          console.log("error:", err);
          errorHandler();
        });
    }
  };

  // To open restaurant adding modal
  const handleAddRestaurantsToList = (index) => {
    let restaurantsAvailable = restaurants.filter(
      (res) =>
        !topRestaurants[index]
          .map((r) => r.restaurant_id)
          .includes(res.restaurant_id)
    );
    console.log("restaurants available updated", restaurantsAvailable);
    setNewRestaurantToAdd({
      listNumber: index,
      listTitle: listTitles[index],
      restaurantsAvailable: restaurantsAvailable,
    });
    setModalOpen(true);
  };

  // To actually add restaurant to list, selected in by modal
  const addRestaurantToList = (index, id, name) => {
    let rank = topRestaurants[index].length + 1;
    const topRestaurant = {
      city_id: cityId,
      restaurant_id: id,
      list_id: index + 1,
      rank: rank,
    };
    TopRestaurantsService.addTopRestaurant(topRestaurant)
      .then((res) => {
        console.log(res);
        let tempArray = topRestaurants;
        tempArray[index].push({ restaurant_id: id, name: name });
        setTopRestaurants([...tempArray]);
        console.log("top res:", tempArray);
      })
      .catch((err) => {
        console.log(err);
        errorHandler();
      });

    // handleAddRestaurantsToList(index);
  };

  return (
    <div>
      <Container className="top-restaurants-admin">
        <h1 className="text-center my-4">Top Restaurants Lists</h1>

        <Form.Select
          aria-label="Default select example"
          className="city-selector"
          onChange={(e) => {
            setCityId(e.target.value);
          }}
        >
          <option value="0" defaultValue={0}>
            Select city
          </option>
          <option value="1">New York</option>
          <option value="2">Chicago</option>
          <option value="3">Miami</option>
          <option value="4">Atlanta</option>
          <option value="5">Boston</option>
          <option value="6">Austin</option>
          <option value="7">San Francisco</option>
          <option value="8">Seattle</option>
          <option value="9">Los Angeles</option>
        </Form.Select>
        <hr />
        {!loading && (
          <Row>
            {[0, 1, 2].map((_, index) => (
              <Col
                key={index}
                xs={12}
                sm={6}
                md={4}
                className="top-restaurants-admin-list-container"
              >
                <h2 className="text-center">List #{index + 1}</h2>
                <InputGroup>
                  <InputGroup.Text>Title</InputGroup.Text>
                  <Form.Control
                    value={listTitles[index]}
                    onChange={(e) => handleListTitleChange(e, index)}
                  />
                  <Button onClick={() => saveTitle(index)}>Save</Button>
                </InputGroup>
                <br />
                {Array.from(
                  { length: topRestaurants[index].length },
                  (_, i) => i
                ).map((_, i) => (
                  <div key={i}>
                    <Row>
                      <Col xs={2}>{topRestaurants[index][i].restaurant_id}</Col>
                      <Col>{topRestaurants[index][i].name}</Col>
                      <Col xs={2}>
                        <i className="delete-icon">
                          <svg
                            onClick={() =>
                              handleDelete(index, i, topRestaurants[index][i])
                            }
                            height="16"
                            width="1em"
                            viewBox="0 0 16 16"
                          >
                            <g
                              xmlns="http://www.w3.org/2000/svg"
                              transform="scale(0.1)"
                            >
                              <path
                                xmlns="http://www.w3.org/2000/svg"
                                className="cls-1"
                                d="M11.17,37.16H94.65a8.4,8.4,0,0,1,2,.16,5.93,5.93,0,0,1,2.88,1.56,5.43,5.43,0,0,1,1.64,3.34,7.65,7.65,0,0,1-.06,1.44L94,117.31v0l0,.13,0,.28v0a7.06,7.06,0,0,1-.2.9v0l0,.06v0a5.89,5.89,0,0,1-5.47,4.07H17.32a6.17,6.17,0,0,1-1.25-.19,6.17,6.17,0,0,1-1.16-.48h0a6.18,6.18,0,0,1-3.08-4.88l-7-73.49a7.69,7.69,0,0,1-.06-1.66,5.37,5.37,0,0,1,1.63-3.29,6,6,0,0,1,3-1.58,8.94,8.94,0,0,1,1.79-.13ZM5.65,8.8H37.12V6h0a2.44,2.44,0,0,1,0-.27,6,6,0,0,1,1.76-4h0A6,6,0,0,1,43.09,0H62.46l.3,0a6,6,0,0,1,5.7,6V6h0V8.8h32l.39,0a4.7,4.7,0,0,1,4.31,4.43c0,.18,0,.32,0,.5v9.86a2.59,2.59,0,0,1-2.59,2.59H2.59A2.59,2.59,0,0,1,0,23.62V13.53H0a1.56,1.56,0,0,1,0-.31v0A4.72,4.72,0,0,1,3.88,8.88,10.4,10.4,0,0,1,5.65,8.8Zm42.1,52.7a4.77,4.77,0,0,1,9.49,0v37a4.77,4.77,0,0,1-9.49,0v-37Zm23.73-.2a4.58,4.58,0,0,1,5-4.06,4.47,4.47,0,0,1,4.51,4.46l-2,37a4.57,4.57,0,0,1-5,4.06,4.47,4.47,0,0,1-4.51-4.46l2-37ZM25,61.7a4.46,4.46,0,0,1,4.5-4.46,4.58,4.58,0,0,1,5,4.06l2,37a4.47,4.47,0,0,1-4.51,4.46,4.57,4.57,0,0,1-5-4.06l-2-37Z"
                              ></path>
                            </g>
                          </svg>
                        </i>
                      </Col>
                    </Row>

                    <hr />
                  </div>
                ))}

                {topRestaurants[index].length < 5 && (
                  <button
                    onClick={
                      () => handleAddRestaurantsToList(index)
                      // console.log(index)
                    }
                  >
                    add more
                  </button>
                )}

                {/* <h5>Res name</h5>
                <h5>Res name</h5>
                <h5>Res name</h5>
                <h5>Res name</h5> */}
              </Col>
            ))}
          </Row>
        )}

        <AnimatePresence initial={false} exit={true} onExitComplete={() => {}}>
          {modalOpen && (
            <ModalBox
              modalOpen={modalOpen}
              handleClose={() => setModalOpen(false)}
              children={
                <AddNewRestaurantToList
                  newRestaurantToAdd={newRestaurantToAdd}
                  addRestaurantToList={addRestaurantToList}
                  setModalOpen={setModalOpen}
                />
              }
              style_class="admin-modal"
            />
          )}
        </AnimatePresence>
        {alert && (
          <Alert variant="danger" onClose={() => setAlert(false)} dismissible>
            Something went wrong
          </Alert>
        )}
      </Container>
    </div>
  );
};

export default TopRestaurants;
