import React, { useEffect, useState } from "react";
import {
  Dropdown,
  DropdownButton,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  FormSelect,
  Pagination,
  Table,
} from "react-bootstrap";
import UserService from "../../Services/UserService";
import Moment from "react-moment";
import { useStoreState } from "easy-peasy";
import LoadingAnimationSpoon from "../Helpers/LoadingAnimationSpoon";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [searchParam, setSearchParam] = useState("");
  const [loading, setLoading] = useState(true);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchRole, setSearchRole] = useState("");
  const [totalPages, setTotalPages] = useState(0);

  const role = useStoreState((state) => state.role);
  const account = useStoreState((state) => state.account);

  useEffect(() => {
    if (role !== "ADMIN" || !account) {
      // Account is used here because role is persisting, it will stay true even if login is not complete on reload
      // account will be true only when login is complete, and then this fetchUser() will be called
      // 'role' is already used in admin Dashboard, here its just a 2nd layer of security.

      return;
    }

    const delayDebounceFn = setTimeout(() => {
      if (pageNo !== 1) {
        setPageNo(1);
      } else {
        fetchUsers();
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchParam]);

  useEffect(() => {
    if (role !== "ADMIN" || !account) {
      return;
    }

    fetchUsers();
  }, [role, account, pageNo, searchRole, pageSize]);

  useEffect(() => {
    if (role !== "ADMIN" || !account) {
      return;
    }
    if (pageNo !== 1) setPageNo(1);
  }, [pageSize]);

  const fetchUsers = async () => {
    try {
      setLoading(true);

      let params = { pageNo, pageSize };
      if (searchParam.length) params.searchParam = searchParam;
      if (searchRole.length) params.role = searchRole;

      const res = await UserService.getUsers(params);
      console.log(res);

      setUsers(res.data?.users);
      setTotalPages(res.data?.totalPages);
      setLoading(false);
    } catch (e) {
      console.log("ERROR:", e);
      setLoading(false);
    }
  };

  return (
    <div className="users">
      <h3 className="text-center">Users</h3>

      <div className="search-container">
        <Form.Control
          type="search"
          placeholder="Search Users"
          value={searchParam}
          onChange={(e) => {
            (e.target?.value?.replace(/\s/g, "").length ||
              !e.target?.value?.length) &&
              setSearchParam(e.target.value);
          }}
        />
        <DropdownButton
          id="dropdown-basic-button"
          title={
            {
              USER: "User",
              ADMIN: "Admin",
              RESTO_ADMIN: "Restaurant Admin",
              RESTO_SUB_ADMIN: "Restaurant Sub Admin",
              "": "Role",
            }[searchRole]
          }
          onSelect={(e) => {
            setSearchRole(e);
          }}
        >
          <Dropdown.Item eventKey={""}>All</Dropdown.Item>
          <Dropdown.Item eventKey={"USER"}>User</Dropdown.Item>
          <Dropdown.Item eventKey={"ADMIN"}>Admin</Dropdown.Item>
          <Dropdown.Item eventKey={"RESTO_ADMIN"}>
            Restaurant Admin
          </Dropdown.Item>
          <Dropdown.Item eventKey={"RESTO_SUB_ADMIN"}>
            Restaurant Sub Admin
          </Dropdown.Item>
        </DropdownButton>
      </div>
      {loading ? (
        <div className="text-center">
          <LoadingAnimationSpoon width={150} />
        </div>
      ) : (
        <>
          <div className="table-container">
            <Table bordered striped>
              <thead className="text-bg-secondary">
                <tr>
                  <td>
                    <b>Joined on</b>
                  </td>
                  <td>
                    <b>Wallet Address</b>
                  </td>
                  <td>
                    <b>Name</b>
                  </td>
                  <td>
                    <b>Email</b>
                  </td>
                  <td>
                    <b>Mobile</b>
                  </td>
                  <td>
                    <b>Login Method</b>
                  </td>
                  <td>
                    <b>Role</b>
                  </td>
                </tr>
              </thead>
              <tbody>
                {users.map((user, i) => (
                  <tr key={i}>
                    <td>
                      <Moment format="MMMM Do YYYY">{user.createdAt}</Moment>
                    </td>
                    <td>{user.walletAddress}</td>
                    <td>{user.name}</td>
                    <td>{user.email}</td>
                    <td>{user.mobile}</td>
                    <td className="text-capitalize">{user.typeOfLogin}</td>
                    <td>{user.role}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          {totalPages > 0 && (
            <div className="d-flex justify-content-between flex-wrap mt-1 mx-1">
              <div className="pagination-container ">
                <Pagination className="justify-content-center mb-0" size="sm">
                  <Pagination.Prev
                    disabled={pageNo === 1}
                    onClick={() => setPageNo((pageNo) => pageNo - 1)}
                  />
                  {Array.from(Array(totalPages).keys()).map((_, i) => (
                    <Pagination.Item
                      active={pageNo === i + 1}
                      onClick={() => setPageNo(i + 1)}
                      key={i}
                    >
                      {i + 1}
                    </Pagination.Item>
                  ))}

                  <Pagination.Next
                    disabled={pageNo === totalPages}
                    onClick={() => setPageNo((pageNo) => pageNo + 1)}
                  />
                </Pagination>
              </div>
              <div className="page-size-selector">
                <div className="d-flex">
                  <small className="mb-0 me-1 align-self-center">
                    Results per page
                  </small>
                  <FormSelect
                    onChange={(e) => {
                      setPageSize(e.target.value);
                    }}
                    defaultValue={pageSize}
                    size="sm"
                    className="w-max"
                  >
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={30}>30</option>
                  </FormSelect>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Users;
