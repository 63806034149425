import { useStoreActions, useStoreState } from "easy-peasy";
import React, { forwardRef, useEffect, useState } from "react";
import { Alert, Button, Container, FloatingLabel, Form } from "react-bootstrap";
import PhoneInput from "react-phone-number-input";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import Input from "react-phone-number-input/input";
import UserService from "../../Services/UserService";
import { useOutlet, useOutletContext } from "react-router-dom";
import MobileNumberInput from "../MobileNumberInput";
import { AnimatePresence } from "framer-motion";
import ModalBox from "../Modal/ModalBox";
import LoadingAnimationEclipse from "../Helpers/LoadingAnimationEclipse";
import LoginLite from "../LoginLite/LoginLite";
import UpdateEmail from "./AccountPopups/UpdateEmail";

const Profile = () => {
  const account = useStoreState((states) => states.account);
  const [name, setName] = useState("");
  const setEmail_store = useStoreActions((actions) => actions.setEmail);
  const [disableEmail, setDisableEmail] = useState(false);
  const [disableMobile, setDisableMobile] = useState(false);
  const [mobile, setMobile] = useState("");
  const [invalidMobile, setInvalidMobile] = useState(false);
  const [updating, setUpdating] = useState(true);
  const [alertVariant, setAlertVariant] = useState("danger");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [copied, setCopied] = useState(false);
  const [email, setEmail] = useState("");
  const [modal, setModal] = useState(false);
  const [modalEmail, setModalEmail] = useState(false);

  const loginLite = useStoreState((state) => state.loginLite);

  const closeModal = () => {
    setModal(false);
  };

  const closeModalEmail = () => {
    setModalEmail(false);
  };

  const setNameStore = useStoreActions((action) => action.setName);
  const isLoggedIn = useStoreState((state) => state.isLoggedIn);
  const { loginButtonRef } = useOutletContext();

  const getUser = async () => {
    let res;
    if (loginLite) {
      res = await UserService.getUserLite();
    } else {
      res = await UserService.getUser();
    }

    setName(res.name);
    if (res.isSocialLogin && res.typeOfLogin !== "phone") {
      setDisableEmail(true);
    } else if (res.typeOfLogin === "phone" && res.mobile) {
      setDisableMobile(true);
    }
    console.log("mobile:", res.mobile);
    setEmail(res.email);
    setMobile(res.mobile);
    setUpdating(false);
  };

  useEffect(() => {
    if (account) getUser();
  }, [account]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUpdating(true);

    try {
      let updateBody = { name };
      const update_res = await UserService.updateUser(updateBody);
      console.log(update_res);
      alert("Profile Updated", "success");
      setNameStore(name);
      setUpdating(false);
    } catch (e) {
      console.log("ERROR::", e);
      alert("Something went wrong");
      setUpdating(false);
    }
  };

  const alert = (message = "Something went wrong", variant = "danger") => {
    setAlertMessage(message);
    setAlertVariant(variant);
    setShowAlert(true);

    setTimeout(() => setShowAlert(false), 3000);
  };

  const removeMobile = async () => {
    try {
      if (
        window.confirm(
          "Are you sure to remove mobile number from your account?"
        )
      ) {
        const res = await UserService.updateUser({ mobile: " " });
      }
    } catch (e) {
      console.log("ERROR::", e);
    }
  };

  return (
    <div className="profile">
      {!isLoggedIn && !loginLite ? (
        <div className="p-4">
          <h4 className="paidrez-color not-signed-in">
            You are not signed in.
          </h4>
          <Button
            className="paidrez-btn"
            onClick={() => loginButtonRef.current?.click()}
          >
            {loginLite && "Full "}
            Sign In
          </Button>
        </div>
      ) : (
        <Container className="mt-4 profile-container">
          <h3 className="mb-3">My Profile</h3>
          <Form onSubmit={(e) => handleSubmit(e)}>
            <p className="mb-1 paidrez-color">Wallet Address</p>
            <p className="wallet-address mb-1 text-bold">{account}</p>
            <div className="text-start mb-3">
              <Button
                size="sm"
                className="copy-button"
                onClick={() => {
                  try {
                    navigator.clipboard.writeText(account);
                    setCopied(true);
                    setTimeout(() => setCopied(false), 2000);
                  } catch (e) {
                    console.log("Error in copying");
                  }
                }}
              >
                Copy
              </Button>
              {copied && <span className="ms-1">✔</span>}
            </div>

            <FloatingLabel
              controlId="floatingInput"
              label="Name"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="name"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                disabled={loginLite}
              />
            </FloatingLabel>

            <FloatingLabel
              controlId="floatingInput"
              label="Email"
              className="mb-3"
            >
              <Form.Control
                type="email"
                placeholder="email"
                value={email}
                disabled
              />
              {!disableEmail && !loginLite && (
                <div className="buttons">
                  <svg
                    className="feather feather-edit "
                    fill="none"
                    height="24"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    width="24"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => {
                      if (!disableEmail) {
                        // console.log("fd");
                        setModalEmail(true);
                      }
                    }}
                  >
                    <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />
                    <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" />
                  </svg>
                </div>
              )}
            </FloatingLabel>

            <FloatingLabel
              controlId="floatingInput"
              label="Mobile Number"
              className="mb-3"
            >
              <Form.Control
                // country="US"
                // id="floatingInput"
                // className={`form-control ${invalidMobile && "invalid-input"}`}
                // international
                // withCountryCallingCode
                placeholder="Mobile"
                value={mobile}
                disabled
              />
              {!disableMobile && !loginLite && (
                <div className="buttons">
                  <svg
                    className="feather feather-edit "
                    fill="none"
                    height="24"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    width="24"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => {
                      if (!disableMobile) {
                        console.log("fd");
                        setModal(true);
                      }
                    }}
                  >
                    <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />
                    <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" />
                  </svg>

                  {/* <svg
                    class="feather feather-x"
                    fill="none"
                    height="24"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    viewBox="0 0 24 24"
                    width="24"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => removeMobile()}
                  >
                    <line x1="18" x2="6" y1="6" y2="18" />
                    <line x1="6" x2="18" y1="6" y2="18" />
                  </svg> */}
                </div>
              )}
            </FloatingLabel>

            {loginLite ? (
              <b>To edit any detail, please use Full Sign In.</b>
            ) : (
              <Button
                className="update-button mt-2"
                type="submit"
                disabled={updating}
              >
                Update Details{" "}
                {updating && (
                  <LoadingAnimationEclipse
                    width={20}
                    className="d-inline ms-1 align-text-bottom"
                  />
                )}
              </Button>
            )}
          </Form>
          {showAlert && (
            <Alert
              dismissible
              className="alert"
              variant={alertVariant}
              onClose={() => setShowAlert(false)}
            >
              {alertMessage}
            </Alert>
          )}
        </Container>
      )}

      <AnimatePresence initial={false} exit={true}>
        {modal && (
          <ModalBox
            modalOpen={modal}
            handleClose={closeModal}
            children={
              <MobileNumberInput
                closeModal={closeModal}
                verified={() => {
                  getUser();
                  closeModal();
                }}
              />
            }
            style_class="invite-modal"
          />
        )}
        {modalEmail && (
          <ModalBox
            modalOpen={modalEmail}
            handleClose={closeModalEmail}
            children={
              <UpdateEmail closeModal={closeModalEmail} getUser={getUser} />
            }
            style_class="update-email-modal"
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export default Profile;
