import "./App.scss";
import "../src/scss/react_datepicker.scss";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import Restaurant from "./Components/Restaurant";
import ResConfirmation from "./Components/ResConfirmation";
import AdminDashboard from "./Components/Admin/AdminDashboard";
import Login from "./Components/Login";
import { useState, useEffect, useRef } from "react";
import WithoutNav from "./Components/NavigationBarHelper/WithoutNav";
import WithNav from "./Components/NavigationBarHelper/WithNav";
import HomePage from "./Components/HomePage";
import RestaurantAdminLogin from "./Components/RestaurantAdmin/RestaurantAdminLogin";
import Profile from "./Components/Account/Profile";
import ReservationsAndNotify from "./Components/Account/ReservationsAndNotify";
import MyHitList from "./Components/Account/MyHitList";
import PaymentMethods from "./Components/Account/PaymentMethods";
import Communication from "./Components/Account/Communication";
import HelpAndFeedback from "./Components/Account/HelpAndFeedback";
import AccountNavbar from "./Components/Account/AccountNavbar";
import ScrollToTop from "./ScrollToTop";
import MarketPlace from "./Components/Marketplace/MarketPlace";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import NFTPage from "./Components/Marketplace/NFTPage";
import AllRestaurantsAdmin from "./Components/Admin/AllRestaurants";
import TopRestaurants from "./Components/Admin/TopRestaurants";
import moment from "moment";
import Blogs from "./Components/Admin/Blogs";
import BlogPage from "./Components/BlogPage";
import AllRestaurants from "./Components/AllRestaurants";
import Users from "./Components/Admin/Users";
import PlatformFeeInfo from "./Components/Admin/PlatformFeeInfo";
import { checkIfTokenIsExpired } from "@web3auth/base";
import { useStoreActions } from "easy-peasy";
import ManageReservations from "./Components/Admin/ManageReservations";
import { AnimatePresence } from "framer-motion";
import ModalBox from "./Components/Modal/ModalBox";
import SessionExpiredPopup from "./Components/SessionExpiredPopup";
import Partners from "./Components/Partners";
import ValueProposition from "./Components/ValueProposition";
import HowItWorks from "./Components/HowItWorks";
import PartnerRequests from "./Components/Admin/PartnerRequests";
import { Button } from "react-bootstrap";
import ManageCitiies from "./Components/Admin/ManageCitiies";
import Invitation from "./Components/Invitation";
import { Helmet } from "react-helmet-async";
import BuyTokens from "./Components/BuyTokens";

function App() {
  const [cityId, setCityId] = useState(1);
  const [startDate, setStartDate] = useState(
    sessionStorage.getItem("selectedDate")
      ? new Date(moment(Number(sessionStorage.getItem("selectedDate")) * 1000))
      : new Date()
  );

  const [numberOfGuests, setNumberOfGuests] = useState(0);
  const [selectedAccountPage, setSelectedAccountPage] = useState(-1);
  const [sessionExpiredModal, setSessionExpiredModal] = useState(false);
  const loginButtonRef = useRef();

  const setLogout = useStoreActions((action) => action.setLogout);
  const setLogoutLite = useStoreActions((action) => action.setLogoutLite);

  useEffect(() => {
    sessionStorage.setItem(
      "selectedDate",
      moment(startDate).startOf("day").unix()
    );
  }, [startDate]);

  useEffect(() => {
    const pathName = sessionStorage.getItem("pathName");
    if (pathName) {
      console.log("Navigating to ", pathName);
      window.location.pathname = pathName;
      sessionStorage.removeItem("pathName");
    }
  }, []);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState == "visible") {
        const idToken = localStorage.getItem("idToken");

        if (idToken) {
          try {
            const expired = checkIfTokenIsExpired(idToken);
            console.log("Token expired?:", expired);
            if (expired) {
              setLogout(true);
              setSessionExpiredModal(true);
            }
          } catch (e) {
            setLogout(true);
            console.log("Logged Out :: ", e);
          }
        }

        const cachedIdTokenLite = localStorage.getItem("idTokenLite");
        const cachedTimestamp = localStorage.getItem("idTokenLiteTimeStamp");
        const currentTime = new Date().getTime();

        if (
          cachedIdTokenLite &&
          cachedTimestamp &&
          currentTime - cachedTimestamp > 3600000 * 24
        ) {
          setLogoutLite(true);
        }
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () =>
      document.removeEventListener("visibilitychange", handleVisibilityChange);
  });

  const handleModalClose = () => {
    if (window.location.pathname !== "/") window.location.pathname = "/";
    setSessionExpiredModal(false);
  };

  return (
    <div className="App">
      <Helmet>
        <title>
          Buy Reservations to High Demand Restaurants and Events | PaidRez
        </title>
      </Helmet>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route element={<WithoutNav />}>
            <Route path="login" element={<Login />} />
            <Route path="admin" element={<AdminDashboard />}>
              <Route
                index={true}
                path="all-restaurants"
                element={<AllRestaurantsAdmin />}
              />
              <Route
                path="manage-reservations"
                element={<ManageReservations />}
              />
              <Route path="platform-fee" element={<PlatformFeeInfo />} />
              <Route path="blog-posts" element={<Blogs />} />
              <Route path="top-restaurants" element={<TopRestaurants />} />
              <Route path="users" element={<Users />} />
              <Route path="partner-requests" element={<PartnerRequests />} />
              <Route path="manage-cities" element={<ManageCitiies />} />
            </Route>
            <Route path="restaurantAdmin" element={<RestaurantAdminLogin />} />
          </Route>

          <Route
            element={
              <WithNav
                setCityId={setCityId}
                startDate={startDate}
                setStartDate={setStartDate}
                numberOfGuests={numberOfGuests}
                setNumberOfGuests={setNumberOfGuests}
                selectedAccountPage={selectedAccountPage}
                setSelectedAccountPage={setSelectedAccountPage}
                loginButtonRef={loginButtonRef}
              />
            }
          >
            <Route path="/" element={<HomePage cityId={cityId} />} />
            <Route
              path="restaurant/:id"
              element={
                <Restaurant
                  startDate={startDate}
                  setStartDate={setStartDate}
                  numberOfGuests={numberOfGuests}
                  setNumberOfGuests={setNumberOfGuests}
                />
              }
            />
            <Route
              path="restaurant/:id/confirm-reservation"
              element={<ResConfirmation />}
            />
            <Route path="blog/:id" element={<BlogPage />} />
            <Route path="all-restaurants" element={<AllRestaurants />} />

            <Route
              path="marketplace"
              element={
                <MarketPlace
                  startDate={startDate}
                  setStartDate={setStartDate}
                />
              }
            />
            <Route path="marketplace/nft/:tokenId" element={<NFTPage />} />
            <Route path="partners" element={<Partners />} />
            <Route
              path="value-proposition-for-restaurants"
              element={<ValueProposition />}
            />
            <Route path="how-it-works" element={<HowItWorks />} />
            <Route
              path="invitation/:inviteId"
              element={<Invitation loginButtonRef={loginButtonRef} />}
            />
            <Route
              path="account"
              element={
                <AccountNavbar
                  selectedAccountPage={selectedAccountPage}
                  setSelectedAccountPage={setSelectedAccountPage}
                />
              }
            >
              <Route
                index={true}
                path="reservations-and-notify"
                element={<ReservationsAndNotify />}
              />
              <Route path="profile" element={<Profile />} />
              <Route path="my-hit-list" element={<MyHitList />} />
              <Route path="payment-methods" element={<PaymentMethods />} />
              <Route path="communication" element={<Communication />} />
              <Route path=":help-and-feedback" element={<HelpAndFeedback />} />
            </Route>

            <Route
              path="buy-tokens"
              element={<BuyTokens loginButtonRef={loginButtonRef} />}
            />
          </Route>
        </Routes>
      </BrowserRouter>

      <AnimatePresence initial={false} exit={true}>
        {sessionExpiredModal && (
          <ModalBox
            modalOpen={sessionExpiredModal}
            handleClose={handleModalClose}
            children={
              <SessionExpiredPopup
                loginButtonRef={loginButtonRef}
                handleClose={() => setSessionExpiredModal(false)}
              />
            }
            style_class="session-expired-modal"
          />
        )}
      </AnimatePresence>
      {/* <Button onClick={() => setSessionExpiredModal(true)}>popup</Button> */}
    </div>
  );
}

export default App;
