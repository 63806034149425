import React from "react";
import AllRestaurants from "./AllRestaurants";
import Discover from "./Discover";
import RestaurantsCollections from "./RestaurantsCollections";
import UpcomingNFTSlots from "./UpcomingNFTSlots";

const HomePage = ({ cityId }) => {
  return (
    <div className="page-container">
      <Discover />
      {/* <RestaurantsCollections cityId={cityId} /> */}
      {/* <AllRestaurants cityId={cityId} /> */}
      <UpcomingNFTSlots cityId={cityId} />
    </div>
  );
};

export default HomePage;
