import React from "react";
import loading from "../Animations/spoon-rotation-animation.gif";

const LoadingAnimationSpoon = ({ className, width }) => {
  return (
    <img src={loading} alt="loading" className={className} width={width} />
  );
};

export default LoadingAnimationSpoon;
