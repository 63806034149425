import { ethers } from "ethers";
import { useState } from "react";
import {
  Alert,
  Button,
  Col,
  FloatingLabel,
  Form,
  Row,
  Toast,
  ToastContainer,
} from "react-bootstrap";
import RestaurantsService from "../../Services/RestaurantsService";
import LoadingAnimationEclipse from "../Helpers/LoadingAnimationEclipse";

const AddNewForm = ({ handleRestaurantAddedSuccessfully, closeModal }) => {
  const [resName, setResName] = useState("");
  const [resMobile, setResMobile] = useState("");
  const [adminEmail, setAdminEmail] = useState("");
  const [location, setLocation] = useState("");
  const [cityId, setCityId] = useState(0);
  const [creatorAddress, setCreatorAddress] = useState("");
  const [royaltyPercentage, setRoyaltyPercentage] = useState("");
  const [walletAddress, setWalletAddress] = useState("");
  const [alertMessage, setAlertMessage] = useState("Something went wrong");
  const [showAlert, setShowAlert] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [added, setAdded] = useState(false);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [validated, setValidated] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (submitting) {
      return;
    }

    const form = event.currentTarget;
    setShowAlert(false);
    setSubmitting(true);
    try {
      if (form.checkValidity() === false) {
        console.log("form not valid");

        setAlertMessage("Please fill all the required details");
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
          // setDisableSubmitButton(false);
        }, 2000);
        setSubmitting(false);
      } else {
        console.log("validated");
        const name = resName;
        const mobile = resMobile;

        await RestaurantsService.addNewRestaurant({
          name,
          mobile,
          adminEmail,
          location,
          creator: creatorAddress,
          royaltyPercent: royaltyPercentage,
          walletAddress,
          cityId,
        });

        console.log("added!");
        setAdded(true);
        setTimeout(() => {
          console.log("handle");
          handleRestaurantAddedSuccessfully(); // setModalOpen(false) To close modal
        }, 2000);
      }
    } catch (e) {
      console.log("cant add", e);
      if (e.response?.data?.errStatus === 500) {
        setAlertMessage(e.response.data.message);
      } else {
        setAlertMessage("Something went wrong");
      }
      setShowAlert(true);
      setSubmitting(false);
    }

    setValidated(true);
    setSubmitting(false);
    // console.log(validated);
  };

  return (
    <>
      <div className="close-button-container" onClick={() => closeModal()}>
        <svg
          width="32"
          height="32"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13.896 0.664673H34.126C42.036 0.664673 47.3327 6.21801 47.3327 14.478V33.5437C47.3327 41.7803 42.036 47.3313 34.126 47.3313H13.896C5.98602 47.3313 0.666016 41.7803 0.666016 33.5437V14.478C0.666016 6.21801 5.98602 0.664673 13.896 0.664673ZM31.0228 30.998C31.8162 30.207 31.8162 28.9237 31.0228 28.1304L26.8695 23.977L31.0228 19.8214C31.8162 19.0304 31.8162 17.7237 31.0228 16.9304C30.2295 16.1347 28.9462 16.1347 28.1295 16.9304L23.9995 21.0814L19.8462 16.9304C19.0295 16.1347 17.7462 16.1347 16.9528 16.9304C16.1595 17.7237 16.1595 19.0304 16.9528 19.8214L21.1062 23.977L16.9528 28.107C16.1595 28.9237 16.1595 30.207 16.9528 30.998C17.3495 31.3947 17.8862 31.607 18.3995 31.607C18.9362 31.607 19.4495 31.3947 19.8462 30.998L23.9995 26.8704L28.1528 30.998C28.5495 31.4204 29.0628 31.607 29.5762 31.607C30.1128 31.607 30.6262 31.3947 31.0228 30.998Z"
            fill="#D9D9D9"
          />
        </svg>
      </div>
      <Form
        className="add-new-form"
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
      >
        <h3 className="text-center">Add new restaurant</h3>
        <Row>
          <Col md={6}>
            <Form.Group className="mb-3" controlId="name">
              <Form.Label>Restaurant Name</Form.Label>
              <Form.Control
                type="text"
                required
                value={resName}
                onChange={(e) => setResName(e.target.value)}
              />
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group className="mb-3" controlId="mobile">
              <Form.Label>Mobile number</Form.Label>
              <Form.Control
                type="tel"
                required
                pattern="[0-9]{10}"
                value={resMobile}
                onChange={(e) => setResMobile(e.target.value)}
              />
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Label>Admin Email</Form.Label>
            <Form.Control
              type="email"
              required
              value={adminEmail}
              onChange={(e) => setAdminEmail(e.target.value)}
              className="mb-3"
            />
          </Col>

          <Col md={6}>
            <Form.Label>Location</Form.Label>
            <Form.Control
              type="text"
              required
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              className="mb-3"
            />
          </Col>

          <Col md={6}>
            <Form.Label>City</Form.Label>
            <Form.Select
              required
              className="mb-3"
              onChange={(e) => {
                setCityId(e.target.value);
              }}
            >
              <option value="-1" defaultValue={-1}>
                Select city
              </option>
              <option value="1">New York</option>
              <option value="2">Chicago</option>
              <option value="3">Miami</option>
              <option value="4">Atlanta</option>
              <option value="5">Boston</option>
              <option value="6">Austin</option>
              <option value="7">San Francisco</option>
              <option value="8">Seattle</option>
              <option value="9">Los Angeles</option>
            </Form.Select>
          </Col>

          <Col md={6}>
            <Form.Label>Creator</Form.Label>
            <Form.Control
              type="text"
              required
              value={creatorAddress}
              onChange={(e) => setCreatorAddress(e.target.value)}
              className="mb-3"
            />
          </Col>
          <Col md={6}>
            <Form.Label>
              Royalty <small className="text-black-50">(%)</small>
            </Form.Label>
            <Form.Control
              type="number"
              required
              value={royaltyPercentage}
              onChange={(e) => setRoyaltyPercentage(e.target.value)}
              className="mb-3"
            />
          </Col>
          <Col md={6}>
            <Form.Label>Wallet Address</Form.Label>
            <Form.Control
              type="text"
              required
              value={walletAddress}
              onChange={(e) => setWalletAddress(e.target.value)}
              className="mb-3"
            />
          </Col>
          {/* <Col md={6}>
          <Form.Label>Mint fees - 2 guests</Form.Label>
          <Form.Control
            type="text"
            required
            value={mintFees_2}
            onChange={(e) => setMintFees_2(e.target.value)}
            className="mb-3"
          />
        </Col>
        <Col md={6}>
          <Form.Label>Mint fees - 4 guests</Form.Label>
          <Form.Control
            type="text"
            required
            value={mintFees_4}
            onChange={(e) => setmintFees_4(e.target.value)}
            className="mb-3"
          />
        </Col>
        <Col md={6}>
          <Form.Label>Mint fees - 6 guests</Form.Label>
          <Form.Control
            type="text"
            required
            value={mintFees_6}
            onChange={(e) => setmintFees_6(e.target.value)}
            className="mb-3"
          />
        </Col> */}
        </Row>

        <Button
          variant={added ? "success" : "primary"}
          type="submit"
          // onClick={handleSubmit}
          disabled={disableSubmitButton}
          className="submit-button"
        >
          {!submitting ? "Submit" : <LoadingAnimationEclipse width={20} />}
        </Button>

        <Alert
          variant="danger"
          show={showAlert}
          className="my-2 text-capitalize"
          dismissible
          onClose={() => setShowAlert(false)}
        >
          {alertMessage}
        </Alert>
      </Form>
    </>
  );
};

export default AddNewForm;
