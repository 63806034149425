import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Image,
} from "react-bootstrap";
import { registerPartner } from "../Services/BlockchainBackendService";

const ValuePropsApplyNowPopup = ({ closeModal }) => {
  const [name, setName] = useState("");
  const [resName, setResName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const [isMobile, setMobile] = useState(window.innerWidth < "576");

  useEffect(() => {
    const updateScreenSize = () => {
      setMobile(window.outerWidth < "576");
    };
    window.addEventListener("resize", updateScreenSize);
    return () => window.removeEventListener("resize", updateScreenSize);
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(name, resName, email, address);
    setLoading(true);
    try {
      const res = await registerPartner({
        name,
        restaurantName: resName,
        email,
        address,
      });
      console.log(res);
      setSubmitted(true);
    } catch (e) {
      setLoading(false);
      console.log("ERROR::", e);
    }
  };

  return (
    <div className="apply-now">
      <button className="close-btn" onClick={() => closeModal()}>
        <svg height="20" viewBox="0 0 20 20" width="20">
          <path
            d="m3.94621165 2.89705176.08411844.07261815 5.96966991 5.96933009 5.9696699-5.96933009c.2928932-.29289321.767767-.29289321 1.0606602 0 .2662665.26626657.2904726.68293025.0726181.97654174l-.0726181.08411844-5.9693301 5.96966991 5.9693301 5.9696699c.2928932.2928932.2928932.767767 0 1.0606602-.2662666.2662665-.6829303.2904726-.9765418.0726181l-.0841184-.0726181-5.9696699-5.9693301-5.96966991 5.9693301c-.29289322.2928932-.76776696.2928932-1.06066018 0-.26626656-.2662666-.29047261-.6829303-.07261815-.9765418l.07261815-.0841184 5.96933009-5.9696699-5.96933009-5.96966991c-.29289321-.29289322-.29289321-.76776696 0-1.06066018.26626657-.26626656.68293025-.29047261.97654174-.07261815z"
            fillRule="evenodd"
          ></path>
        </svg>
      </button>

      {!submitted ? (
        <div className="form-and-image-container">
          <div className="form-container">
            <Image
              src="paidrez-logo.png"
              className="paidrez-logo"
              height={40}
            />
            <h3 className="mb-4">Apply as a Partner</h3>

            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <FormLabel>Full Name</FormLabel>
                <FormControl
                  type="text"
                  // placeholder="Enter your name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </FormGroup>

              <FormGroup>
                <FormLabel>Restaurant Name</FormLabel>
                <FormControl
                  type="text"
                  // placeholder="Enter your Restaurant Name"
                  value={resName}
                  onChange={(e) => setResName(e.target.value)}
                  required
                />
              </FormGroup>
              <FormGroup>
                <FormLabel>Restaurant Location</FormLabel>
                <FormControl
                  type="text"
                  // placeholder="Enter your Restaurant Address"
                  size="2"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  required
                />
              </FormGroup>
              <FormGroup>
                <FormLabel>Email Address</FormLabel>
                <FormControl
                  type="email"
                  // placeholder="Enter your Email Address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </FormGroup>

              <Button
                type="submit"
                className="submit-button"
                disabled={loading}
              >
                Submit
              </Button>
            </Form>
          </div>

          <div className="image-container">
            <Image src="/value-props-page/form-image.webp" />
          </div>
        </div>
      ) : (
        <div className="submitted">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="100"
            height="100"
            viewBox="0 0 48 48"
            className="mb-3"
          >
            <path
              fill="#c8e6c9"
              d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"
            ></path>
            <path
              fill="#4caf50"
              d="M34.586,14.586l-13.57,13.586l-5.602-5.586l-2.828,2.828l8.434,8.414l16.395-16.414L34.586,14.586z"
            ></path>
          </svg>
          <h3 className="mb-3">Submitted Successfully!</h3>

          <p>
            <b>
              Thank you for showing interest in our restaurant partner program.
              We will be in touch shortly.
            </b>
          </p>
        </div>
      )}
    </div>
  );
};

export default ValuePropsApplyNowPopup;
