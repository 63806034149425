import React from "react";
import { useEffect } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import BlogsService from "../../Services/BlogsService";
import { useState } from "react";
import { AnimatePresence } from "framer-motion";
import ModalBox from "../Modal/ModalBox";
import Blog from "./Blog";
import LoadingAnimationSpoon from "../Helpers/LoadingAnimationSpoon";

const Blogs = () => {
  const [blogs, setBlogs] = useState([]);
  const [modal, setModal] = useState(false);
  const [selectedBlog, setSelectedBlog] = useState(null);
  const [disableButtons, setDisableButtons] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchBlogs();
  }, []);

  useEffect(() => {
    if (selectedBlog) {
      setModal(true);
    }
  }, [selectedBlog]);

  const handleClose = () => {
    setModal(false);
    setSelectedBlog(null);
  };

  const fetchBlogs = () => {
    setLoading(true);
    BlogsService.getBlogs()
      .then((res) => {
        console.log("Blogs:", res.data);
        setBlogs(res.data);
        setLoading(false);
      })
      .catch((e) => {
        console.log("ERROR:", e);
        setLoading(false);
        alert("Something went wrong");
      });
  };

  const handleAddNew = () => {
    setModal(true);
  };

  const handleMakeTopPost = async (id) => {
    setDisableButtons(true);
    try {
      const res = await BlogsService.makeTopPost(id);
      console.log(res);
      fetchBlogs();
      setDisableButtons(false);
    } catch (e) {
      console.log("ERROR:", e);
      setDisableButtons(false);
      alert("Something went wrong");
    }
  };

  const handleDelete = async (id) => {
    setDisableButtons(true);
    try {
      const res = await BlogsService.deleteBlog(id);
      console.log("Blog Deleted", res);
      fetchBlogs();
      setDisableButtons(false);
    } catch (e) {
      console.log("ERROR", e);
      setDisableButtons(false);
      alert("Something went wrong");
    }
  };

  return (
    <Container className="admin-blogs">
      <h3 className="text-center mb-3">Blogs</h3>
      <Button
        className="paidrez-btn mb-2"
        onClick={handleAddNew}
        disabled={disableButtons}
      >
        Add New
      </Button>
      {loading ? (
        <LoadingAnimationSpoon className="d-block m-auto" width={150} />
      ) : (
        <Row xs={1} md={2} lg={3} xl={4} className="g-4">
          {Array.from(blogs).map((blog, idx) => (
            <Col key={idx}>
              <div className="blog-item-container">
                <Card>
                  <Card.Img
                    variant="top"
                    src={blog.imageUrl}
                    className="admin-blog-image"
                  />
                  <Card.Body>
                    <Card.Title>{blog.title}</Card.Title>
                    <Card.Text>
                      {blog.content?.substring(0, 50)}
                      {blog.content?.length > 50 && "..."}
                    </Card.Text>
                  </Card.Body>
                </Card>
                <Container className="buttons-container">
                  <Row className="top-post-button-container">
                    <Button
                      className="w-100 paidrez-btn mb-1"
                      disabled={blog.isTopPost || disableButtons}
                      onClick={() => handleMakeTopPost(blog._id)}
                    >
                      {blog.isTopPost ? "Top Post" : "Make Top Post"}
                    </Button>
                  </Row>
                  <Row>
                    <Col>
                      <Button
                        className="paidrez-btn-secondary w-100"
                        onClick={() => setSelectedBlog(blog)}
                        disabled={disableButtons}
                      >
                        Edit
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        variant="danger"
                        className="w-100 text-danger bg-white"
                        onClick={() => handleDelete(blog._id)}
                        disabled={disableButtons}
                      >
                        Delete
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </div>
            </Col>
          ))}
        </Row>
      )}

      <AnimatePresence initial={false} exit={true}>
        {modal && (
          <ModalBox
            modalOpen={modal}
            handleClose={handleClose}
            children={
              <Blog
                closeModal={handleClose}
                fetchBlogs={fetchBlogs}
                blog={selectedBlog}
              />
            }
            style_class="blog-modal"
          />
        )}
      </AnimatePresence>
    </Container>
  );
};

export default Blogs;
