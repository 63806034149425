import React, { useEffect, useState } from "react";
import "../scss/partners.scss";
import { Accordion, Button, Col, Container, Image, Row } from "react-bootstrap";
const Partners = () => {
  const [activeKey, setActiveKey] = useState(-1);
  const [activeColor, setActiveColor] = useState(-1);
  const [isMobile, setMobile] = useState(window.innerWidth < "480");

  useEffect(() => {
    console.log(activeKey);
  }, [activeKey]);

  useEffect(() => {
    const updateScreenSize = () => {
      setMobile(window.outerWidth < "480");
    };
    window.addEventListener("resize", updateScreenSize);
    return () => window.removeEventListener("resize", updateScreenSize);
  });

  return (
    <div className="partners page-container">
      <div className="top">
        <Container>
          <div className="ellipse"></div>
          <h1 className="header">
            SECURE YOUR EARNINGS THROUGH SECONDARY SALES.
          </h1>
          <Button className="get-started-button">
            <div>Get Started Now</div>
          </Button>
          {isMobile ? (
            <div className="girl-image-container">
              <Image src="girl.png" className="girl-image" />
              <Image
                src="dollar-gradient.png"
                // width={100}
                className="dollar-gradient-img"
              />
              <Image
                src="notify-heart-gradient.png"
                // width={100}
                className="notify-gradient-img"
              />
            </div>
          ) : (
            <div className="dashboard-image-border primary-border">
              <div className="image-container">
                DASHBOARD IMAGE OF RESTAURANT PARTNER
              </div>
              <Image
                src="dollar-gradient.png"
                width={100}
                className="dollar-gradient-img"
              />
              <Image
                src="notify-heart-gradient.png"
                width={100}
                className="notify-gradient-img"
              />
            </div>
          )}
        </Container>
        <hr />
      </div>

      <Container className="second">
        <Row className="mb-5 _500-restaurants">
          <Col xs={12} lg={6}>
            <h1 className="mb-4">
              500+ RESTAURANTS <br />
              <span className="blue-bg">OWNERS MAKE THE</span> <br />
              SUCCESSFUL
              <br />
              BUSINESS WITH US
            </h1>
            <Image
              src="people-img-horizontal.png"
              width={200}
              className="people-img-horizontal"
            />
          </Col>
          <Col xs={12} lg={6}>
            <Image src="people-img.png" className="people-img-1" />
          </Col>
        </Row>
        <Row className="mb-5 video-container">
          <Col>
            <div className="secondary-border">
              <div className="primary-border">
                <video
                  className="cheers-video"
                  autoPlay
                  loop
                  muted
                  playsInline
                  poster="/partners/cheers-poster.webp"
                  // width="320"
                  // height="240"
                  // controls
                >
                  <source src="/partners/cheers-video.mp4" type="video/mp4" />
                </video>
              </div>
            </div>
          </Col>
        </Row>

        <Row className="mb-5">
          <h1 className="work-with-us ">
            BENEFITS OF{" "}
            <span className="blue-bg px-1 nowrap"> WORK WITH US</span>
          </h1>
          <p className="text-secondary mb-0">
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quisquam,
            debitis.
          </p>
          <p className="text-white">
            Lorem ipsum dolor sit amet consectetur adipisicing.
          </p>
        </Row>

        <Row className="cards-container mb-5">
          <Col className="vertical-row">
            <div className="card h-50">
              <Image src="wallet.png" width={100} />
              <h1>Book Your Profit from Secondary Sales</h1>
            </div>
            <div className="card h-50">
              <Image src="lock.png" width={100} />
              <h1> Secure & Fast Payouts</h1>
            </div>
          </Col>
          <Col className="vertical-row">
            <div className="card right">
              <Image src="analytics.png" width={200} />
              <h4 className="analysis-text">Daily Analysis Reports</h4>
            </div>
            <div className="card right">
              <h1 className="X2">X2</h1>
              <h4>Advertising With Us</h4>
            </div>
            <div className="card right">
              <Image src="community.png" width={200} />
              <h4>Access of Our Community</h4>
            </div>
          </Col>
        </Row>

        <Row>
          <h3 className="mb-4">
            EFFORTLESSLY SECURE YOUR FIRST RESERVATION <br />
            <span className="blue-bg px-1">
              QUICK AND SIMPLE STEPS TO GET BOOKING
            </span>
          </h3>
        </Row>

        <Row xs={1} sm={2} md={3} className="register mb-5">
          <Col>
            <div className="card">
              <Image src="hands.png" width={100} className="" />
              <h4>Register as a Partner</h4>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Deleniti voluptates animi eos vero
              </p>
            </div>
          </Col>
          <Col>
            <div className="card">
              <Image src="calendar.png" width={100} className="" />
              <h4>Add Your Time Slots</h4>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Deleniti voluptates animi eos vero
              </p>
            </div>
          </Col>
          <Col>
            <div className="card">
              <Image src="money.png" width={100} className="" />
              <h4>Get Paid of All Your Reservations</h4>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Deleniti voluptates animi eos vero
              </p>
            </div>
          </Col>
        </Row>
      </Container>

      <Container className="faq">
        <Image src="heart-gradient.png" width={100} />
        <h4 className="mb-4">FREQUENTLY ASKED QUESTIONS</h4>

        <Accordion
          activeKey={activeKey}
          onSelect={(e) => {
            setActiveKey(e);
            e !== null && setActiveColor(e);
          }}
        >
          <div className={activeColor === "0" ? "active" : ""}>
            <Accordion.Item eventKey="0">
              <Accordion.Header>How to connect my wallet?</Accordion.Header>
              <Accordion.Body>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </Accordion.Body>
            </Accordion.Item>
          </div>

          <div className={activeColor === "1" ? "active" : ""}>
            <Accordion.Item eventKey="1">
              <Accordion.Header>How I get my payout?</Accordion.Header>
              <Accordion.Body>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </Accordion.Body>
            </Accordion.Item>
          </div>
          <div className={activeColor === "2" ? "active" : ""}>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                Can I register multiple restaurants?
              </Accordion.Header>
              <Accordion.Body>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </Accordion.Body>
            </Accordion.Item>
          </div>

          <div className={activeColor === "3" ? "active" : ""}>
            <Accordion.Item eventKey="3">
              <Accordion.Header>How I create my NFT?</Accordion.Header>
              <Accordion.Body>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </Accordion.Body>
            </Accordion.Item>
          </div>

          <div className={activeColor === "4" ? "active" : ""}>
            <Accordion.Item eventKey="4">
              <Accordion.Header>
                What is the payment options you have?
              </Accordion.Header>
              <Accordion.Body>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </Accordion.Body>
            </Accordion.Item>
          </div>

          <div className={activeColor === "5" ? "active" : ""}>
            <Accordion.Item eventKey="5">
              <Accordion.Header>
                Can I get my weekly reports on my mail address?
              </Accordion.Header>
              <Accordion.Body>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </Accordion.Body>
            </Accordion.Item>
          </div>

          <div className={activeColor === "6" ? "active" : ""}>
            <Accordion.Item eventKey="6">
              <Accordion.Header>
                How much slots can I book at a time?
              </Accordion.Header>
              <Accordion.Body>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </Accordion.Body>
            </Accordion.Item>
          </div>

          <div className={activeColor === "7" ? "active" : ""}>
            <Accordion.Item eventKey="7">
              <Accordion.Header>
                How I can access partners community?
              </Accordion.Header>
              <Accordion.Body>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </Accordion.Body>
            </Accordion.Item>
          </div>
        </Accordion>
      </Container>

      <Container className="new-footer mt-5">
        <h4 className="text-uppercase mb-3">
          Lorem ipsum, dolor sit amet consectetur adipisicing elit.
        </h4>
        <p className="mb-3">
          Explore your Favourite Restaurant and Book your Table Now{" "}
        </p>
        <Button className="get-started-button">Register Now</Button>
      </Container>
    </div>
  );
};

export default Partners;
