import axios from "axios";
import { ethers } from "ethers";

const BASE_URL = process.env.REACT_APP_BLOCKCHAIN_BE_SERVER_URL;
const customConfig = {
  headers: {
    "Content-Type": "application/json",
  },
};

export async function getReservationNFTs({
  name,
  location,
  startTime,
  endTime,
  seatCapacity,
  pageNo,
  pageSize,
  showActive,
  cityId,
  search,
  sortOrder,
}) {
  try {
    let restoId;
    if (name && location) {
      restoId = ethers.utils.solidityKeccak256(
        ["string", "string"],
        [name, location]
      );
    }
    const params = seatCapacity
      ? {
          restoId,
          startTime,
          endTime,
          seatCapacity,
          pageNo,
          pageSize,
          showActive,
          cityId,
          search,
          sortOrder,
        }
      : {
          restoId,
          startTime,
          endTime,
          pageNo,
          pageSize,
          showActive,
          cityId,
          search,
          sortOrder,
        };
    const response = await axios.get(
      `${BASE_URL}/confirmed-reservation-slots`,
      {
        params,
      }
    );
    return response.data;
  } catch (error) {
    console.log("ERROR::getReservationNFTs", error);
  }
}

export async function getTokenMetadata({ tokenURI }) {
  try {
    const response = await axios.get(`${tokenURI}`);
    console.log("mt", response);
    return response.data;
  } catch (error) {
    console.log("ERROR::getTokenMetadata", error);
  }
}

export async function markNFTAsMinted(id) {
  try {
    const body = JSON.stringify({ id });
    const response = await axios.post(
      `${BASE_URL}/mark-as-minted`,
      body,
      customConfig
    );
    return await response.data;
  } catch (error) {
    console.log("ERROR::markNFTAsMinted", error);
    throw new Error(error);
  }
}

export async function _useReservation(slotId) {
  try {
    const response = await axios.post(BASE_URL + "/use-reservation/" + slotId);
    return await response.data;
  } catch (e) {
    console.log("ERROR:use-NFT", e);
  }
}
export async function _useReservationLite(slotId) {
  try {
    const response = await axios.post(
      BASE_URL + "/use-reservation-lite/" + slotId
    );
    return await response.data;
  } catch (e) {
    console.log("ERROR:use-nft-lite", e);
  }
}

export async function getUpcomingReservations({
  restoId,
  walletAddress,
  pageSize = 10,
  pageNo = 1,
}) {
  try {
    const params = restoId
      ? { params: { restoId, pageSize, pageNo } }
      : { pageSize, pageNo };
    const response = await axios.get(
      `${BASE_URL}/upcoming-slots/${walletAddress}`,
      params,
      customConfig
    );
    return await response.data;
  } catch (error) {
    console.log("ERROR::getUpcomingReservations", error);
  }
}

export async function getPastReservations({
  restoId,
  walletAddress,
  pageSize = 10,
  pageNo = 1,
}) {
  try {
    const params = restoId
      ? { params: { restoId, pageSize, pageNo } }
      : { pageSize, pageNo };
    const response = await axios.get(
      `${BASE_URL}/past-slots/${walletAddress}`,
      params,
      customConfig
    );
    return await response.data;
  } catch (error) {
    console.log("ERROR::getPastReservations", error);
  }
}

export function toggleSlotStatus(restaurantId, slotId) {
  return axios.post(
    BASE_URL + "/toggle-slot-status/" + restaurantId + "/" + slotId
  );
}

export function getPartnerRequests() {
  return axios.get(BASE_URL + "/partner-requests");
}

export function registerPartner({ name, restaurantName, email, address }) {
  return axios.post(BASE_URL + "/register-partner", {
    name,
    restaurantName,
    email,
    address,
  });
}

export function getInvitation(inviteId) {
  return axios.get(BASE_URL + "/invite/" + inviteId);
}

export function acceptInvitation(inviteId) {
  return axios.post(BASE_URL + "/accept-invite/" + inviteId);
}
