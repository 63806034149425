import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { StoreProvider } from "easy-peasy";
import MainStorage from "./store/MainStorage";
import "react-loading-skeleton/dist/skeleton.css";
import { HelmetProvider } from "react-helmet-async";
import { MoonPayProvider } from "@moonpay/moonpay-react";

const root = ReactDOM.createRoot(document.getElementById("root"));
const moonpayApiKey = process.env.REACT_APP_MOONPAY_WIDGET_API_KEY;
root.render(
  <HelmetProvider>
    <StoreProvider store={MainStorage}>
      <MoonPayProvider
        // apiKey="pk_test_sZZgKB4MUrDDud11Tgkuq58EfYZwGI"
        apiKey={moonpayApiKey}
        // apiKey="pk_test_arFiVAL1N9FDzKwKKOzzzYt25rcg5Tsf"
        environment="sandbox"
        debug
      >
        <App />
      </MoonPayProvider>
    </StoreProvider>
  </HelmetProvider>
);

// const root = (
//   <HelmetProvider>
//     <StoreProvider store={MainStorage}>
//       <App />
//     </StoreProvider>
//   </HelmetProvider>
// );

// ReactDOM.hydrateRoot(document.getElementById("root"), root);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
