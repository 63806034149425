import React, { useEffect, useState } from "react";
import { Button, Form, FormControl, Image } from "react-bootstrap";
import OtpInput from "react-otp-input";
import PhoneInputWithCountrySelect, {
  formatPhoneNumber,
  isPossiblePhoneNumber,
} from "react-phone-number-input";
import PhoneInput from "react-phone-number-input/input";
import "react-phone-number-input/style.css";
import LoadingAnimationEclipse from "../Helpers/LoadingAnimationEclipse";
import UserService from "../../Services/UserService";
import "../../scss/loginLite.scss";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { useStoreState } from "easy-peasy";

const LoginLiteModal = ({
  closeModal,
  verified,
  collapseNavbar,
  handleWeb3AuthLogin,
}) => {
  const [otpChannel, setOtpChannel] = useState(0); // 0 mobile; 1 email
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [incorrect, setIncorrect] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [loginDetail, setLoginDetail] = useState("");
  const [loginLitePage, setLoginLitePage] = useState(false);

  const loginLite = useStoreState((state) => state.loginLite);

  useEffect(() => {
    if (otpSent) {
      setLoading(false);
    }
  }, [otpSent]);

  useEffect(() => {
    if (otp.length === 6) {
      handleOtpSubmit();
    } else {
      setIncorrect(false);
    }
  }, [otp]);

  const handleSubmit = async (e) => {
    e?.preventDefault();
    if (loginDetail.length === 0) {
      setErrorMsg("Enter Email Address or Mobile Number");
      return;
    }
    setErrorMsg("");
    setLoading(true);

    // Regex pattern for email
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    // Regex pattern for mobile number (supports common formats)
    const mobilePattern = /^\+?[1-9]\d{0,2}-?\d{9,13}$/;

    if (emailPattern.test(loginDetail)) {
      console.log("Email");
      // if (loginLite) {
      //   handleEmailSubmitLite();
      // } else {
      //   handleWeb3AuthLogin(loginDetail, "email");
      // }

      const res = await UserService.validateLoginType(
        loginDetail.toLowerCase(),
        "email"
      );
      console.log(res.data);

      if (res.data?.user?.valid) {
        if (loginLitePage) {
          if (res.data?.user?.notFound) {
            setErrorMsg(
              "Sign in Lite can be used for only accounts registered with us previously."
            );
            setLoading(false);
            return;
          }
          handleEmailSubmitLite();
        } else {
          handleWeb3AuthLogin(loginDetail.toLowerCase(), "email");
        }
      } else {
        setLoading(false);
        setErrorMsg(
          `Looks like the original sign in method for your account is ${res.data?.user?.phone}. Please use the same above.`
        );
      }
    } else if (mobilePattern.test(loginDetail)) {
      console.log("Mobile Number");

      let number = loginDetail;
      if (/^\+/.test(loginDetail)) {
        console.log("it contains +");
      } else {
        // setLoginDetail("+1" + loginDetail);
        number = "+1" + loginDetail;
        console.log("+1 added to number", number);
      }

      if (!isPossiblePhoneNumber(number)) {
        setErrorMsg("Invalid Mobile number");
        setLoading(false);
        return;
      }

      console.log(isPossiblePhoneNumber(number));
      const formattedNumber = formatPhoneNumberIntl(number)
        ?.replace(" ", "-")
        .replaceAll(" ", "");

      if (formattedNumber.split("-")[1].length < 10) {
        setErrorMsg("Invalid Mobile Number");
        setLoading(false);
        return;
      }

      console.log("formatted number", formattedNumber);

      const res = await UserService.validateLoginType(formattedNumber, "phone");
      console.log(res.data);
      if (res.data?.user?.valid) {
        if (loginLitePage) {
          if (res.data?.user?.notFound) {
            setErrorMsg(
              "Sign in Lite can be used for only accounts registered with us previously."
            );
            setLoading(false);
            return;
          }
          handleMobileSubmitLite(formattedNumber);
        } else {
          handleWeb3AuthLogin(formattedNumber, "phone");
        }
      } else {
        setLoading(false);
        setErrorMsg(
          `Looks like the original sign in method for your account is ${res.data?.user?.email}. Please use the same above.`
        );
      }
    } else {
      setLoading(false);
      setErrorMsg("Invalid Email Address or Mobile Number");
      console.log("Neither");
    }
  };

  const handleMobileSubmitLite = async (mobile) => {
    setOtpChannel(0);

    try {
      await UserService.sendOtpForLoginLite({ mobile });
      setOtpSent(true);
      setOtp("");
    } catch (e) {
      console.log("ERROR::", e);
      setErrorMsg(
        "Sign in Lite can be used for only accounts registered with us previously."
      );
      setLoading(false);
    }
  };

  const handleEmailSubmitLite = async () => {
    setOtpChannel(1);

    try {
      await UserService.sendOtpForLoginLite({
        email: loginDetail.toLowerCase(),
      });
      setOtpSent(true);
      setOtp("");
    } catch (e) {
      console.log("ERROR::", e);
      setErrorMsg(
        "Sign in Lite can be used for only accounts registered with us previously."
      );
      setLoading(false);
    }
  };

  const handleOtpSubmit = async () => {
    console.log("otp");
    setSubmitting(true);
    setIncorrect(false);
    try {
      const res =
        otpChannel === 0
          ? await UserService.verifyOtpForLoginLite({
              mobile: loginDetail,
              otp: otp,
            })
          : await UserService.verifyOtpForLoginLite({
              email: loginDetail,
              otp,
            });

      console.log(res);

      if (res.data?.data?.status === "failed") {
        setIncorrect(true);
        setSubmitting(false);
      } else {
        const signedIn = verified(res.data?.data, res.data?.token);

        if (signedIn) {
          setSubmitted(true);
          setSubmitting(false);
          setTimeout(() => {
            closeModal();
            collapseNavbar();
          }, 3000);
        }
      }
    } catch (e) {
      setSubmitting(false);
      console.log("ERROR::", e);
    }
  };

  return (
    <div className="login-lite">
      <div className="close-button-container" onClick={() => closeModal()}>
        <svg
          width="32"
          height="32"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.896 0.664673H34.126C42.036 0.664673 47.3327 6.21801 47.3327 14.478V33.5437C47.3327 41.7803 42.036 47.3313 34.126 47.3313H13.896C5.98602 47.3313 0.666016 41.7803 0.666016 33.5437V14.478C0.666016 6.21801 5.98602 0.664673 13.896 0.664673ZM31.0228 30.998C31.8162 30.207 31.8162 28.9237 31.0228 28.1304L26.8695 23.977L31.0228 19.8214C31.8162 19.0304 31.8162 17.7237 31.0228 16.9304C30.2295 16.1347 28.9462 16.1347 28.1295 16.9304L23.9995 21.0814L19.8462 16.9304C19.0295 16.1347 17.7462 16.1347 16.9528 16.9304C16.1595 17.7237 16.1595 19.0304 16.9528 19.8214L21.1062 23.977L16.9528 28.107C16.1595 28.9237 16.1595 30.207 16.9528 30.998C17.3495 31.3947 17.8862 31.607 18.3995 31.607C18.9362 31.607 19.4495 31.3947 19.8462 30.998L23.9995 26.8704L28.1528 30.998C28.5495 31.4204 29.0628 31.607 29.5762 31.607C30.1128 31.607 30.6262 31.3947 31.0228 30.998Z"
            fill="#D9D9D9"
          />
        </svg>
      </div>
      <Image src="/favicon/64_64.png" height={40} className="mb-3" />
      <h4 className="mb-4">Sign In {loginLitePage && "Lite"}</h4>
      {/* <hr /> */}
      {loginLitePage && !otpSent && (
        <small className="text-start d-block mb-3">
          <b>
            Using this method, you won't be able to make any transactions
            (purchase or sell). You can only view & use your already booked
            reservations at a restaurant.{" "}
          </b>
        </small>
      )}
      {!otpSent ? (
        <>
          <Form onSubmit={handleSubmit}>
            <p className="mb-2">
              Email Address or Mobile Number{" "}
              {/* <svg
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="opacity-75 align-text-bottom"
              >
                {" "}
                <path
                  d="M13 16H12V12H11M12 8H12.01M21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12Z"
                  stroke="#9CA3AF"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg> */}
            </p>
            <Form.Control
              value={loginDetail}
              onChange={(e) => {
                setLoginDetail(e.target.value);
              }}
              className="login-input mb-2"
              placeholder="Eg: name@example.com / 2125551234"
              id="login_data_input"
              autoCapitalize="none"
              spellCheck={false}
            />
            <div>
              <small className="error-msg text-danger fw-bold ms-2 mb-2 d-block">
                {errorMsg}
              </small>
            </div>
            <Button className="submit-button" type="submit" disabled={loading}>
              {loading ? <LoadingAnimationEclipse width={20} /> : "Submit"}
            </Button>
            <small className="text-center text-muted d-block link mb-2">
              Having Trouble?{" "}
              <a
                className="contact-us ps-0 text-muted"
                href="mailto:support@developingnow.com?subject=PAIDREZ%20Support"
              >
                Contact Us
              </a>
            </small>
            {!loginLite && !loginLitePage && (
              <small className="d-block text-muted text-center ">
                <b
                  className="try-lite-sign-in"
                  onClick={() => setLoginLitePage(true)}
                >
                  Try Lite Sign In
                </b>
              </small>
            )}

            {loginLitePage && (
              <small className="d-block paidrez-color text-center">
                <b
                  onClick={() => setLoginLitePage(false)}
                  className="cursor-pointer hover-underline"
                >
                  Go Back to Full Sign In
                </b>
              </small>
            )}
          </Form>

          {/* <small>
            <b className="text-muted">
              You won't be able to make any transaction using this sign in
              method.
            </b>
          </small> */}
        </>
      ) : (
        <div className="otp-screen">
          <p className="mb-1 text-center">
            We have sent a 6-Digit Confirmation Code to
          </p>
          <p className="text-center mobile-number">
            {loginDetail}{" "}
            <small
              className="edit"
              onClick={() => {
                if (!submitting && !submitted) setOtpSent(false);
              }}
            >
              Edit
            </small>
          </p>
          <p>
            Enter it below to verify{" "}
            {otpChannel === 0 ? "Mobile Number." : "your Email."}
          </p>
          <OtpInput
            value={otp}
            onChange={(otp) => setOtp(otp)}
            numInputs={6}
            // separator={<span>-</span>}
            className={
              incorrect
                ? "otp-input incorrect"
                : submitted
                ? "otp-input correct"
                : "otp-input"
            }
            shouldAutoFocus={true}
            isInputNum={true}
            hasErrored={true}
            isDisabled={submitting || submitted}
          />

          <div className="loader">
            {submitting && <LoadingAnimationEclipse width={20} />}
            {incorrect && (
              <p className="text-center text-danger">
                Please Enter Correct OTP
              </p>
            )}
            {submitted && (
              <p className="text-center">Signed In Successfully ✅</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default LoginLiteModal;
