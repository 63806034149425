import React, { useState } from "react";
import { Outlet } from "react-router";
import Footer from "../Footer";
import NavigationBar from "../NavigationBar";
import { useRef } from "react";

const WithNav = ({
  setCityId,
  startDate,
  setStartDate,
  numberOfGuests,
  setNumberOfGuests,
  selectedAccountPage,
  setSelectedAccountPage,
  loginButtonRef,
}) => {
  const [contractInstance, setContractInstance] = useState(null);
  const [provider, setProvider] = useState(null);
  const [mpFilters, setMpFilters] = useState(false);
  const [noDate, setNoDate] = useState(true);

  return (
    <>
      <NavigationBar
        setCityId={setCityId}
        startDate={startDate}
        setStartDate={setStartDate}
        numberOfGuests={numberOfGuests}
        setNumberOfGuests={setNumberOfGuests}
        selectedAccountPage={selectedAccountPage}
        setSelectedAccountPage={setSelectedAccountPage}
        contractInstance={contractInstance}
        setContractInstance={setContractInstance}
        provider={provider}
        setProvider={setProvider}
        loginButtonRef={loginButtonRef}
        mpFilters={mpFilters}
        noDate={noDate}
        setNoDate={setNoDate}
      />
      <Outlet
        context={{
          contractInstance,
          setContractInstance,
          provider,
          setProvider,
          setStartDate,
          loginButtonRef,
          mpFilters,
          setMpFilters,
          noDate,
          setNoDate,
          msg1: "okokok",
        }}
      />
      <Footer />
    </>
  );
};

export default WithNav;
