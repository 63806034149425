import axios from "axios";

const BASE_URL_NEW = process.env.REACT_APP_BLOCKCHAIN_BE_SERVER_URL;

class RestaurantsService {
  async getRestaurants(params) {
    try {
      const res = await axios.get(BASE_URL_NEW + "/restaurants", {
        params: params,
      });
      return res;
    } catch (err) {
      throw err;
    }
  }

  getRestaurantsByCityId(cityId) {
    return axios.get(BASE_URL_NEW + "/restaurants/city/" + cityId);
  }

  getRestaurantById(id) {
    // return axios.get(RESTAURANTS_BASE_URL + `/restaurant/${id}`);
    return axios.get(BASE_URL_NEW + "/restaurant-by-id/" + id);
  }

  getCities() {
    return axios.get(BASE_URL_NEW + "/cities");
  }

  addNewCity(city) {
    return axios.post(BASE_URL_NEW + "/city/add", city);
  }

  deleteCity(cityId) {
    return axios.delete(BASE_URL_NEW + "/city/delete/" + cityId);
  }

  addNewRestaurant(restaurant) {
    // return axios.post(RESTAURANTS_BASE_URL + "/addRestaurant", restaurant);
    return axios.post(BASE_URL_NEW + "/add-restaurant", restaurant);
  }

  updateRestaurant(id, restaurantDetails) {
    return axios.post(
      BASE_URL_NEW + `/update-restaurant/${id}`,
      restaurantDetails
    );
  }

  async registerRestaurant(id) {
    try {
      const response = await axios.post(
        `${BASE_URL_NEW}/registered-on-chain/${id}`
      );
      return await response.data;
    } catch (error) {
      console.log("ERROR::registerRestaurant", error);
    }
  }
  async deleteRestaurant(id) {
    return await axios.delete(`${BASE_URL_NEW}/delete-restaurant/${id}`, {});
  }

  toggleRestaurant(id) {
    return axios.post(BASE_URL_NEW + "/toggle-restaurant-status/" + id);
  }
}

export default new RestaurantsService();
