import React from "react";
import { useEffect } from "react";
import { useParams } from "react-router";

import "../scss/blogPage.scss";
import BlogsService from "../Services/BlogsService";
import { useState } from "react";
import { Image } from "react-bootstrap";
import Moment from "react-moment";

const BlogPage = () => {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);

  const fetchBlog = async () => {
    console.log("id", id);
    try {
      const res = await BlogsService.getBlogById(id);
      setBlog(res.data);
      console.log(res.data);
    } catch (e) {
      console.log("ERROR:", e);
    }
  };

  useEffect(() => {
    if (id) {
      fetchBlog();
    }
  }, [id]);

  return (
    <div className="blog-page page-container container">
      {blog && (
        <>
          <Image src={blog.imageUrl} className="blog-image mb-3" />
          <h1 className="blog-title">{blog.title}</h1>
          <small className="blog-writer">
            By {blog.author || "PaidRez"} |{" "}
            <Moment format="LL">{blog.updatedAt}</Moment>
          </small>
          <hr className="title-content-divider" />
          <p className="blog-content">{blog.content}</p>
        </>
      )}
    </div>
  );
};

export default BlogPage;
