import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Alert, Button, Container, InputGroup } from "react-bootstrap";
import "./Admin.scss";
import { Outlet, useNavigate } from "react-router-dom";
import { useStoreState } from "easy-peasy";
import LoadingAnimationNFT from "../Helpers/LoadingAnimationNFT";
import Web3AuthComponent from "../Web3AuthComponent";

const AdminDashboard = () => {
  const [contractInstance, setContractInstance] = useState(null);
  const [provider, setProvider] = useState(null);
  const [web3Loading, setWeb3Loading] = useState(true);
  const [errorAlert, setErrorAlert] = useState(false);
  const [successAlert, setSuccessAlert] = useState("");
  const [admin, setAdmin] = useState(false);

  const role = useStoreState((state) => state.role);

  // 'role' is persisting, so reloading also won't show "You are not authorized" unnecessarily

  useEffect(() => {
    if (role === "ADMIN") {
      setAdmin(true);
      setWeb3Loading(false);
    } else {
      setAdmin(false);
      setWeb3Loading(false);
    }
  }, [role]);

  let navigate = useNavigate();

  const setError = (msg) => {
    setErrorAlert(msg || "Something Went Wrong");
    setTimeout(() => {
      setErrorAlert("");
    }, 3000);
  };

  const setSuccess = (msg) => {
    setSuccessAlert(msg || "Success");
    setTimeout(() => {
      setSuccessAlert("");
    }, 3000);
  };

  return (
    <div className="admin">
      <div className="top-bar">
        <Container>
          <div className="logo-container">
            <img
              src="/paidrez-logo.png"
              height={40}
              alt=""
              onClick={() => navigate("/")}
            />
            <div className="hello-container d-inline ms-2"> Hi Admin 👋</div>
          </div>

          <div className="button-container">
            <Web3AuthComponent
              contractInstance={contractInstance}
              setContractInstance={setContractInstance}
              provider={provider}
              setProvider={setProvider}
              admin={true}
            />
          </div>
        </Container>
      </div>
      {admin && (
        <>
          <Container className="admin-dashboard">
            <InputGroup className="my-3 top-buttons-container overflow-auto">
              <Button
                className="mx-1 paidrez-btn"
                onClick={() => {
                  navigate("./all-restaurants");
                }}
              >
                All Restaurants
              </Button>

              <Button
                onClick={() => navigate("./manage-reservations")}
                className="mx-1 paidrez-btn"
              >
                Manage Reservations
              </Button>

              <Button
                onClick={() => navigate("./platform-fee")}
                className="mx-1 paidrez-btn"
              >
                Platform Fee Info
              </Button>

              <Button
                className="mx-1 paidrez-btn"
                onClick={() => {
                  navigate("./top-restaurants");
                }}
              >
                Top Restaurants Lists
              </Button>

              <Button
                className="mx-1 paidrez-btn"
                onClick={() => {
                  navigate("./blog-posts");
                }}
              >
                Blogs
              </Button>

              <Button
                className="mx-1 paidrez-btn"
                onClick={() => {
                  navigate("./users");
                }}
              >
                Users
              </Button>

              <Button
                className="mx-1 paidrez-btn"
                onClick={() => navigate("./partner-requests")}
              >
                Partner Requests
              </Button>

              <Button
                className="mx-1 paidrez-btn"
                onClick={() => navigate("./manage-cities")}
              >
                Manage Cities
              </Button>
            </InputGroup>

            <Outlet
              context={{
                contractInstance,
                setWeb3Loading,
                setError,
                setSuccess,
              }}
            />

            {errorAlert && (
              <Alert
                dismissible
                className="error-alert"
                variant="danger"
                onClose={() => setErrorAlert(false)}
              >
                {errorAlert}
              </Alert>
            )}

            {successAlert && (
              <Alert
                dismissible
                className="error-alert"
                variant="success"
                onClose={() => setSuccessAlert("")}
              >
                {successAlert}
              </Alert>
            )}
          </Container>
        </>
      )}

      {!admin && <h3>You are not authorized to access this section</h3>}

      {web3Loading && (
        <div className="nft-loader-container fullscreen topmost">
          <LoadingAnimationNFT />
        </div>
      )}
    </div>
  );
};

export default AdminDashboard;
