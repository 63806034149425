import React, { useEffect, useState } from "react";
import { Alert, Button, Form } from "react-bootstrap";
import OtpInput from "react-otp-input";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useNavigate } from "react-router-dom";
import otpService from "../Services/otpService";
import LoadingAnimationEclipse from "./Helpers/LoadingAnimationEclipse";

const MobileOtp = ({ getMobileNumber: otpVerified = () => {}, onlyLogIn }) => {
  const [mobileNumber, setMobileNumber] = useState("");
  const [isMobileEntered, setMobileEntered] = useState(false);
  const [otp, setOtp] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [loading, setLoading] = useState(false);

  let navigate = useNavigate();

  const isLoggedIn = () => {
    if (sessionStorage.getItem("isLoggedIn") === "true") {
      console.log("mobile entered and submitted set to true");
      setMobileEntered(true);
      setSubmitted(true);
    }
  };

  const handleLogout = (e) => {
    e.preventDefault();
    sessionStorage.setItem("isLoggedIn", false);
  };

  const handleLogin = (e) => {
    e.preventDefault();
    sessionStorage.setItem("isLoggedIn", true);
  };

  useEffect(() => {
    isLoggedIn();
  }, []);

  const handleOtpSending = async (e) => {
    e.preventDefault();
    setLoading(true);
    console.log("mobile-numer: ", mobileNumber);
    if (!mobileNumber) {
      console.log("no mobile number, hence alert true");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
        setLoading(false);
      }, 3000);
      return;
    }

    const otp = await otpService.getOtp(mobileNumber);

    if (otp === -1) {
      console.log("otp not sent");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
        setLoading(false);
      }, 3000);
    } else {
      setMobileEntered(true);
      sessionStorage.setItem("otp", otp);
    }
  };

  const handleOtpSubmit = async () => {
    console.log("otp submit button clicked");
    if (sessionStorage.getItem("otp") === null) {
      setAlertMessage("Looks like this OTP has expired.");
      setShowAlert(true);
    } else if (otp === sessionStorage.getItem("otp")) {
      otpVerified(mobileNumber);
      // sessionStorage.removeItem("otp");
    } else {
      setAlertMessage("Please enter correct OTP");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
  };

  useEffect(() => {
    if (otp.length === 6) {
      handleOtpSubmit();
    }
  }, [otp]);

  return (
    <div className="mobile-otp">
      {!isMobileEntered ? (
        <Form className="mobile-form">
          <img
            src="/paidrez-logo.png"
            alt="paidrez-logo"
            className="paidrez-logo"
            height={34}
          />

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <p className="otp-form-text">
              {!onlyLogIn && <span>The Bar at Manhatta uses {""}</span>}
              PaidRez's hospitality platform {!onlyLogIn && "to"} securely
              manage
              {onlyLogIn && "s "}
              your account information and reservations.
            </p>
            <p className="otp-form-headline">
              Please enter your mobile phone number to verify or create an
              account:
            </p>
            <Form.Label>
              <small>Mobile Number</small>
            </Form.Label>
            <PhoneInput
              defaultCountry="US"
              placeholder="Enter phone number"
              international
              countryCallingCodeEditable={false}
              withCountryCallingCode={true}
              value={mobileNumber}
              onChange={setMobileNumber}
            />

            {/* <Form.Control
              type="number"
              maxLength="10"
              value={mobileNumber}
              onChange={(e) => {
                setMobileNumber(e.target.value);
              }}
            /> */}
          </Form.Group>

          <Alert
            className="otp-not-sent-alert"
            variant="danger"
            show={showAlert}
            // show
            // onClose={setShowAlert(false)}
            // dismissible
          >
            OTP not sent
          </Alert>

          <Button
            variant="primary"
            type="submit"
            className="get-otp-button border-0"
            // disabled={!(mobileNumber.length === 10) || showAlert}
            onClick={handleOtpSending}
            disabled={loading}
          >
            {loading ? <LoadingAnimationEclipse width={25} /> : "Get OTP"}
          </Button>
          <div class="otp-footnote">
            By providing your mobile number, you agree that it may be used to
            send you text messages and call you to confirm and manage your
            reservations.
          </div>
          <hr />

          <button className="otp-footer">Use Email and Password instead</button>
        </Form>
      ) : (
        <>
          <div className="otp-entry-screen">
            <img
              src="/paidrez-logo.png"
              alt="paidrez-logo"
              className="paidrez-logo"
              height={34}
            />

            <p className="header">Check your mobile phone!</p>
            <p className="text">
              We sent a 6-digit confirmation code to <span>{mobileNumber}</span>
              . It expires shortly, so enter it soon.
            </p>
            <OtpInput
              value={otp}
              onChange={(otp) => setOtp(otp)}
              numInputs={6}
              // separator={<span>-</span>}
              className="otp-input"
              shouldAutoFocus={true}
              isInputNum={true}
              hasErrored={true}
            />
            {/* <small>
                Not received? <a>Resend</a>
              </small> */}

            {/* <br /> */}
            {/* <Button onClick={handleOtpSubmit} variant="primary" type="submit">
                Submit
              </Button> */}
            <button className="need-help-button">Need Help?</button>
            <Alert
              className="incorrect-otp-alert"
              variant="danger"
              show={showAlert}
            >
              {alertMessage}{" "}
              {sessionStorage.getItem("otp") === null && (
                <a
                  href="/#"
                  onClick={() => {
                    console.log("send again clicked");
                    // setMobileEntered(false);
                    setShowAlert(false);
                    setOtp("");
                    setMobileEntered(false);
                  }}
                >
                  Send again
                </a>
              )}
            </Alert>
          </div>
          {/* ) : (
            <>
              {loading ? (
                <div className="loading">
                  <h1>Loading...</h1>
                  <h5>Confirming with restaurant, Please wait</h5>
                </div>
              ) : (
                <div className="booking-successful-dialog">
                  <img src="/success.gif" alt="success.gif" width={100} />
                  <h1>Congratulations!</h1>
                  <h5>Your booking was successful.</h5>
                  <p>Booking details are sent to your mobile</p>
                </div>
              )}
            </>
          )} */}
        </>
      )}
    </div>
  );
};

export default MobileOtp;
