import React, { useState } from "react";
import { Button, Form, FormControl, Image } from "react-bootstrap";
import CloseIcon from "../Icons/CloseIcon";

const UpdateListingPopup = ({
  nft,
  closeModal,
  setModalValue,
  setNewPrice,
  newPrice,
}) => {
  // const [newPrice, setNewPrice] = useState(nft.priceInMatic);

  const handleButtonClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (newPrice && newPrice != 0) {
      if (newPrice !== nft.priceInMatic) {
        setModalValue(3);
        setNewPrice(newPrice);
      } else {
        closeModal();
      }
    }
  };
  return (
    <div className="update-listing">
      <CloseIcon closeModal={closeModal} />

      <h4 className="text-center">Update Listing</h4>

      <hr />

      <Image
        src={nft.s3ImageURI}
        alt=""
        className="d-block mx-auto mb-4 object-fit-cover"
        roundedCircle
        width={150}
        height={150}
      />

      <h5 className="mb-3 text-center">
        {nft?.tokenName ||
          `PAIDREZ ${nft?.restoName} #${nft?.tokenId
            ?.toString()
            .padStart(5, "0")}`}
      </h5>

      <Form onSubmit={handleButtonClick}>
        <div className="position-relative mb-2">
          <FormControl
            type="number"
            step={0.001}
            min={0}
            placeholder="0.0"
            className="price-input"
            value={newPrice}
            autoFocus
            onChange={(e) => setNewPrice(e.target.value)}
          />
          <p className="matic">
            POL <img src="/matic-icon.svg" alt="" />
          </p>
        </div>

        <Button className="paidrez-btn w-100" type="submit">
          Continue{" "}
          <svg
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="align-text-bottom"
          >
            <path
              d="M11.6935 5.57568L17.0867 10.8106M17.0867 10.8106L11.6935 16.0455M17.0867 10.8106L3.2666 10.8106"
              stroke="#FCFCF9"
              strokeWidth="2.0102"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Button>
      </Form>
    </div>
  );
};

export default UpdateListingPopup;
