import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Accordion, Button, Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ReservationsService from "../../Services/ReservationsService";
import RestaurantDetails from "./RestaurantDetails";

const ReservationSummary = ({
  closeModal,
  setModalChild,
  modificationAllowed,
  reservationId,
  handleCancelButtonClick,
}) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [reservationDetails, setReservationDetails] = useState(null);

  const fetchReservationDetails = () => {
    ReservationsService.getReservationByReservationId(reservationId)
      .then((res) => {
        console.log(res.data);
        setReservationDetails(res.data.reservation);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        // alert "something went wrong"
      });
  };

  useEffect(() => {
    fetchReservationDetails();
  }, []);

  if (loading) {
    return <h1>loading</h1>;
  }
  return (
    <div className="reservation-summary">
      <button class="close-btn" onClick={closeModal}>
        <svg height="20" viewBox="0 0 20 20" width="20">
          <path
            d="m3.94621165 2.89705176.08411844.07261815 5.96966991 5.96933009 5.9696699-5.96933009c.2928932-.29289321.767767-.29289321 1.0606602 0 .2662665.26626657.2904726.68293025.0726181.97654174l-.0726181.08411844-5.9693301 5.96966991 5.9693301 5.9696699c.2928932.2928932.2928932.767767 0 1.0606602-.2662666.2662665-.6829303.2904726-.9765418.0726181l-.0841184-.0726181-5.9696699-5.9693301-5.96966991 5.9693301c-.29289322.2928932-.76776696.2928932-1.06066018 0-.26626656-.2662666-.29047261-.6829303-.07261815-.9765418l.07261815-.0841184 5.96933009-5.9696699-5.96933009-5.96966991c-.29289321-.29289322-.29289321-.76776696 0-1.06066018.26626657-.26626656.68293025-.29047261.97654174-.07261815z"
            fill-rule="evenodd"
          ></path>
        </svg>
      </button>

      <div className="header">
        <h1>Reservation booked</h1>
        <p>Please check your inbox for a confirmation email.</p>
      </div>

      <RestaurantDetails
        // restaurant={restaurant}
        reservationDetails={reservationDetails}
      />

      <div className="body">
        <Container className="questions-container">
          <Accordion>
            <Accordion.Item eventKey="0" className="question">
              <Accordion.Header className="question-header">
                <div>
                  <h1>What's the Occasion?</h1>
                  <p>Celebrating something special?</p>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <Form>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Add special request"
                  />
                  <Button className="mt-3 ">Submit</Button>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1" className="question">
              <Accordion.Header className="question-header">
                <div>
                  <h1>Any special requests?</h1>
                  <p>We'll give restaurants a headsup</p>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <Form>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Add special request"
                  />
                  <Button className="mt-3">Submit</Button>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2 " className="question">
              <Accordion.Header className="question-header">
                <div>
                  <h1>Any allergies or restrictions?</h1>
                  <p>Your own or someone in your party</p>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <Form>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Add special request"
                  />
                  <Button className="mt-3">Submit</Button>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Container>

        {/* <Container className="invite-container">
          <h1>Invite your party</h1>
        </Container> */}

        <Container className="summary-container">
          <Row className="mb-4">
            <Col md={3}>
              <h2>Order Summary</h2>
            </Col>
            <Col className="flex-grow-0">
              <p>{reservationDetails.number_of_guests}</p>
            </Col>
            <Col>
              <p>Dining room</p>
            </Col>
            <Col className="flex-grow-0">
              <p>$0.00</p>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col md={3}>
              <h2>Need to know</h2>
            </Col>
            <Col></Col>
          </Row>
          <Row className="mb-4">
            <Col md={3}>
              <h2>Cancellation policy</h2>
            </Col>
            <Col>
              <p className="mb-3">
                While you won't be charged if you need to cancel, we ask that
                you do so at least 24 hours in advance.
              </p>
              {modificationAllowed && (
                <div className="buttons-container">
                  <Button
                    className="change-btn me-2"
                    onClick={() => {
                      console.log("change rservation button clicked");
                      setModalChild(2);
                    }}
                  >
                    Change Reservation
                  </Button>
                  <Button
                    className="cancel-btn"
                    variant="danger"
                    onClick={() => {
                      handleCancelButtonClick();
                      // setModalChild(0);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </div>
      {!modificationAllowed && (
        <Container className="done-button-container">
          <Button
            className="done-button"
            onClick={() => {
              navigate("/");
            }}
          >
            Done
          </Button>
        </Container>
      )}
    </div>
  );
};

export default ReservationSummary;
