import React from "react";
import { motion } from "framer-motion";
import Backdrop from "./Backdrop";
// import Contact from "../../Contact";

const dropIn = {
  hidden: {
    y: "-10vh",
    opacity: 0,
  },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 0.6,
      type: "spring",
      // damping: 25,
      // stiffness: 500,
    },
  },
  exit: {
    y: "10vh",
    opacity: 0,
    transition: {
      duration: 0.6,
      type: "spring",
      // damping: 25,
      // stiffness: 500,
    },
  },
};
// eslint-disable-next-line
const flip = {
  hidden: {
    transform: "scale(0) rotateX(-360deg)",
    opacity: 0,
    transition: {
      delay: 0.3,
    },
  },
  visible: {
    transform: " scale(1) rotateX(0deg)",
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
  exit: {
    transform: "scale(0) rotateX(360deg)",
    opacity: 0,
    transition: {
      duration: 0.5,
    },
  },
};

const ModalBox = ({ handleClose, children, style_class = "" }) => {
  return (
    <Backdrop onClick={handleClose} children={children}>
      <motion.div
        onClick={(e) => e.stopPropagation()}
        className={` ${style_class} my-modal`}
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
        disabled
      >
        {children}
      </motion.div>
    </Backdrop>
  );
};

export default ModalBox;
