import React from "react";

const PeopleIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon"
    >
      <path
        d="M14.9856 11.5371C16.8427 11.9182 17.5219 13.1535 17.5219 14.1851"
        stroke="#9E9E9E"
        strokeWidth="1.14435"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5728 5.2312C15.4182 5.50406 16.028 6.29839 16.0246 7.23476C16.021 8.13127 15.4554 8.89532 14.6619 9.19239"
        stroke="#9E9E9E"
        strokeWidth="1.14435"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.00391 15.7532C5.00391 14.1447 6.27378 12.142 9.93094 12.142C13.5881 12.142 14.858 14.1299 14.858 15.7394"
        stroke="#9E9E9E"
        strokeWidth="1.14435"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0792 6.6944C13.0792 8.43289 11.6699 9.84136 9.93224 9.84136C8.19376 9.84136 6.78442 8.43289 6.78442 6.6944C6.78442 4.95589 8.19376 3.54742 9.93224 3.54742C11.6699 3.54742 13.0792 4.95589 13.0792 6.6944Z"
        stroke="#9E9E9E"
        strokeWidth="1.14435"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.87711 11.5371C3.01993 11.9182 2.34082 13.1535 2.34082 14.1851"
        stroke="#9E9E9E"
        strokeWidth="1.14435"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.28993 5.2312C4.4445 5.50406 3.83469 6.29839 3.83815 7.23476C3.84161 8.13127 4.40726 8.89532 5.20071 9.19239"
        stroke="#9E9E9E"
        strokeWidth="1.14435"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PeopleIcon;
