// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blog-page {
  max-width: 1000px !important;
}
.blog-page .blog-image {
  width: 100%;
  object-fit: cover;
  max-height: 70vh;
}
@media (max-width: 576px) {
  .blog-page .blog-image {
    max-height: 400px;
  }
}
.blog-page .blog-title {
  color: #0a697e;
}
.blog-page .blog-writer {
  margin-bottom: 0.5rem;
}
.blog-page .title-content-divider {
  margin: 2rem 0;
}
.blog-page .blog-content {
  white-space: pre-line;
  font-size: 20px;
  font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/scss/blogPage.scss"],"names":[],"mappings":"AAEA;EACE,4BAAA;AADF;AAGE;EACE,WAAA;EACA,iBAAA;EACA,gBAAA;AADJ;AAEI;EAJF;IAKI,iBAAA;EACJ;AACF;AAEE;EAEE,cAhBY;AAehB;AAKE;EACE,qBAAA;AAHJ;AAME;EACE,cAAA;AAJJ;AAOE;EACE,qBAAA;EACA,eAAA;EACA,gBAAA;AALJ","sourcesContent":["$paidrez-color: #0a697e;\n\n.blog-page {\n  max-width: 1000px !important;\n\n  .blog-image {\n    width: 100%;\n    object-fit: cover;\n    max-height: 70vh;\n    @media (max-width: 576px) {\n      max-height: 400px;\n    }\n  }\n\n  .blog-title {\n    // text-align: center;\n    color: $paidrez-color;\n    // margin-bottom: 1rem;\n  }\n\n  .blog-writer {\n    margin-bottom: 0.5rem;\n  }\n\n  .title-content-divider {\n    margin: 2rem 0;\n  }\n\n  .blog-content {\n    white-space: pre-line;\n    font-size: 20px;\n    font-weight: 500;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
