import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useKeyPress from "./Helpers/useKeyPress";

const SearchResultsContainer = ({
  searchResults,
  setIsVisible,
  setSearchTerm,
  closeSearchPage,
}) => {
  const navigate = useNavigate();
  const image_url = process.env.REACT_APP_API_BASE_URL + "/images/";

  const [selected, setSelected] = useState(searchResults[0]);
  const downPress = useKeyPress("ArrowDown");
  const upPress = useKeyPress("ArrowUp");
  const enterPress = useKeyPress("Enter");
  const [cursor, setCursor] = useState(0);
  const [hovered, setHovered] = useState(undefined);

  useEffect(() => {
    if (searchResults.length && downPress) {
      console.log("downpress");
      setCursor((prevState) =>
        prevState < searchResults.length - 1 ? prevState + 1 : prevState
      );
      console.log();
    }
  }, [downPress]);
  useEffect(() => {
    if (searchResults.length && upPress) {
      setCursor((prevState) => (prevState > 0 ? prevState - 1 : prevState));
    }
  }, [upPress]);
  useEffect(() => {
    if (searchResults.length && enterPress) {
      setSelected(searchResults[cursor]);
    }
  }, [cursor, enterPress]);
  useEffect(() => {
    if (searchResults.length && hovered) {
      console.log("hovered");
      setCursor(searchResults.indexOf(hovered));
    }
  }, [hovered]);

  useEffect(() => {
    if (selected) {
      console.log("selected");
    }
  }, [selected]);

  return (
    <div className="search-results-container">
      {searchResults.map((restaurant, index) => (
        <div
          className="result-row py-2 px-3"
          // onClick={() => {
          //   navigate(`/restaurant/${restaurant.restaurant_id}`);
          //   setIsVisible(false);
          //   setSearchTerm("");
          //   closeSearchPage();
          // }}
          index={index}
          onClick={() => setSelected(restaurant)}
          onMouseEnter={() => setHovered(restaurant)}
          onMouseLeave={() => setHovered(undefined)}
        >
          <div
            className="image-container"
            style={{
              backgroundImage: `url(${
                // image_url + restaurant.restaurant_id + ".jpeg"
                restaurant.displayImageS3Url
                  ? restaurant.displayImageS3Url
                  : "/placeholder-restaurant.png"
              })`,
            }}
          >
            {/* <Image
                  src="https://picsum.photos/200/300"
                  className="bg-info"
                /> */}
          </div>
          <div className="restaurant-details">
            <h5>{restaurant.name}</h5>
            <p>{restaurant.cuisineType}</p>
            <p>
              {restaurant.location}, {restaurant.city}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SearchResultsContainer;
