import { useStoreState } from "easy-peasy";
import React, { useEffect, useState } from "react";
import { getPartnerRequests } from "../../Services/BlockchainBackendService";
import { Table } from "react-bootstrap";
import LoadingAnimationSpoon from "../Helpers/LoadingAnimationSpoon";

const PartnerRequests = () => {
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);

  // const role = useStoreState((state) => state.role);
  const account = useStoreState((state) => state.account);

  useEffect(() => {
    if (account) fetchRequests();
  }, [account]);

  const fetchRequests = async () => {
    setLoading(true);
    try {
      const res = await getPartnerRequests();
      console.log(res);
      setRequests(res.data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log("ERROR::", e);
    }
  };
  return (
    <>
      {loading ? (
        <div className="text-center">
          <LoadingAnimationSpoon width={150} />
        </div>
      ) : (
        <div className="partner-requests">
          <h4>Partner Requests</h4>
          <Table striped bordered>
            <thead className="text-bg-secondary">
              <tr>
                <th>Name</th>
                <th>Restaurant Name</th>
                <th>Email</th>
                <th>Address</th>
              </tr>
            </thead>
            <tbody>
              {requests.map((req, i) => (
                <tr key={i}>
                  <td>{req.name}</td>
                  <td>{req.restaurantName}</td>
                  <td>{req.email}</td>
                  <td>{req.address}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
    </>
  );
};

export default PartnerRequests;
