import axios from "axios";

// *********  NOT IN USE ***********

const BASE_URL = process.env.REACT_APP_API_BASE_URL + "/topRestaurants";

class TopRestaurantsService {
  getTopRestaurants() {
    return axios.get(BASE_URL + "/");
  }

  getTopRestaurantsByCityId(cityId) {
    return axios.get(BASE_URL + "/getTopRestaurantsByCityId", {
      params: { cityId },
    });
  }

  addTopRestaurant(topRestaurant) {
    return axios.post(BASE_URL + "/add", topRestaurant);
  }

  deleteTopRestaurant(restaurant_id, list_id) {
    return axios.delete(BASE_URL + "/delete", {
      params: {
        restaurant_id: restaurant_id,
        list_id: list_id,
      },
    });
  }

  updateListTitles(titleDetails) {
    return axios.put(BASE_URL + "/updateListTitle", titleDetails);
  }
}

export default new TopRestaurantsService();
