import axios from "axios";

const RESERVATIONS_BASE_URL =
  process.env.REACT_APP_API_BASE_URL + "/reservations";

// ****** NOT IN USE **********

class ReservationService {
  getReservations(userId) {
    return axios.get(RESERVATIONS_BASE_URL + "/getReservationsByUserId", {
      params: { userId: userId },
    });
  }

  getPastReservations(userId) {
    return axios.get(RESERVATIONS_BASE_URL + "/getPastReservationsByUserId", {
      params: { userId: userId },
    });
  }

  getReservationByReservationId(reservationId) {
    return axios.get(RESERVATIONS_BASE_URL + "/getReservationByReservationId", {
      params: {
        reservationId: reservationId,
      },
    });
  }

  addNewReservation(reservationDetails) {
    return axios.post(
      RESERVATIONS_BASE_URL + "/addNewReservation",
      reservationDetails
    );
  }

  changeReservation(reservationDetails) {
    return axios.put(
      RESERVATIONS_BASE_URL + "/changeReservation",
      reservationDetails
    );
  }

  cancelReservation(reservation_id) {
    return axios.delete(RESERVATIONS_BASE_URL + "/cancelReservation", {
      params: {
        reservation_id,
      },
    });
  }
}

export default new ReservationService();
