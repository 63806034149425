import React, { useEffect, useState } from "react";
import { Button, Image, Table } from "react-bootstrap";
import MarketplaceService from "../../../Services/MarketplaceService";
import { ethers } from "ethers";
import { useStoreState } from "easy-peasy";
import { getCurrentMaticUSDPrice } from "../../../Services/MaticPriceService";
import LoadingAnimationSpoon from "../../Helpers/LoadingAnimationSpoon";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import CloseIcon from "../../Icons/CloseIcon";

const SaleConfirmation = ({
  closeModal,
  nft,
  listingPrice,
  handleModalError,
  setModalValue,
  fetchReservations,
  updateBalance,
}) => {
  const [listing, setListing] = useState(false);
  const [gasFee, setGasFee] = useState(0);
  const [maticUsdPrice, setMaticUsdPrice] = useState(0);
  const [success, setSuccess] = useState(false);

  const mpContractInstance = useStoreState(
    (states) => states.mpContractInstance
  );

  const navigate = useNavigate();

  useEffect(() => {
    estimateGasFees();
    getListingPriceInUSD();
  }, []);

  const getListingPriceInUSD = async () => {
    try {
      setListing(true);
      const usd = await getCurrentMaticUSDPrice(1);
      setMaticUsdPrice(usd);
      setListing(false);
    } catch (e) {
      console.log("ERROR::GETTING_PRICE_IN_USD::", e);
      handleModalError();
    }
  };

  const fetchGasPrice = async () => {
    const gas_fee_url = process.env.REACT_APP_GAS_FEES_URL;

    let gasPriceInfo;
    for (let i = 0; i < 3; i++) {
      gasPriceInfo = await fetch(gas_fee_url)
        .then((response) => response.json())
        .catch((e) => {
          console.log("fetching gas price failed, Attempt", i + 1, e);
        });

      if (gasPriceInfo) {
        break;
      }
    }

    console.log("gas price info:", gasPriceInfo);
    return gasPriceInfo;
  };

  const estimateGasFees = async () => {
    try {
      let gasPriceInfo = await fetchGasPrice();

      if (gasPriceInfo?.fast?.maxFee) {
        gasPriceInfo.fast.maxFee = Math.min(200, 3 * gasPriceInfo.fast.maxFee);
        console.log("maxFee exists, doubled:", gasPriceInfo?.fast?.maxFee);
      } else {
        console.log("couldn't fetch gas price. Using default values");
        gasPriceInfo = { fast: { maxFee: 200 } };
      }

      const options = {
        gasPrice: ethers.utils.parseUnits(
          gasPriceInfo.fast.maxFee.toFixed(6),
          "gwei"
        ), // Set your desired gas price
        // value: listingPrice,
      };

      const amountInWei = ethers.utils.parseEther(listingPrice);

      // Estimate the gas required for the transaction
      const gasEstimate = await mpContractInstance.estimateGas.listNFTItem(
        process.env.REACT_APP_CONTRACT_ADDRESS,
        nft.tokenId,
        amountInWei,
        {
          gasLimit: 300000,
        }
      );

      console.log(gasEstimate);

      let gasFee = ethers.utils
        .formatUnits(gasEstimate.mul(options.gasPrice), "ether")
        .substring(0, 8);

      gasFee = Number(gasFee).toFixed(4);

      setGasFee(gasFee);

      console.log("Gas estimate:", gasEstimate.toString());
      console.log("Estimated gas fees:", gasFee);
    } catch (e) {
      console.log("ERROR::", e);
      handleModalError();
    }
  };

  const createListing = async () => {
    try {
      setListing(true);

      const amountInWei = ethers.utils.parseEther(listingPrice);

      const receipt = await mpContractInstance.listNFTItem(
        process.env.REACT_APP_CONTRACT_ADDRESS,
        nft.tokenId,
        amountInWei,
        { gasLimit: 300000 }
      );

      await receipt.wait();

      console.log("NFTListed on ");

      // API call when transaction completes
      const res = await MarketplaceService.createListing(
        nft?.restoId,
        nft?.tokenId,
        String(amountInWei)
      );
      console.log(res);
      updateBalance();
      setListing(false);
      setSuccess(true);
      fetchReservations();
    } catch (e) {
      console.log("ERROR::", e);
      setListing(false);
      handleModalError();
    }
  };

  return (
    <div className="sale-confirmation w-100">
      {/* {!success && (
        <div className="back-button-container" onClick={() => setModalValue(7)}>
          <img src="/back-button.svg" alt="back" />
        </div>
      )} */}

      {!success && (
        <div className="back-button-container" onClick={() => setModalValue(7)}>
          <svg
            width="38"
            height="38"
            viewBox="0 0 38 38"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11.8798 3.09668H25.3086C30.5593 3.09668 34.0752 6.78301 34.0752 12.266V24.9219C34.0752 30.3895 30.5593 34.0743 25.3086 34.0743H11.8798C6.6291 34.0743 3.09766 30.3895 3.09766 24.9219V12.266C3.09766 6.78301 6.6291 3.09668 11.8798 3.09668ZM23.2481 23.232C23.7747 22.707 23.7747 21.8551 23.2481 21.3285L20.4911 18.5715L23.2481 15.8129C23.7747 15.2878 23.7747 14.4205 23.2481 13.8938C22.7215 13.3657 21.8696 13.3657 21.3275 13.8938L18.586 16.6493L15.829 13.8938C15.2869 13.3657 14.435 13.3657 13.9084 13.8938C13.3817 14.4205 13.3817 15.2878 13.9084 15.8129L16.6654 18.5715L13.9084 21.313C13.3817 21.8551 13.3817 22.707 13.9084 23.232C14.1717 23.4953 14.5279 23.6363 14.8687 23.6363C15.2249 23.6363 15.5657 23.4953 15.829 23.232L18.586 20.4921L21.343 23.232C21.6063 23.5124 21.947 23.6363 22.2878 23.6363C22.644 23.6363 22.9848 23.4953 23.2481 23.232Z"
              fill="#D9D9D9"
            />
            <path d="M29 11H11V27H29V11Z" fill="#D9D9D9" />
            <path
              d="M13.3652 19.3384H25.2402"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18.1548 24.1076L13.3652 19.3386L18.1548 14.5688"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      )}

      <CloseIcon closeModal={closeModal} />

      {!success ? (
        <>
          <h5 className="header">Confirm sell</h5>

          <hr className="mt-0" />
          <div className="selling-details-container">
            <Table borderless className="mb-0">
              <tbody>
                <tr>
                  <td>
                    <h3>
                      {nft.tokenName ||
                        `PAIDREZ ${nft.restoData?.name} #${nft.tokenId
                          .toString()
                          .padStart(5, "0")}`}
                    </h3>
                  </td>
                  <td>
                    <h3 className="price green">{listingPrice} POL</h3>{" "}
                  </td>
                </tr>
                <tr>
                  <td>
                    {" "}
                    <p>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="align-text-bottom"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M3.48633 11.3716C3.48633 6.52804 7.4128 2.60156 12.2563 2.60156C17.0999 2.60156 21.0264 6.52804 21.0264 11.3716C21.0264 14.3207 19.2628 16.8205 17.2308 18.5815L13.8773 21.4879C12.947 22.2942 11.5657 22.2942 10.6354 21.4879L7.28185 18.5815C5.24985 16.8205 3.48633 14.3207 3.48633 11.3716ZM12.2563 13.3205C13.3327 13.3205 14.2052 12.448 14.2052 11.3716C14.2052 10.2952 13.3327 9.42269 12.2563 9.42269C11.18 9.42269 10.3075 10.2952 10.3075 11.3716C10.3075 12.448 11.18 13.3205 12.2563 13.3205Z"
                          fill="#7F8596"
                        />
                      </svg>
                      {nft.restoData?.name}
                    </p>
                  </td>
                  <td>
                    <p className="price">
                      ~ $ {(listingPrice * maticUsdPrice).toFixed(2)} USD
                    </p>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>

          <div className="gas-fee-container">
            <h4>Estimated Gas fee</h4>
            <div className="d-flex justify-content-between text-muted mb-5">
              {gasFee && maticUsdPrice ? (
                <>
                  <b>{gasFee} POL</b>
                  <b> ~ $ {(gasFee * maticUsdPrice).toFixed(4)} USD</b>
                </>
              ) : (
                <Skeleton containerClassName="w-100" className="h-100" />
              )}
            </div>
          </div>

          <Button
            className="paidrez-btn w-100 mb-2"
            onClick={createListing}
            disabled={!gasFee}
          >
            Continue{" "}
            <svg
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="align-text-bottom"
            >
              <path
                d="M11.6935 5.57568L17.0867 10.8106M17.0867 10.8106L11.6935 16.0455M17.0867 10.8106L3.2666 10.8106"
                stroke="#FCFCF9"
                strokeWidth="2.0102"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Button>

          <small className="text-center text-muted mx-4 mb-0 d-block lh-1">
            PaidRez will charge a small fee of 5% for every sale you make.{" "}
          </small>
        </>
      ) : (
        <div className="success-container">
          <Image src={nft.s3ImageURI} width={150} className="nft-image" />
          <h2 className="text-center">Congratulations!</h2>
          <p className="text-center">Awesome, your item was put on sale!</p>
          <Button
            className="paidrez-btn w-100 mb-1"
            onClick={() => navigate("/marketplace/nft/" + nft.tokenId)}
          >
            View On Marketplace
          </Button>
          <Button className="paidrez-btn-secondary w-100" onClick={closeModal}>
            List More Items
          </Button>
        </div>
      )}

      {listing && (
        <div className="loader-container">
          <LoadingAnimationSpoon width={150} />
        </div>
      )}
    </div>
  );
};

export default SaleConfirmation;
