import React from "react";
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  FormCheck,
  FormControl,
  FormGroup,
  FormLabel,
  Image,
  Row,
} from "react-bootstrap";
import BlogsService from "../../Services/BlogsService";

const Blog = ({ blog = false, closeModal, fetchBlogs }) => {
  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState(null);
  const [title, setTitle] = useState(blog ? blog.title : "");
  const [content, setContent] = useState(blog ? blog.content : "");
  const [isTopPost, setTopPost] = useState(blog ? blog.isTopPost : false);
  const [author, setAuthor] = useState(blog ? blog.author : "");
  const [noImageError, setNoImageError] = useState(false);
  const [disableButton, setDisableButton] = useState(false);

  const imageInputRef = useRef();
  const submitButonRef = useRef();

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!image) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(image);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [image]);

  const imageInputFunction = () => {
    imageInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }
    setImage(event.target.files[0]);
    console.log(event.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisableButton(true);
    console.log("clicked");
    console.log(isTopPost);
    if (!blog && !image) {
      setNoImageError(true);
      console.log("No image provided");
      setDisableButton(false);
      return;
    }

    try {
      let res;
      if (!blog) {
        res = await BlogsService.addBlog({
          title,
          content,
          image,
          author,
          isTopPost,
        });
      } else {
        console.log(blog);
        res = await BlogsService.updateBlog({
          id: blog._id,
          title,
          content,
          image,
          author,
          isTopPost,
        });
      }

      console.log(res);
      fetchBlogs();
      closeModal();
      setDisableButton(false);
    } catch (e) {
      setDisableButton(false);
      console.log("ERROR:", e);
    }
  };

  // WORK ON EDIT

  return (
    <Container className="px-0">
      <div className="close-button-container" onClick={() => closeModal()}>
        <svg
          width="32"
          height="32"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.896 0.664673H34.126C42.036 0.664673 47.3327 6.21801 47.3327 14.478V33.5437C47.3327 41.7803 42.036 47.3313 34.126 47.3313H13.896C5.98602 47.3313 0.666016 41.7803 0.666016 33.5437V14.478C0.666016 6.21801 5.98602 0.664673 13.896 0.664673ZM31.0228 30.998C31.8162 30.207 31.8162 28.9237 31.0228 28.1304L26.8695 23.977L31.0228 19.8214C31.8162 19.0304 31.8162 17.7237 31.0228 16.9304C30.2295 16.1347 28.9462 16.1347 28.1295 16.9304L23.9995 21.0814L19.8462 16.9304C19.0295 16.1347 17.7462 16.1347 16.9528 16.9304C16.1595 17.7237 16.1595 19.0304 16.9528 19.8214L21.1062 23.977L16.9528 28.107C16.1595 28.9237 16.1595 30.207 16.9528 30.998C17.3495 31.3947 17.8862 31.607 18.3995 31.607C18.9362 31.607 19.4495 31.3947 19.8462 30.998L23.9995 26.8704L28.1528 30.998C28.5495 31.4204 29.0628 31.607 29.5762 31.607C30.1128 31.607 30.6262 31.3947 31.0228 30.998Z"
            fill="#D9D9D9"
          />
        </svg>
      </div>

      <Row>
        <Col md={6}>
          {!blog && !image ? (
            <div
              className="add-image-container text-center mt-3"
              onClick={imageInputFunction}
              style={{ cursor: "pointer" }}
            >
              <svg
                width="46"
                height="45"
                viewBox="0 0 46 45"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mb-3"
              >
                <path
                  d="M3.03076 32.1392L14.4775 20.6924C16.4271 18.7428 19.588 18.7428 21.5376 20.6924L32.9844 32.1392M27.9921 27.1469L31.9504 23.1886C33.9 21.239 37.061 21.2389 39.0106 23.1886L42.9689 27.1469M27.9921 12.1701H28.0171M8.02303 42.1237H37.9766C40.7338 42.1237 42.9689 39.8886 42.9689 37.1314V7.17781C42.9689 4.42066 40.7338 2.18555 37.9766 2.18555H8.02303C5.26588 2.18555 3.03076 4.42066 3.03076 7.17781V37.1314C3.03076 39.8886 5.26588 42.1237 8.02303 42.1237Z"
                  stroke="#0E91AE"
                  strokeWidth="4.10134"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <p className="mb-0">
                <span>Click to upload a file</span>
              </p>
              <p className="text-black-50">
                {" "}
                <small> PNG, JPG, GIF upto 5MB</small>
              </p>
              <b className={`text-danger ${!noImageError && "opacity-0"}`}>
                Please select an Image
              </b>
            </div>
          ) : (
            <>
              <Image
                src={preview ? preview : blog?.imageUrl}
                className="w-100 mb-1"
              />
              <Button
                className="change-image-button"
                size="sm"
                onClick={imageInputFunction}
                disabled={disableButton}
              >
                Change
              </Button>
            </>
          )}

          <input
            type="file"
            name="nft-image-input"
            accept=".png, .jpg, .jpeg"
            onChange={handleFileChange}
            ref={imageInputRef}
            className={"d-none"}
          />
        </Col>
        <Col md={6} className="pt-3">
          <Form onSubmit={handleSubmit}>
            <FormGroup className="mb-2">
              <FormLabel>Title</FormLabel>
              <FormControl
                required
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </FormGroup>

            <FormGroup className="mb-2">
              <FormLabel>Content</FormLabel>
              <FormControl
                as="textarea"
                rows={10}
                required
                value={content}
                onChange={(e) => setContent(e.target.value)}
              />
            </FormGroup>

            <FormGroup>
              <FormCheck
                type="switch"
                label="Top Post"
                className="top-post-check"
                onChange={() => setTopPost(!isTopPost)}
                checked={isTopPost}
              />
            </FormGroup>

            <FormGroup className="mb-2">
              <FormLabel>Author</FormLabel>
              <FormControl
                required
                value={author}
                onChange={(e) => setAuthor(e.target.value)}
              />
            </FormGroup>

            <Button type="submit" className="d-none" ref={submitButonRef} />
          </Form>
        </Col>
        <Col>
          <Button
            className="paidrez-btn d-block w-100 mt-2"
            onClick={() => submitButonRef.current?.click()}
            disabled={disableButton}
          >
            {blog ? "Update" : "Create"} Blog Post
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default Blog;
