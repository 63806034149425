import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;
// const BASE_URL = "https://api.paidrez.com";

class otpService {
  async getOtp(mobileNumber) {
    // console.log("base url", process.env.REACT_APP_API_BASE_URL);
    const response = await axios
      .get(BASE_URL + "/getOtp", {
        params: {
          mobileNumber: mobileNumber,
        },
      })
      .then((response) => {
        console.log("otp sent! - ", response.data.otp);
        return response.data.otp;
      })
      .catch((err) => {
        if (err.response.data) {
          console.log("Error from API :", err.response.data.message);
        } else {
          console.log("Error : ", err);
        }
        return -1;
      });

    // console.log("response:::", response);
    return response;
  }
}
export default new otpService();
