import React, { useEffect, useState } from "react";
import ReservationsService from "../../Services/ReservationsService";
import {
  getReservationNFTs,
  toggleSlotStatus,
} from "../../Services/BlockchainBackendService";
import moment from "moment";
import LoadingAnimationSpoon from "../Helpers/LoadingAnimationSpoon";
import {
  Button,
  Col,
  Form,
  FormCheck,
  FormControl,
  FormSelect,
  Pagination,
  Row,
  Table,
  ToggleButton,
  ToggleButtonGroup,
} from "react-bootstrap";
import { useOutletContext } from "react-router-dom";

const ManageReservations = () => {
  const [past, setPast] = useState(false);
  const [reservations, setReservations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);

  const { setError } = useOutletContext();

  useEffect(() => {
    fetchReservations();
  }, [past, pageNo, pageSize]);

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      if (pageNo !== 1) {
        setPageNo(1);
      } else {
        fetchReservations();
      }
    }, 500);

    return () => clearTimeout(delayDebounce);
  }, [search]);

  const fetchReservations = async () => {
    try {
      setLoading(true);
      const currentTime = moment().utc().unix();

      let params = { search, pageNo, pageSize };

      params[past ? "endTime" : "startTime"] = currentTime;
      if (!past) params.sortOrder = "ASC";

      const res = await getReservationNFTs(params);
      console.log(res);
      setReservations(res.slots);
      setTotalPages(res.totalPages);
      setLoading(false);
    } catch (e) {
      console.log("ERROR::", e);
      setLoading(false);
    }
  };

  const handleDisable = async (res) => {
    const action = res.isSlotActive ? "disable" : "enable";
    if (
      window.confirm(
        "Are you sure to " +
          action +
          " the reservation for " +
          moment(res.slotTime * 1000).format("LT on M/D/YYYY") +
          " from " +
          res.restaurantName +
          "?"
      )
    ) {
      try {
        setLoading(true);
        const result = await toggleSlotStatus(res.restaurantId, res._id);
        console.log(result);
        fetchReservations();
      } catch (e) {
        console.log("ERROR::", e);
        setLoading(false);
        setError();
      }
    }
  };

  return (
    <div className="manage-reservations">
      <Row xs={1} md={2} className="header mb-2">
        <Col className="button-container">
          <ToggleButtonGroup
            type="checkbox"
            value={past ? 2 : 1}
            onChange={() => {
              setPast(!past);
            }}
            className="toggle-button-group"
          >
            <ToggleButton id="tbg-btn-1" value={1}>
              Upcoming Reservations
            </ToggleButton>
            <ToggleButton id="tbg-btn-2" value={2}>
              Past Reservations
            </ToggleButton>
          </ToggleButtonGroup>
        </Col>
        <Col>
          <Form className="search-container">
            <FormControl
              type="search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search By Restaurant"
            />
          </Form>
        </Col>
      </Row>

      {loading ? (
        <LoadingAnimationSpoon width={150} className="d-block m-auto" />
      ) : (
        <>
          <div className="table-container overflow-auto">
            <Table bordered striped className="w-100">
              <thead className="text-bg-secondary">
                <tr>
                  <th>Status</th>
                  <th>Date</th>
                  <th>Time</th>
                  <th>Token ID</th>
                  <th>NFT Name</th>
                  <th>Restaurant</th>
                  <th>Guests</th>
                  <th>Owner</th>
                </tr>
              </thead>

              <tbody>
                {reservations.map((reservation, i) => (
                  <tr key={i}>
                    <td className="text-center">
                      <FormCheck
                        type="switch"
                        checked={reservation.isSlotActive}
                        onChange={() => {
                          handleDisable(reservation);
                        }}
                        className="switch"
                        disabled={reservation.isMinted || past}
                      />{" "}
                    </td>
                    <td>
                      {moment(reservation.slotTime * 1000).format("MM/DD/YYYY")}
                    </td>
                    <td>{moment(reservation.slotTime * 1000).format("LT")}</td>
                    <td>{reservation.tokenId}</td>
                    <td>
                      {reservation.tokenName ||
                        `PAIDREZ ${
                          reservation?.restaurantName
                        } #${reservation?.tokenId
                          ?.toString()
                          .padStart(5, "0")}`}
                    </td>
                    <td>{reservation.restaurantName}</td>
                    <td>{reservation.seatCapacity}</td>
                    <td>
                      {reservation.isMinted ? reservation.walletAddress : "NA"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          {totalPages > 0 && (
            <div className="d-flex justify-content-between flex-wrap mt-1 mx-1">
              <div className="pagination-container ">
                <Pagination className="justify-content-center mb-0" size="sm">
                  <Pagination.Prev
                    disabled={pageNo === 1}
                    onClick={() => setPageNo((pageNo) => pageNo - 1)}
                  />
                  {Array.from(Array(totalPages).keys()).map((_, i) => (
                    <Pagination.Item
                      active={pageNo === i + 1}
                      onClick={() => setPageNo(i + 1)}
                      key={i}
                    >
                      {i + 1}
                    </Pagination.Item>
                  ))}

                  <Pagination.Next
                    disabled={pageNo === totalPages}
                    onClick={() => setPageNo((pageNo) => pageNo + 1)}
                  />
                </Pagination>
              </div>
              <div className="page-size-selector">
                <div className="d-flex">
                  <small className="mb-0 me-1 align-self-center">
                    Results per page
                  </small>
                  <FormSelect
                    onChange={(e) => {
                      setPageSize(e.target.value);
                    }}
                    defaultValue={pageSize}
                    size="sm"
                    className="w-max"
                  >
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={30}>30</option>
                  </FormSelect>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ManageReservations;
